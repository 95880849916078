import { Helmet } from 'react-helmet-async';
// @mui
import { Container, Typography } from '@mui/material';
// components
import { useSettingsContext } from '../components/settings';
import {Dashfirst} from "../sections/@dashboard/Dashfirst";

// ----------------------------------------------------------------------

export default function PageDashfirst({socket}:any) {
  const { themeStretch } = useSettingsContext();

  return (
    <>
      <Helmet>
        <title> Dashboard do Herói - Eyhe Maior Comunidade de Suporte Emocional do Brasil </title>
      </Helmet>

      <Dashfirst socket={socket}/>
    </>
  );
}
