import {Divider,Tabs, Tab, IconButton,Button,TextField,Popover , List, MenuItem,FormControl,OutlinedInput,InputAdornment, Paper, Box, Grid, Badge, Avatar, Select, Stack, Rating, Tooltip, Typography} from "@mui/material";
import { CustomAvatar } from "src/components/custom-avatar";
import {useEffect, useState, useCallback} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import Iconify from "src/components/iconify";
import { styled } from '@mui/material/styles';
import useResponsive from "src/hooks/useResponsive";

import {useSettingsContext} from '../../../../components/settings';
import {useAuthContext} from "../../../../auth/useAuthContext";
// @mui

import axios from "../../../../utils/axios";
import {PATH_AUTH, PATH_DASHBOARD} from "../../../../routes/paths";



const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
type Props = {
    agendamento: any;
};


  export default function AgendamentoMarcado({agendamento}:Props){


      const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const abrirchat = () => {
        navigate(PATH_DASHBOARD.chat.view(agendamento.tokenConversa));
    };
    const abrirperfil= () => {
        navigate(PATH_DASHBOARD.verperfil(agendamento.IDAtendente));
    };
    const editarhora= () => {
        navigate(PATH_DASHBOARD.reagendamento(agendamento.IDAtendente,agendamento.id));
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;










    return(
        <div className="MeusAgendamentosDashInicialRecorrente">
                    <div className="AgendamentoRecorrenteNomeFuncaoeFoto">
                        <div className="AgendamentoRecorrenteFotoAnjo">
                            <CustomAvatar
                                className="FotoPerfilAtendente"
                                src={agendamento?.avatar}
                                alt={agendamento?.nome}
                                name={agendamento?.nome}
                                sx={{ cursor: 'pointer', width: 60, height: 60 }}
                            />
                        </div>

                        <div className="AgendamentoRecorrenteNomeeFuncaoAnjo">
                                <div className="FuncaoAnjoAgendamentoRecorrente">{agendamento?.TipoAtendente}</div>
                                <Typography className="NomeAnjoAgendamentoRecorrente">{agendamento?.nome}</Typography>
                        </div>

                    </div>

                    <div className="DataeHoraAgendamentoRecorrente">
                        <div className="DataAgendamentoRecorrente">
                            <Iconify icon="eva:calendar-outline"/>
                            <Typography>{agendamento?.data}</Typography>
                        </div>
                        <div className="HoraAgendamentoRecorrente">
                            <Iconify icon="eva:clock-outline"/>
                            <Typography>{agendamento?.hora}</Typography>
                        </div>
                        <div className="BotaoMaisOpcoes">
                        <Button aria-describedby={id} variant="contained" onClick={handleClick} sx={{ml: 1.5}}>
                            Opções
                        </Button>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                            }}
                        >
                            {agendamento.ispast ? (
                            <div>
                                <Button onClick={abrirchat}> <Iconify icon='eva:message-square-outline'  mr={1.5} /> Acessar chat </Button>
                            </div>
                            ) : ('')}
                            <div>
                                <Button onClick={abrirperfil}> <Iconify icon='eva:eye-outline' mr={1.5}/> Ver perfil do atendente </Button>
                            </div>
                            <div>
                                <Button onClick={editarhora}> <Iconify  icon='eva:edit-outline' mr={1.5}/> Editar data/hora </Button>
                            </div>
                        </Popover>
                        </div>

                        
                    </div>   
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      sx={{mt: 1}}
                    >
                      <Iconify icon="eva:info-outline" sx={{ mr: 1, color: '#637381'}} /> 
                      <Typography variant='subtitle2' sx={{ color: '#637381'}}>  Horário de Brasília</Typography>
                    </Grid>      
                </div>
    )

  }