import { Helmet } from 'react-helmet-async';
// sections
import { DetalhesPacotes } from 'src/sections/@dashboard/DetalhesPacotes';



export default function DetalhesPacotesPage() {
  return (
    <>
      <Helmet>
        <title>Detalhes Pacotes do Herói - Eyhe Maior Comunidade de Suporte Emocional do Brasil</title>
      </Helmet>

      <DetalhesPacotes/>
    </>
  );
}
