import { Helmet } from 'react-helmet-async';
// sections
import Comunidades from "../sections/@dashboard/Comunidades/Comunidades";

export default function ComunidadesPage({socket}:any) {
  return (
    <>
      <Helmet>
        <title>Comunidades -  Eyhe Maior Comunidade de Suporte Emocional do Brasil </title>
      </Helmet>

      <Comunidades socket={socket} />
    </>
  );
}
