import {useEffect, useState, useCallback, useMemo, useLayoutEffect} from 'react';
// @mui
import {Divider,Tabs, Tab, IconButton,Button,Dialog,Card,Chip,TextField, List, MenuItem,FormControl,OutlinedInput,InputAdornment, Paper, Box, Grid, Badge, Avatar, Select, Stack, Rating, Tooltip, Typography} from "@mui/material";
import { CustomAvatar } from "src/components/custom-avatar";
import {useLocation, useNavigate, useParams} from 'react-router-dom';
// @types
import useResponsive from "src/hooks/useResponsive";
import Iconify from 'src/components/iconify/Iconify';
import {styled, Theme} from '@mui/material/styles';
import { getCoresTemplate } from 'src/utils/coresTemplate';
import {useSettingsContext} from '../../../components/settings';

import { IUserProfile, IUserProfilePost } from '../../../@types/user';
//
import ProfileAbout from './home/ProfileAbout';
import ProfilePostCard from './home/ProfilePostCard';
import ProfilePostInput from './home/ProfilePostInput';
import ProfileFollowInfo from './home/ProfileFollowInfo';
import ProfileSocialInfo from './home/ProfileSocialInfo';
import ProfileCover from './ProfileCover';
import CoverProfile from './home/CoverProfile';
import FotoPerfil from './background_verperfil.jpg'

import './VerPerfil.css';
import './home/CoverProfile.css';
import Avaliacoesperfil from "./home/Avaliacoesperfil";
import ConversasSobre from "./home/ConversasSobre";
import {PATH_DASHBOARD} from "../../../routes/paths";
import axios from "../../../utils/axios";
import {BadgeStatusValue} from "../../../components/badge-status";
import {useAuthContext} from "../../../auth/useAuthContext";
import {criarevento} from "../../../utils/analytics ";
import ProfileBiografia from './home/ProfileBiografia';


// ----------------------------------------------------------------------

type Props = {
  info: IUserProfile;
  posts: any;
  Atendente: any;
  atedimentos: any;
  avaliacoes: any;
  assuntos: any;
  idAtendente: any;
  handleChangePage: any;
};

function getStatusColor(status: string, theme: Theme): string {
  switch (status) {
    case 'Disponivel':
      return theme.palette.success.main; // Cor para status 'online'.
    case 'Offline':
      return theme.palette.error.main; // Cor para status 'offline'.
      // Adicione outros casos conforme necessário para outros status.
    default:
      return theme.palette.primary.main; // Cor padrão.
  }
}
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function VerPerfil({ info, posts,Atendente,atedimentos,avaliacoes,assuntos,idAtendente,handleChangePage }: Props) {

  const { themeLayout } = useSettingsContext();
  const isDesktop = useResponsive('up', 'lg');
  const navigate = useNavigate();
  const [name, setName] = useState<any>({}); 
  const [role, setRole] = useState<any>({});
  const [cover, setCover] = useState<any>({});
  const { user } = useAuthContext();
  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
  setValue(newValue);
  }


  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const [status, setStatus] = useState<BadgeStatusValue>('Offline');

console.log(Atendente);



  useEffect(  () => {

    setStatus(Atendente.StatusAtendimento);


  },[user,Atendente]);




  const irpagamento = () => {
    // criarevento('escolheu_atendente');
    navigate(PATH_DASHBOARD.conversar(idAtendente));
  }



  const [video, setvideo] = useState<any>(null);
console.log(video);

  useEffect(() => {


      setvideo(Atendente?.AVideo);



  }, [setvideo,Atendente]);


  const verifyVideo = () => {
    console.log(video);
  }

  const irAtendentes = () => {
    navigate(PATH_DASHBOARD.atendentes);
  };


  const BotaoConversar = (
    
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      className='CTAVerPerfilGrid'
    >

      <Button variant='contained' color="success" fullWidth onClick={irpagamento} className='BotaoCTAVerPerfil'>Conversar Agora</Button>
      { (user?.jaconversou === 'n' && Atendente.TipoAtendente === 1) ? (
          <Chip color="primary" variant="outlined" label='A primeira conversa é gratuita!' icon={<Iconify icon='eva:checkmark-circle-outline' />}/>
      ) : ('') }


      </Grid>
  )
  





const  nome  = (
  <Typography className="NomeCardTopoPrincipalCoverProfileDesktop">{Atendente.nome}</Typography>
);

const atendentedesde  = (
  <Typography className="AtendenteDesdeCoverProfileDesktop">{Atendente.created_at}</Typography>
);









  // @ts-ignore
  // @ts-ignore
  return (

    <>
    <Grid
    container
    direction="row"
    justifyContent="flex-start"
    alignItems="center"
    >
        <Item>
          <IconButton onClick={irAtendentes} ><Iconify icon='eva:arrow-ios-back-fill' /></IconButton> Ver Perfil 
        </Item>
    </Grid>
    

    { isDesktop ? (
       <Grid container spacing={3}>

       <Grid item xs={12} md={12}>
       { isDesktop ? (
          <div className="CapaAtendenteCoverProfileDesktop">
          <div className= {getCoresTemplate(Atendente?.TipoAtendente).capaAtendenteDashFirstDesk} >
              <div className="FotoeNomeAtendenteCoverProfileDesktop">

                <CustomAvatar
                    className="FotoPerfilAtendente"
                    src={Atendente.avatar}
                    alt={Atendente.nome}
                    name={Atendente.nome}
                    sx={{
                      cursor: 'pointer',
                      width: 100,
                      height: 100,
                      border: (theme) => `4px solid ${getStatusColor(status, theme)}`, // Define a largura e cor da borda com base no status.
                      borderRadius: '50%', // Para criar uma borda circular.
                    }}
                    onClick={handleOpenModal}
                />

                  <div className="NomeAtendenteCoverProfileDesktop">
                      {nome}
                      {/* {atendentedesde} */}
                  </div>
                  
              </div>

              
          </div>

          <div className="tabsCoverProfile">
              <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="icon position tabs example"
                  >
                  <Tab icon={<Iconify icon="eva:home-outline"/>} iconPosition="start" label="Perfil" />
                  <Tab icon={<Iconify icon="eva:heart-fill"/>} iconPosition="start" label="Avaliações" />
                  <Tab icon={<Iconify icon="eva:people-fill"/>} iconPosition="start" label="Conversas Sobre" />
                  {/* <Tab icon={<Iconify icon="eva:image-outline"/>} iconPosition="start" label="Diário" /> */}
              </Tabs>
          </div>
         
  </div>  
       ) : (
                  '' 
       ) }
       </Grid>
 
        
        {value === 0 ? (
          <>
          <Grid item xs={12} md={4}>
            <Stack spacing={3}>
              <ProfileFollowInfo TipoAtendente={Atendente.TipoAtendente}  valorChat={Atendente.valorChat} valor={Atendente.ValorAtendimento} tempo={Atendente.TempoAtendimento} atedimentos={atedimentos} />
    
              {BotaoConversar}
    
              <ProfileAbout
                  Atendente={Atendente}
              />
    

    
              <ProfileSocialInfo socialLinks={info.socialLinks} Atendente={Atendente}  assuntos={assuntos}/>
              
              
                  
              

              

            </Stack>
          </Grid>

           <Grid item xs={12} md={8}>
            <Stack spacing={2}>
            <ProfileBiografia Atendente={Atendente} />
            {Atendente.AVideo ? ( <iframe width="100%" height="500" src={video} className='videoyoutube'
                                              title="YouTube video player" frameBorder="0"
                                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                              allowFullScreen />):('')
              }




              {/* <ProfilePostInput /> */}

              {/* {posts.data.map((post:any) => ( */}
              {/*  <ProfilePostCard key={post.id} post={post} /> */}
              {/* ))} */}
            </Stack>

           </Grid>
          </>
        ) : ('')}
        
        {value === 1 ? (
          <>
            <Avaliacoesperfil avaliacoes={avaliacoes} handleChange={handleChangePage}/>
          </>
        ) : ('')}

        {value === 2 ? (
          <>
            <ConversasSobre assuntos={assuntos}/>
          </>
        ) : ('')}
     </Grid>
    ):(
      <>

<div className="TopoCoverProfileMobile">
                <div className="CardTopoPrincipalCoverProfile">
                <div className="FotoNomeCoverProfile">
                  <CustomAvatar
                      className="FotoPerfilAtendente"
                      src={Atendente.avatar}
                      alt={Atendente.nome}
                      name={Atendente.nome}
                      sx={{ cursor: 'pointer', width: 80, height: 80 }}
                      onClick={handleOpenModal}
                  />
                    <div className="NomeCoverProfile">
                        {nome}
                        {/* {atendentedesde} */}
                    </div>
                    
                    
                </div>

             
            </div>
                <div className="tabsCoverProfileMobile">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="icon position tabs example"
                            >
                            <Tab icon={<Iconify icon="eva:home-outline"/>} iconPosition="start" label="Perfil" />
                            <Tab icon={<Iconify icon="eva:heart-fill"/>} iconPosition="start" label="Avaliações" />
                            <Tab icon={<Iconify icon="eva:people-fill"/>} iconPosition="start" label="Conversas Sobre" />
                            { /* <Tab icon={<Iconify icon="eva:image-outline"/>} iconPosition="start" label="Diário" /> */ }
                        </Tabs>
                </div>
            </div>  

        {value === 0 ?(
          <>
          <Grid item xs={12} md={4}>
          <Stack spacing={3}> 
            <ProfileFollowInfo TipoAtendente={Atendente.TipoAtendente}  valorChat={Atendente.valorChat} valor={Atendente.ValorAtendimento}  tempo={Atendente.TempoAtendimento} atedimentos={atedimentos} />
  
            {BotaoConversar}
                  
            <ProfileBiografia Atendente={Atendente} />

           
              {Atendente.AVideo ? (
              <Card className='VideoApresentacaoCard'>
              <iframe height="315" src={video} className='videoyoutube'
                    title="YouTube video player" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen />
                    </Card>) : ('')}
            
            

                    <ProfileAbout Atendente={Atendente}/>
                  
                  
                    <ProfileSocialInfo socialLinks={info.socialLinks} assuntos={assuntos} Atendente={Atendente} />
                  
            
            
  
          
            
            
            
          </Stack>
        </Grid>

         <Grid item xs={12} md={8}>
          <Stack spacing={3}>

           

            




            {/* <ProfilePostInput /> */}

            {/* {posts.data.map((post:any) => ( */}
            {/*  <ProfilePostCard key={post.id} post={post} /> */}
            {/* ))} */}
          </Stack>

         </Grid>
        </>
        ) : ('')}

        {value === 1 ?(
          <>
         <Avaliacoesperfil avaliacoes={avaliacoes} handleChange={handleChangePage}/>
          </>
        ) : ('')}

        {value === 2 ? (
          <>
        <ConversasSobre assuntos={assuntos}/>
          </>
        ) : ('')}
      
      </>
    )}
    <Dialog open={openModal} onClose={handleCloseModal}>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <IconButton onClick={handleCloseModal}>
          <Iconify icon='eva:close-outline' />
        </IconButton>
      </Grid>
      
      
        <img src={Atendente.avatar} style={{padding: '10px', width: '100vw', height: '100vw', borderRadius: '16px' , objectFit: 'cover'}} alt={Atendente.nome} />
      </Dialog>
    </>
  );
}
