import { Dialog,DialogTitle , ToggleButtonGroup,ToggleButton,FormControl,InputLabel, Select, Grid,MenuItem,SelectChangeEvent , DialogContent,ListItemText, Box, Rating, useMediaQuery ,ListItem,List,Tooltip, Divider, AppBar , Toolbar ,IconButton  ,Slider,Slide, Button, TextField, Tabs, Tab, InputAdornment, Paper, Typography} from "@mui/material";
import { TransitionProps } from '@mui/material/transitions';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import React, {useEffect, useState} from "react";
import {styled, useTheme} from "@mui/material/styles";
import { CustomAvatar } from "src/components/custom-avatar";
import {useSettingsContext} from "../../../../components/settings";
import {useAuthContext} from "../../../../auth/useAuthContext";
import {PATH_DASHBOARD} from "../../../../routes/paths";
import Iconify from "../../../../components/iconify";
import MenuPopover from "../../../../components/menu-popover";
import "./FiltrarAtendente.css";    
import BadgeStatus, {BadgeStatusValue} from "../../../../components/badge-status";
import axios from "../../../../utils/axios";

import iconAtendente1 from "../img/love.svg"
import iconAtendente2 from "../img/psychology.svg"
import iconAtendente4 from "../img/terapia.png"
import iconAtendente5 from "../img/coach.png"

  type props = {
    settipoprofissional?: any,
};

export default function FiltroTipoAtendenteMobile({settipoprofissional}:props) {

    const [valueProfissional, setValueProfissional] = React.useState('');

    const handleProficional = (event: SelectChangeEvent<string>) => {
      const newValue = event.target.value;
        sessionStorage.setItem('tipoprofissional',event.target.value);
      setValueProfissional(newValue);
      settipoprofissional(newValue);
    };


    useEffect(() => {
        if(sessionStorage.getItem('tipoprofissional')){
            // @ts-ignore
            setValueProfissional(sessionStorage.getItem('tipoprofissional'));
        }

    }, []);




return (
   
        <>

        {valueProfissional === '' ? (<Typography className="SubTituloCardFiltroAtendente" sx={{my: 1.5}}>Selecione o tipo de atendente</Typography>) : ('')}

          
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label"> {valueProfissional === '' ? ('Todos os atendentes') : ('Tipo de atendente:')} </InputLabel>
                    <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={valueProfissional}
                    label="Todos os Atendentes"
                    onChange={handleProficional}
                    className="SelectTipoAtendenteMobile"
                    
                    >
                        <MenuItem value="">  Todos os atendentes </MenuItem>
                        <MenuItem value="1"> <div className="IconsAtendentesFiltroMobile"><img src={iconAtendente1} height="30px" alt="Icone Anjo" /></div>  Anjo </MenuItem>
                        <MenuItem value="2"> <div className="IconsAtendentesFiltroMobile"><img src={iconAtendente2} height="30px" alt="Icone Psicólogo" /></div> Psicólogo </MenuItem>
                        <MenuItem value="4"> <div className="IconsAtendentesFiltroMobile"><img src={iconAtendente4} height="30px" alt="Icone Terapeuta" /></div> Terapeuta </MenuItem>
                        <MenuItem value="5"> <div className="IconsAtendentesFiltroMobile"><img src={iconAtendente5} height="30px" alt="Icone Coach" /></div> Coach </MenuItem>
                    </Select>
                </FormControl>

          
        </>
   

      


)
}