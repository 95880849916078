import * as React from 'react';
import {
  CssBaseline,
  Dialog,
  DialogContent,
  Box,
  Container,
  Typography,
  Tooltip,
  Button,
  Link,
  Grid,
  IconButton,
  Paper,
  Card,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// eslint-disable-next-line import/order
import { useSnackbar } from '../../../../components/snackbar';
import { useEffect, useState, useCallback, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import './notifica.css';
// @mui

import { styled } from '@mui/material/styles';
import { useSettingsContext } from '../../../../components/settings';
// eslint-disable-next-line import/no-cycle
import notificacaoPagamento from '../img/notificacao_pagamento.png';

import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs';
// routes
import { PATH_AUTH, PATH_DASHBOARD } from '../../../../routes/paths';
import axios from '../../../../utils/axios';
import { useAuthContext } from '../../../../auth/useAuthContext';
import Iconify from '../../../../components/iconify';
import anjoChegou from '../../SalaEspera/img/anjoChegou.webp';
import anjoNaoChegou from '../../SalaEspera/img/anjoNaoChegou.webp';
// eslint-disable-next-line import/no-cycle

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

// ----------------------------------------------------------------------
type props = {
  fecharmodal: any;
  dados: any;
};

export default function NotificaAnjoNaoChegou({ fecharmodal, dados }: props) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isSubmitting, setisSubmitting] = useState(false);

  const [aguardandoAnjoPlantao, setAguardandoAnjoPlantao] = useState(false);

  const chamarplantao = async () => {
    setisSubmitting(true);
    try {
      const response = await axios.post('/areadoheroi/chamarplantao', {
        chamada: dados.tokenConversa,
      });

      enqueueSnackbar(`Aguarde alguém lhe atender.`, { variant: 'success' });
      setisSubmitting(true);
      setAguardandoAnjoPlantao(true);
    } catch (error) {
      enqueueSnackbar(`${error.message}`, { variant: 'error' });
      setisSubmitting(false);
    }
  };

  const pageantedentes = () => {
    navigate(PATH_DASHBOARD.atendentes);
    fecharmodal();
  };

  const handleClickHorariosAgendamento = () => {
    navigate(PATH_DASHBOARD.agendamento(dados.usera.id), { replace: true });
  };

  const handleFinanceiro = () => {
    navigate(PATH_DASHBOARD.financeiro);
    fecharmodal();
  };

  const [openConfirmaPlantao, setOpenConfirmaPlantao] = React.useState(false);

  const handleClickOpenConfirmaPlantao = () => {
    setOpenConfirmaPlantao(true);
  };

  const handleCloseConfirmaPlantao = () => {
    setOpenConfirmaPlantao(false);
  };

  return (
    <>
      <CssBaseline />
      <Container maxWidth="xl" className="GlobalChamadaEncerrada">
        {aguardandoAnjoPlantao === false ? (
          <div className="DialogPaddingAnjoNaoChegouSaladeEspera">
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
              <Item>
                <IconButton edge="start" color="inherit" aria-label="close" onClick={fecharmodal}>
                  <Iconify icon="eva:chevron-left-fill" />
                  <Typography variant="subtitle2">Voltar</Typography>
                </IconButton>
              </Item>

              <Item>
                <IconButton edge="start" color="inherit" aria-label="close" onClick={fecharmodal}>
                  <Iconify icon="eva:close-fill" />
                </IconButton>
              </Item>
            </Grid>

            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              className="MainDialogSaladeEsperaAnjoChegou"
            >
              <Card className="cardNotificacaoAnjoNaoChegou">
                <img src={anjoNaoChegou} className="ImagemSalaDeEsperaAnjoChegou" alt="Aguarde" />

                <Typography className="TituloSalaDeEsperaAnjoNaoChegou">
                  {' '}
                  Poxa, seu atendente infelizmente não pode lhe atender. Mas fique tranquilo..{' '}
                </Typography>

                  <Typography variant="subtitle1" sx={{ px: 2 }}>
                  {' '}
                  Você pode conversar com outro atendente, agendar uma conversa com o atendente que
                  não pode lhe atender agora ou solicitar o atendimento de plantão, que ativa
                  automaticamente outro Anjo disponível no momento.{' '}
                </Typography>

                
                <br />
                
                <Typography variant="subtitle1" sx={{ px: 2 }}>
                  Caso você tenha optado por uma conversa paga, o valor gasto está em sua conta Eyhe
                  e pode ser utilizado para conversar com outros atendentes ou até mesmo ser
                  reembolsado para você. Basta acessar a aba Financeiro em seu menu.
                </Typography>

                <div className="CTAsSalaDeEsperaAnjoNaoChegou">


                    <Button
                      sx={{ my: 1, py: 1 }}
                      variant="contained"
                      color="success"
                      onClick={handleClickOpenConfirmaPlantao}
                      fullWidth
                    >
                      {' '}
                      <Iconify icon="eva:checkmark-circle-outline" sx={{ mr: 1 }} /> Chamar Atendentes de
                      plantão
                    </Button>


                  <Button
                    sx={{ my: 1, py: 1 }}
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleClickHorariosAgendamento}
                  >
                    {' '}
                    <Iconify icon="eva:calendar-outline" sx={{ mr: 1 }} /> Agendar acolhimento
                  </Button>
                  <Button
                    fullWidth
                    variant="outlined"
                    onClick={pageantedentes}
                    sx={{ my: 1, py: 1 }}
                  >
                    {' '}
                    <Iconify icon="eva:people-fill" sx={{ mr: 1 }} /> Escolher outro atendente{' '}
                  </Button>
                  <Button
                    sx={{ my: 1, py: 1, textDecoration: 'underline' }}
                    variant="text"
                    color="primary"
                    onClick={handleFinanceiro}
                  >
                    {' '}
                    <Iconify icon="eva:credit-card-outline" sx={{ mr: 1 }} /> Ir para o financeiro
                  </Button>
                </div>
              </Card>
            </Grid>
          </div>
        ) : (
          ''
        )}
      </Container>

      <Dialog
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleCloseConfirmaPlantao();
          }
        }}
        disableEscapeKeyDown
        open={openConfirmaPlantao}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          sx={aguardandoAnjoPlantao ? { px: 3, py: 5, backgroundColor: '#078DEE' } : { p: 3 }}
        >
          {aguardandoAnjoPlantao === false ? (
            <>
              <Typography variant="h3" color="primary" sx={{ textAlign: 'center' }}>
                Atendimento de plantão
              </Typography>
              <Typography variant="subtitle1" sx={{ textAlign: 'center', my: 2 }}>
                O atendimento de plantão envia sua solicitação de atendimento para 10 atendentes
                disponíveis nesse momento. O primeiro que aceitar será seu ouvinte.
                <br /> <b>Você deseja ativar? Tempo de espera: 2 minutos</b>
              </Typography>

              <>
                <LoadingButton
                  color="primary"
                  fullWidth
                  onClick={chamarplantao}
                  sx={{ my: 1, py: 1 }}
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  <Iconify icon="eva:checkmark-circle-outline" sx={{ mr: 1 }} /> Ativar plantão
                </LoadingButton>
              </>

              <Button
                fullWidth
                variant="outlined"
                onClick={handleCloseConfirmaPlantao}
                sx={{ my: 1, py: 1.5 }}
              >
                {' '}
                <Iconify icon="eva:arrow-ios-back-fill" sx={{ mr: 1 }} /> Voltar{' '}
              </Button>
            </>
          ) : (
            <>
              <Typography variant="h3" sx={{ textAlign: 'center', color: '#fff' }}>
                Agora é só aguardar...
              </Typography>
              <Typography variant="subtitle1" sx={{ textAlign: 'center', my: 2, color: '#fff' }}>
                Fique tranquilo, em minutos um de nossos atendentes estará conversando com você.{' '}
                <b> Por gentileza permaneça nessa página.</b>{' '}
              </Typography>
              <Typography variant="subtitle1" sx={{ textAlign: 'center', mt: 4, color: '#fff' }}>
                Tempo médio de espera: 2 minutos.
              </Typography>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
