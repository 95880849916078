import * as React from 'react';
import {CssBaseline, Box, Container, Typography, Tooltip, Button, Link, Grid, IconButton, Paper} from "@mui/material";
import {styled} from '@mui/material/styles';
import {useEffect, useState, useCallback, useRef} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {PATH_AUTH, PATH_DASHBOARD} from '../../../../../routes/paths';

import './FimAtendimento.css';
// @mui

// eslint-disable-next-line import/no-cycle
import ImagemFimAtendimento from './img/img-fim-atendimento.png';

// routes


import Iconify from "../../../../../components/iconify";
import anjoChegou from "../../SalaEspera/img/anjoChegou.webp";
import ModalAvaliacao from "../../../../../components/modalAvalicao/ModalAvaliacao";
import axios from "../../../../../utils/axios";
// eslint-disable-next-line import/no-cycle

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


// ----------------------------------------------------------------------

type props = {
    Atendente: any;
    verHistorico: any;
};

export default function FimAtendimento({Atendente,verHistorico}:props) {

    const navigate = useNavigate();
    const [AvaliarOpen, setAvaliarOpen] = useState(false);
    const [convers, setConvesa] = useState({});
    const {tokenConversa = ''} = useParams();

    const getmessage = useCallback(async () => {
        try {
            const response = await axios.post(`/areadoheroi/getatendimento`,{tokenConversa});

            const {conversa} = response.data;


            setConvesa(conversa);

        } catch (error) {
            console.error(error);
        }
    },[tokenConversa]);




    useEffect(() => {

        getmessage();

    }, [getmessage]);





    const continuarConversando = ()=>{
        navigate(PATH_DASHBOARD.pagamento(Atendente?.id),{replace:true});
    }
    const avaliar = ()=>{
        setAvaliarOpen(true);
    }
    const encerrar = ()=>{
        navigate(PATH_DASHBOARD.dashfirst,{replace:true});
    }



    return (
        <>

            


                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        className='DialogFimAtendimentoGeral'
                    >

                        <div className='TopoFimAtendimento'>
                            <IconButton onClick={encerrar}>
                                <Iconify icon="eva:arrow-ios-back-fill"  />
                            </IconButton>
                            <Typography>Ir para dashboard</Typography>
                        </div>

                        <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="stretch"
                        >
                            <Grid item>
                                <Item className='imgFimdeAtendimentoItem'>
                                    <img src={ImagemFimAtendimento} className='imgFimAtendimento' alt='Fim do Atendimento' />
                                </Item>
                                <Item className='TituloFimAtendimentoItem'>
                                    <Typography className='TitulochatAnjoChegou'> <Iconify className='IconCheckAnjoChegouSalaDeEspera' icon="eva:checkmark-circle-2-fill"/>
                                    Seu atendimento chegou ao fim</Typography>
                                </Item>
                                <Item>
                                    <Button fullWidth variant='contained' color='success' onClick={continuarConversando} sx={{py : 1.5}}> <Iconify icon='eva:checkmark-circle-outline' sx={{mr: 1}} /> Continuar conversando</Button>
                                </Item>
                                <Item>
                                    <Button fullWidth variant='contained' color='primary' onClick={avaliar} sx={{py : 1.5}}> <Iconify icon='eva:star-outline' sx={{mr: 1}} /> Avaliar atendimento</Button>
                                </Item>
                                <Item>
                                    <Button fullWidth variant='outlined' color='error' onClick={encerrar} sx={{py : 1.5}}> <Iconify icon='eva:power-outline' sx={{mr: 1}} /> Encerrar</Button>
                                </Item>
                                <Item>
                                    <Button fullWidth variant='outlined' color='primary' onClick={verHistorico} sx={{py : 1.5}}> <Iconify icon='eva:message-square-outline' sx={{mr: 1}} /> Ver Histórico</Button>
                                </Item>
                            </Grid>
                        </Grid>

                        <ModalAvaliacao AvaliarOpen={AvaliarOpen} setAvaliarOpen={setAvaliarOpen} dadosAvaliar={convers} tipo='criar'/>

                    
                    </Grid>

              
                    
        </>
    );
}
