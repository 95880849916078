import {useEffect, useState, useCallback, useMemo, useLayoutEffect} from 'react';
// @mui
import {Divider,Tabs, Tab, IconButton,Button,Dialog,Card,Chip,TextField, List, MenuItem,FormControl,OutlinedInput,InputAdornment, Paper,CardContent, Box, Grid, Badge, Avatar, Select, Stack, Rating, Tooltip, Typography} from "@mui/material";
import { CustomAvatar } from "src/components/custom-avatar";
import {useLocation, useNavigate, useParams} from 'react-router-dom';
// @types
import useResponsive from "src/hooks/useResponsive";
import Iconify from 'src/components/iconify/Iconify';
import {styled, Theme} from '@mui/material/styles';
import { getCoresTemplate } from 'src/utils/coresTemplate';
import {useSettingsContext} from '../../../../components/settings';
import TextMaxLine from '../../../../components/text-max-line';

import { IUserProfile, IUserProfilePost } from '../../../../@types/user';

import './CardQuiz.css'

import {PATH_DASHBOARD} from "../../../../routes/paths";
import axios from "../../../../utils/axios";
import {BadgeStatusValue} from "../../../../components/badge-status";
import {useAuthContext} from "../../../../auth/useAuthContext";
import {criarevento} from "../../../../utils/analytics ";



// ----------------------------------------------------------------------

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

type props = {
    tituloPost: any;
    dataPost: any;
    descricaoPost: any;
    linkTeste: any;
    imagePost: any;
  };

export default function CardQuiz({ tituloPost, dataPost,descricaoPost,linkTeste, imagePost}: props) {

    const isDesktop = useResponsive('up', 'md');


    

    const POST_INFO = [
        { id: 'comment', value: 123, icon: 'eva:message-circle-fill' },
        { id: 'view', value: 120, icon: 'eva:eye-fill' },
        { id: 'share', value: 50, icon: 'eva:share-fill' },
    ];

  // @ts-ignore
  // @ts-ignore
  return (

    <>
            <CardContent
            sx={{
            pt: 4.5,
            width: 1,
            color: 'common.white',
            }}
            className='CardQuiz'
            >
            
         

            <Grid container spacing={2} sx={isDesktop ? { display: 'flex', flexDirection: 'reverse-column' } : {}}>
                    <Grid item xs={12} md={8}>
                        <Item>
                            <Typography
                                    gutterBottom
                                    variant="caption"
                                    component="div"
                                    sx={{
                                    color: 'text.disabled',
                                    opacity: 0.64,
                                    textAlign: 'left'
                                    }}
                                >
                                {dataPost}
                            </Typography>

                            <TextMaxLine
                                variant={isDesktop ? 'h5' : 'subtitle2'}
                                line={2}
                                persistent
                                sx={{
                                    textAlign: 'left'
                                }}
                                >
                                {tituloPost}
                            </TextMaxLine>

                            <Typography variant='body2' sx={{textAlign: 'left'}}>
                                {descricaoPost}
                            </Typography>

                            

                        </Item>

                        <Item sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 3}}>

                            <Button variant='contained' color='primary' component='a' sx={{px: 3, py: 1}} href={linkTeste}>Iniciar</Button>

                            <Stack
                                flexWrap="wrap"
                                direction="row"
                                justifyContent="flex-end"
                                alignItems='center'
                                sx={{
                                color: 'text.disabled',
                                opacity: 0.64
                                }}
                            >
                                {POST_INFO.map((info) => (
                                    <Stack
                                        key={info.id}
                                        direction="row"
                                        alignItems="center"
                                        sx={{ typography: 'caption', ml: info.id === 'comment' ? 0 : 1.5 }}
                                    >
                                        <Iconify icon={info.icon} width={16} sx={{ mr: 0.5 }} />
                                        {info.value}
                                    </Stack>
                                ))}
                            </Stack>

                        </Item>

                        



                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Item sx={{height: 1}}> <img src={imagePost} alt="Ansiedade" className='ImgCardQuiz' /> </Item>
                    </Grid>
                </Grid>
            
       

            </CardContent>
    </>
  );
}
