import {useEffect, useState} from 'react';
import {Outlet, useParams} from 'react-router-dom';
// @mui
import { Box, DialogContent } from '@mui/material';
// hooks
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import NotificaAudio from 'src/sections/@dashboard/Notificacoes/ComponetesNotificacoes/NotificaAudio';
import NotificaVideo from 'src/sections/@dashboard/Notificacoes/ComponetesNotificacoes/NotificaVideo';
import useResponsive from '../../hooks/useResponsive';
// components
import { useSettingsContext } from '../../components/settings';
//
import Main from './Main';
import Header from './header';
import NavMini from './nav/NavMini';
import NavVertical from './nav/NavVertical';
import NavHorizontal from './nav/NavHorizontal';
import {useAuthContext} from "../../auth/useAuthContext";
import Notificacoes from "../../sections/@dashboard/Notificacoes/Notificacoes";
import ChamadaRecusadaDialog from "../../sections/@dashboard/Notificacoes/ComponetesNotificacoes/ChamadaRecusadaDialog";
import ChamadaEncerradaDialog
    from "../../sections/@dashboard/Notificacoes/ComponetesNotificacoes/ChamadaEncerradaDialog";






// ----------------------------------------------------------------------
type props = {
    socket: any;
};
export default function DashboardLayout({socket}:props) {
  const { themeLayout } = useSettingsContext();

  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);
  const [openmodal, setopenmodal] = useState(false);

  const [openmodalChamadas, setopenmodalChamadas] = useState(false);

  const [open2, setOpen2] = useState<any>('');
  const [Dadosnoti, setDadosnoti] = useState<any>('');
    const { user } = useAuthContext();
  const isNavHorizontal = themeLayout === 'horizontal';
    const {tokenConversa = ''} = useParams();
  const isNavMini = themeLayout === 'mini';

  const handleOpen = () => {
    setOpen(true);
  };


    useEffect(() => {

       const  url = new URL(window.location.href);
        url.searchParams.delete("code");
        url.searchParams.delete("scope");
        url.searchParams.delete("authuser");
        url.searchParams.delete("prompt");
        window.history.pushState('object or string', 'Title', url);
        socket.emit('entrarusuario', `atendido${  user?.id}`);
        socket.on("connect", () => {
            console.log('usuario relogado');
            socket.emit('entrarusuario', `atendido${  user?.id}`);

            if(tokenConversa){
                console.log('entrou na sala');
                socket.emit('entrarsala', tokenConversa);
            }

        });// client-side




    },[socket, tokenConversa, user?.id]);



    useEffect(() => {

        let timer:any;

        socket.on("notifica", async (dados:any)  => {

        if(dados.tipo === "notificaaudio" || dados.tipo === "notificavideo"){
            setopenmodalChamadas(true);
        }else{
            setopenmodal(true);
        }

            setOpen2(dados.tipo);
            setDadosnoti(dados);



        });

    },[open2, socket, user]);


    console.log( open2);
    console.log( Dadosnoti);
    console.log( open2 === "notificavideo");


  const handleClose = () => {
    setOpen(false);
  };

  const fecharmodal = () => {
    setOpen2('');
    setopenmodal(false);
    setopenmodalChamadas(false);
  };


  const [tipoDialogChamada, setTipoDialogChamada] = React.useState('');

  const [openDialogChamada, setOpenDialogChamada] = React.useState(true);

  const handleClickOpenDialogChamada = () => {
    setOpenDialogChamada(true);
  };

  const handleCloseDialogChamada = () => {
    setOpenDialogChamada(false);
  };




    // notificaaudio
    // notificavideo

    const tipo = (
        <>



            { openmodal  ?
                (
                    <Dialog
                        fullScreen
                        scroll='body'
                        open
                    ><Notificacoes fecharmodal={fecharmodal} dados={Dadosnoti} estadoNotifica={open2}/>
                    </Dialog>
                ) : ('')
            }
        </>
    );


    const dialogChamada = (
      <>
        
                  <Dialog
                    open={openmodalChamadas}
                    scroll='body'
                    className='DialogAudioEVideo'
                  >
                      { open2 === "notificaaudio" && Dadosnoti.tipochamada === 'recebida' ? (
                            <NotificaAudio socket={socket}  fecharmodal={fecharmodal} dados={Dadosnoti}  />
                      ) : ''}

                      { open2 === "notificaaudio" && Dadosnoti.tipochamada === 'recusada' ? (
                            <ChamadaRecusadaDialog  fecharmodal={fecharmodal} dados={Dadosnoti}  />
                      ) : ''}


                      { open2 === "notificaaudio" && Dadosnoti.tipochamada === 'encerrou' ? (
                            <ChamadaEncerradaDialog  fecharmodal={fecharmodal} dados={Dadosnoti}  />
                      ) : ''}




                      { open2 === "notificavideo" && Dadosnoti.tipochamada === 'recebida'? (
                            <NotificaVideo socket={socket} fecharmodal={fecharmodal} dados={Dadosnoti}/>
                      ) : ''}

                      { open2 === "notificavideo" && Dadosnoti.tipochamada === 'recusada' ? (
                          <ChamadaRecusadaDialog   fecharmodal={fecharmodal} dados={Dadosnoti}  />
                      ) : ''}

                      { open2 === "notificavideo" && Dadosnoti.tipochamada === 'encerrou' ? (
                          <ChamadaEncerradaDialog   fecharmodal={fecharmodal} dados={Dadosnoti}  />
                      ) : ''}

                  </Dialog> 
                
                   
        
      </>
    )


  const renderNavVertical = <NavVertical openNav={open} onCloseNav={handleClose} />;


  if (isNavHorizontal) {
    return (
      <>
          {tipo}
          {dialogChamada}
        <Header onOpenNav={handleOpen} />

        {isDesktop ? <NavHorizontal /> : renderNavVertical}

        <Main>
          <Outlet />
        </Main>
      </>
    );
  }

  if (isNavMini) {
    return (
      <>
          {tipo}
          {dialogChamada}
        <Header onOpenNav={handleOpen} />

        <Box
          sx={{
            display: { lg: 'flex' },
            minHeight: { lg: 1 },
          }}
        >
          {isDesktop ? <NavMini /> : renderNavVertical}

          <Main>
            <Outlet />
          </Main>
        </Box>
      </>
    );
  }

  return (
    <>
        {tipo}
        {dialogChamada}
      <Header onOpenNav={handleOpen} />

      <Box
        sx={{
          display: { lg: 'flex' },
          minHeight: { lg: 1 },
        }}
      >
        {renderNavVertical}

        <Main>
          <Outlet />
        </Main>
      </Box>
    </>
  );
}
