import { Helmet } from 'react-helmet-async';
// sections
import ChamadaVideo from "../sections/@dashboard/ChamadaVideo/ChamadaVideo";



export default function ChamadaVideoPage({socket}:any) {
  return (
    <>
      <Helmet>
        <title>Chamada Vídeo Herói - Eyhe Maior Comunidade de Suporte Emocional do Brasil</title>
      </Helmet>

      <ChamadaVideo socket={socket}  />
    </>
  );
}
