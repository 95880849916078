import {useEffect, useState, useCallback, forwardRef} from 'react';
import {useLocation, useNavigate, useParams } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import * as React from "react";
import { TransitionProps } from '@mui/material/transitions';
import {Divider,Tabs, Tab, IconButton,Button,DialogTitle,DialogContent,Drawer,LinearProgress,ListItem,ListItemText,ListItemButton,AppBar,Slide,SlideProps ,Dialog,Toolbar,Card,Chip,TextField, List, MenuItem,FormControl,OutlinedInput,InputAdornment, Paper, Box, Grid, Badge, Avatar, Select, Stack, Rating, Tooltip, Typography} from "@mui/material";
import { DyteProvider, useDyteClient } from '@dytesdk/react-web-core';
import Iconify from "../../../components/iconify";
import {useSettingsContext} from '../../../components/settings';

import AudioRoom from './AudioRoom';
import './ChamadaAudio.css';

import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
// routes
import {PATH_DASHBOARD} from '../../../routes/paths';
import axios from "../../../utils/axios";
import {useAuthContext} from "../../../auth/useAuthContext";

import {useSnackbar} from "../../../components/snackbar";

import avatar1 from '../Comunidades/img/avatar_1.jpg'
import arrows from './img/arrows.png'
import avatar2 from '../Comunidades/img/avatar_2.jpg'

import tchau from './img/tchau.png'

import carregando from './img/loading.gif'
import ChatNotificacoes from "../chat/header/ChatNotificacoes";




// ----------------------------------------------------------------------

const Transition = forwardRef<unknown, SlideProps>((props, ref) => (
    <Slide direction="up" ref={ref} {...props} />
  ));

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

export default function ChamadaAudio({socket}:any) {

  const [status, setStatus] = useState('solicitado')

  const [open, setOpen] = React.useState(false);

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const [openhist, setOpenhis] = useState(true);
    const {tokenConversa = ''} = useParams();

    const [meeting, initMeeting] = useDyteClient();

    useEffect(() => {




        const fetchRepos = async () => {

            try {
                const response = await axios.post(`/areadoheroi/getauthtoken`,{tokenConversa,tipo:'audio'});

                const {authtoken} = response.data;

                initMeeting({
                    authToken:authtoken,
                    defaults: {
                        video: true,
                        audio: false,
                    },
                }).then((m) => m?.joinRoom());


            } catch (error) {
                console.error(error);
            }
        };



        fetchRepos();




        

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    Object.assign(window, { meeting });



    const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openDrawer, setOpenDrawer] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpenDrawer(newOpen);
  };



  


  const [progress, setProgress] = useState(100);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 0) {
          clearInterval(timer);
          return 0;
        }
        return oldProgress - 1;
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);




  useEffect(() =>  () => {
          meeting?.leaveRoom();
      }, [meeting]);

  const minutes = Math.floor(progress / 60);
  const seconds = progress % 60;


    const [valorsomar, setvalorsomar] = useState<number>(0);
    const [time, setTime] = useState(40000000);
    const [tipoconversa, settipoconversa] = useState<any>({ Duracao: 200 });
    const [usera,setuser] = useState<any>({});


    const getmessage = useCallback(async () => {
        try {
            const response = await axios.post('/areadoheroi/gettimeconversa', {
                tokenConversa,
            });

            const { timeconversa, tipoconversas } = response.data;

            if (timeconversa === 0) {
                setvalorsomar(0);
                meeting?.leaveRoom();
                navigate(PATH_DASHBOARD.chat.view(tokenConversa),{replace:true});
            }else{
                setTime(timeconversa);
                setvalorsomar(1);
            }




            settipoconversa(tipoconversas);
        } catch (error) {
            enqueueSnackbar(`${error.message}`, { variant: 'error' });
        }
    }, [enqueueSnackbar, meeting, navigate, tokenConversa]);

    useEffect(() => {
        getmessage();
    }, [getmessage]);



    const getusers = useCallback(async () => {
        try {
            const response = await axios.get(`/areadoheroi/atendente/${  tokenConversa  }`);

            const {atendente} = response.data;

            setuser(atendente)

        } catch (error) {
            enqueueSnackbar(`${ error.message}`, { variant: 'error' });
            navigate(PATH_DASHBOARD.dashfirst);
        }
    }, [enqueueSnackbar, navigate, tokenConversa]);

    useEffect(() => {

        getusers();

    }, [getusers]);



    useEffect(() => {
        const timer = setInterval(() => {
            (async function() {
                try {
                    const response = await axios.post('/areadoheroi/gettimeconversa', {
                        tokenConversa
                    });

                    const {timeconversa} = response.data;


                    if (timeconversa === 0) {
                        clearInterval(timer);
                        setvalorsomar(0);
                        meeting?.leaveRoom();
                        navigate(PATH_DASHBOARD.chat.view(tokenConversa),{replace:true});

                    }else{
                        setTime(timeconversa);
                        setvalorsomar(1);
                    }




                } catch (error) {
                    enqueueSnackbar(`${ error.message}`, { variant: 'error' });
                }
            })();
        }, 60000);





        return () => {
            clearInterval(timer);
        };
    }, [enqueueSnackbar, meeting, navigate, time, tokenConversa]);







    return (
            <>

            <Dialog
                fullScreen
                open
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <DialogContent className='dialogGlobalChamadaAudio'>
                    <ChatNotificacoes time={time} Atendente={usera} tipoConversa={tipoconversa} openhist={openhist} setOpenhis={setOpenhis}/>
                    <DyteProvider value={meeting} fallback={
                      <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{height: '100vh'}}
                    >
                        <img src={carregando} alt='Carregando' width={80} />
                        <Typography variant='subtitle1' sx={{mt: 2}} color='white'>Carregando o Áudio...</Typography>
                    </Grid>
                    }>
                        <AudioRoom  socket={socket} usera={usera}/>
                    </DyteProvider>


                </DialogContent>
                 
                
                
                
            </Dialog>
    
            </>
    );
}
