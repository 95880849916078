import { Dialog,DialogTitle ,MenuItem,FormControl,InputLabel, Select, Grid,SelectChangeEvent , DialogContent,ListItemText, Box, Rating, useMediaQuery ,ListItem,List,Tooltip, Divider, AppBar , Toolbar ,IconButton  ,Slider,Slide, Button, TextField, Tabs, Tab, InputAdornment, Paper, Typography} from "@mui/material";
import { TransitionProps } from '@mui/material/transitions';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import React, {useEffect, useState} from "react";
import {styled, useTheme} from "@mui/material/styles";
import { CustomAvatar } from "src/components/custom-avatar";
import {useSettingsContext} from "../../../../components/settings";
import {useAuthContext} from "../../../../auth/useAuthContext";
import {PATH_DASHBOARD} from "../../../../routes/paths";
import Iconify from "../../../../components/iconify";
import MenuPopover from "../../../../components/menu-popover";
import "./FiltrarAtendente.css";    
import BadgeStatus, {BadgeStatusValue} from "../../../../components/badge-status";
import axios from "../../../../utils/axios";

import iconAtendente1 from "../img/love.svg"
import iconAtendente2 from "../img/psychology.svg"
import iconAtendente4 from "../img/terapia.png"
import iconAtendente5 from "../img/coach.png"

  type props = {
    setassunto?: any,
};

export default function FiltroAssuntoMobile({setassunto}:props) {

    const [valueAssunto, setValueAssunto] = React.useState('');

    const handleAssunto = (event: SelectChangeEvent<string>) => {
      const newValue = event.target.value;
        sessionStorage.setItem('assunto',event.target.value);
      setValueAssunto(newValue);
      setassunto(newValue);
    };

    const Buscar = () => {
        setassunto(valueAssunto);
    }

    useEffect(() => {
        if(sessionStorage.getItem('assunto')){
            // @ts-ignore
            setValueAssunto(sessionStorage.getItem('assunto'));
        }

    }, []);



return (
   
        <>
            {valueAssunto === '' ? (<Typography className="SubTituloCardFiltroAtendente" sx={{mt: 1.5}}>Selecione o assunto</Typography>) : ('')}
                <FormControl sx={valueAssunto !== '' ? { my: 2 } : {mb:1.5}} fullWidth>
                    <InputLabel id="demo-simple-select-label"> {valueAssunto === '' ? ('Todos os assuntos') : ('Assunto selecionado:')} </InputLabel>
                    <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={valueAssunto}
                    label="Todos os Assuntos"
                    onChange={handleAssunto}
                    
                    >
                            <MenuItem value=""> Todos os assuntos</MenuItem>
                            <MenuItem value="25"><div className="IconsAssuntosFiltroMobile"><img src="https://api.eyhe.com.br/img/filtrar-assuntos/Drugs Addict.svg" height="30px" alt="Icone Vícios" /></div> Vícios</MenuItem>
                            <MenuItem value="17"> <div className="IconsAssuntosFiltroMobile"><img src="https://api.eyhe.com.br/img/filtrar-assuntos/Crying.svg" height="30px" alt="Icone Abuso Sexual" /></div>  Abuso Sexual</MenuItem>
                            <MenuItem value="2"><div className="IconsAssuntosFiltroMobile"><img src="https://api.eyhe.com.br/img/filtrar-assuntos/Intrusive thought.svg" height="30px" alt="Icone Ansiedade" /></div> Ansiedade</MenuItem>
                            <MenuItem value="1"><div className="IconsAssuntosFiltroMobile"><img src="https://api.eyhe.com.br/img/filtrar-assuntos/Depression.svg" height="30px" alt="Icone Depressão" /></div> Depressão</MenuItem>
                            <MenuItem value="3"><div className="IconsAssuntosFiltroMobile"><img src="https://api.eyhe.com.br/img/filtrar-assuntos/Alzeimer.svg" height="30px" alt="Icone Luto" /></div> Luto</MenuItem>
                            <MenuItem value="7"><div className="IconsAssuntosFiltroMobile"><img src="https://api.eyhe.com.br/img/filtrar-assuntos/Mental Health.svg" height="30px" alt="Icone Problemas de Saúde" /></div> Problemas de saúde</MenuItem>
                            <MenuItem value="4"><div className="IconsAssuntosFiltroMobile"><img src="https://api.eyhe.com.br/img/filtrar-assuntos/Support System.svg" height="30px" alt="Icone Relacionamentos" /></div> Relacionamento</MenuItem>
                            <MenuItem value="18"><div className="IconsAssuntosFiltroMobile"><img src="https://api.eyhe.com.br/img/filtrar-assuntos/Emotion.svg" height="30px" alt="Icone Amor" /></div> Amor</MenuItem>
                            <MenuItem value="24"><div className="IconsAssuntosFiltroMobile"><img src="https://api.eyhe.com.br/img/filtrar-assuntos/Stress.svg" height="30px" alt="Icone Stress" /></div> Stress</MenuItem>
                            <MenuItem value="22"><div className="IconsAssuntosFiltroMobile"><img src="https://api.eyhe.com.br/img/filtrar-assuntos/Hypersex.svg" height="30px" alt="Icone Gênero" /></div> Gênero</MenuItem>
                            <MenuItem value="12"><div className="IconsAssuntosFiltroMobile"><img src="https://api.eyhe.com.br/img/filtrar-assuntos/Insecure.svg" height="30px" alt="Icone Insegurança" /></div> Insegurança</MenuItem>
                            <MenuItem value="21"><div className="IconsAssuntosFiltroMobile"><img src="https://api.eyhe.com.br/img/filtrar-assuntos/Phobia.svg" height="30px" alt="Icone Fobias" /></div> Fobias</MenuItem>
                            <MenuItem value="23"><div className="IconsAssuntosFiltroMobile"><img src="https://api.eyhe.com.br/img/filtrar-assuntos/Sleep.svg" height="30px" alt="Icone Insônia" /></div> Insônia</MenuItem>
                            <MenuItem value="19"><div className="IconsAssuntosFiltroMobile"><img src="https://api.eyhe.com.br/img/filtrar-assuntos/Autism.svg" height="30px" alt="Icone Autismo" /></div> Autismo</MenuItem>
                            <MenuItem value="20"><div className="IconsAssuntosFiltroMobile"><img src="https://api.eyhe.com.br/img/filtrar-assuntos/Bipolar.svg" height="30px" alt="Icone Bipolaridade" /></div> Bipolaridade</MenuItem>
                            <MenuItem value="26"><div className="IconsAssuntosFiltroMobile"><img src="https://api.eyhe.com.br/img/filtrar-assuntos/Espiritualidade.svg" height="30px" alt="Icone Espiritualidade" /></div> Espiritualidade</MenuItem>
                    </Select>
                </FormControl>
        </>
   

      


)
}