import {useEffect, useState, useCallback, forwardRef} from 'react';
import {
    DyteAudioVisualizer,
    DyteAvatar,
    DyteNameTag,
    DyteParticipantTile,
    DyteParticipantsAudio,
    DyteSimpleGrid,
    provideDyteDesignSystem,
} from '@dytesdk/react-ui-kit';
import { useDyteMeeting, useDyteSelector } from '@dytesdk/react-web-core';
import useResponsive from 'src/hooks/useResponsive';
import {Divider,Tabs, Tab, IconButton,Button,DialogTitle,DialogContent,Drawer,CircularProgress,LinearProgress,ListItem,ListItemText,Grid, ListItemButton,AppBar,Slide,SlideProps ,Dialog,Toolbar,Card,Chip,TextField, List, MenuItem,FormControl,OutlinedInput,InputAdornment, Paper, Box, Badge, Avatar, Select, Stack, Rating, Tooltip, Typography} from "@mui/material";
import clsx from 'clsx';
import Draggable from 'react-draggable';
import { Mic, Video, X } from 'react-feather';
import {useNavigate, useParams} from "react-router-dom";
import Iconify from "../../../components/iconify";
import './ChamadaVideo.css';

import avatar1 from '../Comunidades/img/avatar_1.jpg'
import arrows from './img/arrows.png'
import avatar2 from '../Comunidades/img/avatar_2.jpg'

import tchau from './img/tchau.png'
import chamadaRecusada from './img/chamadarecusada.png'

import carregando from './img/loading.gif'

import {PATH_DASHBOARD} from "../../../routes/paths";
import axios from "../../../utils/axios";
import {useSnackbar} from "../../../components/snackbar";
import {useAuthContext} from "../../../auth/useAuthContext";

function Grid2() {
    const { meeting } = useDyteMeeting();
    const participants = useDyteSelector((m) => m.participants.active);

    const isDesktop = useResponsive('up', 'lg');

    return (
        <div >
            {participants.size === 0 && (
                <p className="textobranco">
                    <img src={carregando} alt='Carregando' width={80} />
                    <Typography variant='subtitle1' sx={{mt: 2}} color='white'>Aguarde o atendente entrar</Typography>
                </p>
            )}
            {participants.size > 0 && (
                <DyteSimpleGrid
                    participants={participants.toArray()}
                    meeting={meeting}
                    gap={18}
                    aspectRatio= {isDesktop ? ('18:9') : ('9:18') }
                    className='dialogGlobalChamadaVideo'
                />
            )}
        </div>
    );
}

function Controlbar({socket,usera}:any) {

    const navigate = useNavigate();
    const { meeting } = useDyteMeeting();
    const {tokenConversa = ''} = useParams();

    const { enqueueSnackbar } = useSnackbar();

    const { user} = useAuthContext();

    const participants = useDyteSelector((m) => m.participants.joined);

    const { videoEnabled, audioEnabled } = useDyteSelector((m) => ({
        videoEnabled: m.self.videoEnabled,
        audioEnabled: m.self.audioEnabled,
    }));



    
    
    const toggleCamera = () => {

        if (meeting.self.videoEnabled) {
            meeting.self.disableVideo();
        } else {
            meeting.self.enableVideo();
            console.log(meeting.self.videoEnabled,'dsdsdsdd');
        }
    };

    const toggleMic = () => {


        if (meeting.self.audioEnabled) {
            meeting.self.disableAudio();
        } else {
            meeting.self.enableAudio();
        }
    };

    const leaveMeeting = async () => {
        meeting.leaveRoom();

        socket.emit("enviarnotifica",

            {
                iduser:`atendente${usera?.id}`,
                heroi: user?.nome,
                heroiavatar: user?.avatar,
                anjo: usera?.nome,
                tipo:'notificavideo',
                tipochamada:'encerrou',
                tokenConversa,
            });
        navigate(PATH_DASHBOARD.chat.view(tokenConversa),{replace:true});
    };
    const [openDrawer, setOpenDrawer] = useState(false);

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpenDrawer(newOpen);
    };

    const DrawerList = (
        <Box sx={{ width: '100%', p:3, zIndex: '999', position: 'relative', overflow: 'visible'}} role="presentation" onClick={toggleDrawer(false)}>

            <img src={tchau} className='imgSairChamada' width={80} alt='Deseja sair da chamada?' />
            <Typography variant='h4' sx={{textAlign:'center', mb: 1}}>Ei, você deseja mesmo encerrar essa chamada?</Typography>
            <Typography variant='subtitle1' sx={{ textAlign: 'center', mb: 2, lineHeight: 1.3}}>Você será direcionado para o chat de mensagens novamente</Typography>

            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <Button fullWidth variant='outlined' color='warning' sx={{py: 1.5}}>Voltar</Button>
                </Grid>
                <Grid item xs={6}>
                    <Button fullWidth variant='contained' color='error' onClick={leaveMeeting} sx={{py: 1.5}}>Encerrar a chamada</Button>
                </Grid>
            </Grid>

        </Box>
    );



    const [openDrawerRecusado, setOpenDrawerRecusado] = useState(true);

    const toggleDrawerRecusado = (newOpen: boolean) => () => {
        setOpenDrawerRecusado(newOpen);
    };

    const DrawerRecusado = (
        <Box sx={{ width: '100%', p:3, zIndex: '999', position: 'relative', overflow: 'visible'}} role="presentation" onClick={toggleDrawer(false)}>

            <img src={chamadaRecusada} className='imgSairChamada' width={80} alt='Chamada Recusada' />
            <Typography variant='h4' sx={{textAlign:'center', mb: 1}}>Ei, parece que sua chamada foi recusada.</Typography>
            <Typography variant='subtitle1' sx={{ textAlign: 'center', mb: 2, lineHeight: 1.3}}>Clique no botão abaixo para ser direcionado para o chat de mensagens novamente</Typography>

            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Button fullWidth variant='contained' color='error' sx={{py: 1.5}}>Voltar para o chat</Button>
                </Grid>
            </Grid>
        </Box>
    );



    return (
<>
        <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            className='menuFixedActionsCallVideo'
            sx={{position: 'fixed', bottom: '0px', left: '0px', width: '100%', py: 2}}
        >
            {audioEnabled ? (
            <IconButton size='large' onClick={toggleMic} >
                <Iconify className='iconsActionChamadaVideo' icon="eva:mic-outline" color='white' />
            </IconButton>

            ):(
                <IconButton size='large' onClick={toggleMic} >
                    <Iconify className='iconsActionChamadaVideo' icon="eva:mic-off-fill" color='white' />
                </IconButton>
            )}

            <IconButton size='large' className='btnEncerrarChamadaVideo' id='btnEncerrarChamadaVideo'  onClick={toggleDrawer(true)} >
                <Iconify className='iconsActionChamadaVideo' icon="eva:phone-off-outline" color='white' />
            </IconButton>

            {videoEnabled ? (
            <IconButton size='large'   onClick={toggleCamera}>
                <Iconify className='iconsActionChamadaVideo' icon="eva:video-fill" color='white'     />
            </IconButton>
            ):(
                <IconButton size='large' onClick={toggleCamera}>
                    <Iconify className='iconsActionChamadaVideo' icon="eva:video-off-fill" color='white'   />
                </IconButton>
            )}

            <Drawer anchor="bottom" open={openDrawer} sx={{zIndex: '9999', overflow: 'visible'}} onClose={toggleDrawer(false)}>
                {DrawerList}
            </Drawer>

            {/* <Drawer anchor="bottom" open={openDrawerRecusado} sx={{zIndex: '9999', overflow: 'visible'}} onClose={toggleDrawerRecusado(false)}> */}
            {/*    {DrawerRecusado} */}
            {/* </Drawer> */}

        </Grid>


</>
    );
}

export default function Facetime({socket,usera}:any) {
    const { meeting } = useDyteMeeting();

    const roomJoined = useDyteSelector((m) => m.self.roomJoined);

    useEffect(() => {
        provideDyteDesignSystem(document.body, {
            colors: {
                'video-bg': '#333333',
            },
        });
    }, []);

    if (!roomJoined) {
        return (
            <p className="textobranco">
            
                    <img src={carregando} alt='Carregando' width={80} />
                    <Typography variant='subtitle1' sx={{mt: 2}} color='white'>Aguarde a conversa ser carregada...</Typography>
            
            </p>
        );
    }

    return (
        <div  className='FaceTimeDiv'>
            <DyteParticipantsAudio meeting={meeting}   />

            <Grid2 />

            <Draggable bounds="parent"  >
                <DyteParticipantTile
                    participant={meeting.self}
                    meeting={meeting}
                    key={meeting.self.id}
                    className='dialogminiVideo'

                >
                    <DyteAvatar participant={meeting.self} size="md" />
                    <DyteNameTag participant={meeting.self} size="md">
                        <DyteAudioVisualizer
                            participant={meeting.self}
                            size="md"
                            slot="start"
                        />
                    </DyteNameTag>
                </DyteParticipantTile>
            </Draggable>

            <Controlbar socket={socket} usera={usera} />
        </div>
    );
}