import * as React from 'react';
import {styled} from '@mui/material/styles';

import {CssBaseline, Box, Container,Grid,Paper, IconButton, Typography, Tooltip, Button, Link, Dialog, DialogContent } from "@mui/material";
import {useEffect, useState, useCallback, useRef} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import "./notifica.css";
// @mui
import useResponsive from "../../../../hooks/useResponsive";

import {useSettingsContext} from '../../../../components/settings';
// eslint-disable-next-line import/no-cycle
import notificacaoAudio from '../img/notificacao_audio.png'

import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs';
// routes
import {PATH_AUTH, PATH_DASHBOARD} from '../../../../routes/paths';
import axios from "../../../../utils/axios";
import {useAuthContext} from "../../../../auth/useAuthContext";
import Iconify from "../../../../components/iconify";
// eslint-disable-next-line import/no-cycle

import fotoPerfil from './img/avatar_1.jpg'

import './ChamadasDialogs.css'


// ----------------------------------------------------------------------

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));


type props = {
    fecharmodal: any,
    dados: any,
    socket: any,
};

export default function NotificaAudio({fecharmodal,dados,socket}:props) {

    const navigate = useNavigate();
    const iniciarchamada = ()=>{
        fecharmodal();
        navigate(PATH_DASHBOARD.chamadaaudio(dados.tokenConversa),{replace:true});
    }
    const recusarchamada = ()=>{


        socket.emit("enviarnotifica",

            {
                iduser:`atendente${dados?.idatendente}`,
                heroi: dados?.heroi,
                heroiavatar: dados?.heroiavatar,
                anjo: dados?.anjo,
                tipo:'notificaaudio',
                tipochamada:'recusada',
                tokenConversa:dados.tokenConversa,
            });
        fecharmodal();
        navigate(PATH_DASHBOARD.chat.view(dados.tokenConversa),{replace:true});
    }

    const chat = ()=>{


        fecharmodal();
        navigate(PATH_DASHBOARD.chat.view(dados.tokenConversa),{replace:true});
    }



    const isDesktop = useResponsive('up', 'lg');

    return (
        <>

        

            <DialogContent className='ChamadaRecebidaDialogCard'>
            <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            >
                <Typography variant='h6' sx={{textAlign: 'center'}}>Chamada Recebida</Typography>
                <img src={dados.atedenteavatar} className='FotoAtendenteChamadaRecebidaDialog' alt={dados.atedenteavatar}/>
                <Typography variant='h4' sx={{textAlign: 'center'}}>O Anjo {dados.anjo} solicitou uma chamada de áudio com você.</Typography>
                <Typography variant='subtitle2' sx={{textAlign: 'center'}}>Clique no botão abaixo para aceitar:</Typography>
            </Grid>

            <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{my: 3}}
            >
                <IconButton color='primary' onClick={chat}>
                    <Iconify icon='eva:message-circle-outline' />
                </IconButton>
                <Typography variant='subtitle2'>Acessar o chat</Typography>
            </Grid>
            

            <Grid container spacing={2}>
                <Grid item md={6} xs={6}>
                    <Item> 
                        <IconButton className='RecusarChamada' onClick={recusarchamada}>
                            <Iconify icon='eva:phone-missed-outline' color='white' />
                        </IconButton> 
                        <Typography variant='subtitle2' color='white' sx={{mt: 1}}>Recusar Chamada</Typography>
                    </Item>
                </Grid>
                <Grid item md={6} xs={6}>
                    <Item>
                        <IconButton className='AceitarChamada' onClick={iniciarchamada}>
                            <Iconify icon='eva:phone-outline' color='white' />
                        </IconButton> 
                        <Typography variant='subtitle2' color='white' sx={{mt: 1}}>Atender Chamada</Typography>
                    </Item>
                </Grid>
            </Grid>
            
        </DialogContent>
       

        </>
    );
}