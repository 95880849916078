import { useState, useRef } from 'react';
// @mui
import { alpha, styled  } from '@mui/material/styles';
import {
  Box,
  Link,
  Card,
  Grid,TextField,
  Stack,
  Stepper,
  Step,
  StepLabel,
  Button,
  Paper,
  Tabs, 
  Tab,
  Checkbox,
  InputBase,
  Typography,
  Dialog,
  DialogContent,
  CardHeader,
  IconButton,
  InputAdornment,
  AvatarGroup,
  Avatar,
  FormControlLabel,
} from '@mui/material';
// @types
import { IUserProfilePost } from '../../../../@types/user';
// auth
import { useAuthContext } from '../../../../auth/useAuthContext';
// utils
import { fDate } from '../../../../utils/formatTime';
import { fShortenNumber } from '../../../../utils/formatNumber';
// components
import Image from '../../../../components/image';
import Iconify from '../../../../components/iconify';
import { CustomAvatar, CustomAvatarGroup } from '../../../../components/custom-avatar';

import './ForumConversa.css';

import ouro from '../img/ouro.png';
import prata from '../img/prata.png';
import bronze from '../img/bronze.png';

import avatar1 from '../img/avatar_1.jpg';
import avatar2 from '../img/avatar_2.jpg';
import avatar3 from '../img/avatar_3.jpg';
import avatar4 from '../img/avatar_4.jpg';
import avatar5 from '../img/avatar_5.jpg';
import avatar6 from '../img/avatar_6.jpg';
import avatar7 from '../img/avatar_7.jpg';
import avatar8 from '../img/avatar_8.jpg';
import avatar9 from '../img/avatar_9.jpg';
import avatar10 from '../img/avatar_10.jpg';
import avatar11 from '../img/avatar_11.jpg';
import avatar12 from '../img/avatar_12.jpg';
import avatar13 from '../img/avatar_13.jpg';
import avatar14 from '../img/avatar_14.jpg';
import avatar15 from '../img/avatar_15.jpg';
import avatar16 from '../img/avatar_16.jpg';
import avatar17 from '../img/avatar_17.jpg';
import avatar18 from '../img/avatar_18.jpg';
import avatar19 from '../img/avatar_19.jpg';
import avatar20 from '../img/avatar_20.jpg';
import avatar21 from '../img/avatar_21.jpg';
import avatar22 from '../img/avatar_22.jpg';
import avatar23 from '../img/avatar_23.jpg';
import avatar24 from '../img/avatar_24.jpg';
import axios from "../../../../utils/axios";
import {useSnackbar} from "../../../../components/snackbar";




// ----------------------------------------------------------------------

interface Props {
  post: any;
    participantedado: any;
    id: any;
    atualizarmenssagens: any;
    rankdados: any;
    personLikes: any;

}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export default function LateralForum({ post, participantedado,id,atualizarmenssagens,rankdados,personLikes}: Props) {

  const [value, setValue] = useState(0);
  const [apelido, setApelido] = useState<any>('');

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { user } = useAuthContext();



  const fileInputRef = useRef<HTMLInputElement>(null);





  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const [selectedAvatar, setSelectedAvatar] = useState<any>('');

  const handleAvatarClick = (avatarNumber: number) => {
    setSelectedAvatar(avatarNumber);
  };
    const { enqueueSnackbar } = useSnackbar();



    const handleparticipar = async () => {

        if(selectedAvatar === '' ){
            enqueueSnackbar(`Selecione um avatar`, { variant: 'error' });
            return false;
        }

        if(apelido === '' ){
            enqueueSnackbar(`Digite um Apelido`, { variant: 'error' });
            return false;
        }


        console.log(apelido);
        try {

            const response = await axios.post(`/areadoheroi/comunidade-participar`, {
                IDComunidade:id,
                Apelido:apelido,
                Avatar:selectedAvatar,
            });
            atualizarmenssagens();
            handleClose();

        } catch (error) {
            enqueueSnackbar(`${ error}`, { variant: 'error' });

        }
        return true;
    };

  const renderAvatars = () => {
    const avatars = [];

    for (let i = 1; i <= 24; i+=1) {
      const avatarClassName = selectedAvatar === i ? 'avatar-clicked' : '';

      avatars.push(
        <Grid item md={2} xs={3} key={i}>
          <Item>
            <Avatar
              alt={`Avatar ${i}`}
              src={`https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_${i}.jpg`}
              onClick={() => handleAvatarClick(i)}
              className= {` avatares-comunidade ${avatarClassName}`} 
            />
          </Item>
        </Grid>
      );
    }

    return avatars;
  };
  

  return (
    <>

        <Card sx={{p: 2, background: 'rgba(31, 99, 209, 0.2)'}}>


            { participantedado.isparticipante === false ? (
                    <Button variant='contained' color='primary' onClick={handleClickOpen} fullWidth>Participar</Button>)

                :(<Button variant='contained' color='primary' onClick={handleClickOpen} fullWidth>Editar</Button>)}

            {rankdados.map((rank:any,index:any) => (
                <div key={index}>
                    { index === 0 ? (
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{py:2}}>
                                <img src={ouro} height='30px' alt="Ouro" />
                                <Typography variant='subtitle2'>{rank.IDHeroi}({rank.quantidade})</Typography>
                            </Grid>)
                        :('')}
                    { index === 1 ? (
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{py:2}}>
                                <img src={prata} height='35px' alt="Prata" />
                                <Typography variant='subtitle2' sx={{ml: 1}}>{rank.IDHeroi}({rank.quantidade})</Typography>
                            </Grid>)
                        :('')}
                    { index === 1 ? (
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{py:2}}>
                                <img src={bronze} height='35px' alt="Bronze" />
                                <Typography variant='subtitle2' sx={{ml: 1}}>{rank.IDHeroi}({rank.quantidade})</Typography>
                            </Grid>
                        )
                        :('')}
                </div>
            ))}

            <CustomAvatarGroup sx={{mt: 2}}>
                {personLikes.map((person:any) => (
                    <CustomAvatar key={person.Apelido} alt={person.Apelido} src={person.avatar} />
                ))}
            </CustomAvatarGroup>
        </Card>
  

        <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
        
        <DialogContent sx={{px: 3, py: 1.5}}>
              

        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Avatar" {...a11yProps(0)} />
              <Tab label="Apelido"   {...a11yProps(1)} />
            </Tabs>
          </Box>

          <CustomTabPanel value={value} index={0}>
              <Typography variant='h4' sx={{mb: 2}}>Selecione aqui seu avatar:</Typography>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                {renderAvatars()}
              </Grid>



              
          </CustomTabPanel>

          <CustomTabPanel value={value} index={1}>
              <Typography variant='h4' sx={{mb: 2}}>Defina aqui seu apelido:</Typography>
              <TextField id="outlined-basic" label="Apelido" variant="outlined" sx={{mb: 2}} fullWidth   onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setApelido(event.target.value);
              }} />

              <Button variant='contained' color='success' fullWidth onClick={handleparticipar}>Pronto!</Button>
          </CustomTabPanel>

        </Box>
   
              



        </DialogContent>
        
      </Dialog>

        

    
    
    </>
  );
}







