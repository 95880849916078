import * as React from 'react';
import { useState, useRef, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Container,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import Iconify from 'src/components/iconify/Iconify';
import {useNavigate} from "react-router-dom";
import axios from "../../../../utils/axios";
import {useSnackbar} from "../../../../components/snackbar";
import {useAuthContext} from "../../../../auth/useAuthContext";
import {PATH_AUTH, PATH_DASHBOARD} from "../../../../routes/paths";
import {criarevento} from "../../../../utils/analytics ";

import iconWhatsapp from '../img/whatsapp.png';

type Props = {
  open: any;
  handleClose: any;
  dados: any;
};

export default function ValidarWhatsapp({ open, handleClose,dados }: Props) {

  const { validar } = useAuthContext();
  const navigate = useNavigate();
  const [inputValues, setInputValues] = useState<string[]>(Array(6).fill(''));
  const inputRefs = useRef<(HTMLInputElement | null)[]>(Array(6).fill(null));
  const { enqueueSnackbar } = useSnackbar();
  const verNums = () => {

  }

  const validard = async () => {
    console.log(dados('email'));

    try {

      if (validar) {
        await validar({email:dados('email'),codigo:inputValues});
         criarevento('cadastro_verificado');
        setTimeout(()=>{
          navigate(PATH_DASHBOARD.dashfirst);
        },3000);
      }


    } catch (error) {
      enqueueSnackbar(`${error.message}`, {variant: 'error'});
    }


  }

  useEffect(() => {

    inputRefs.current[0]?.focus();
  }, []);

  const setInputRef = (el: HTMLInputElement | null, index: number) => {
    inputRefs.current[index] = el;
  };

  const handleInputChange = (index: number, value: string) => {
    if (/^\d*$/.test(value) && value.length <= 1) {
      const newInputValues = [...inputValues];
      newInputValues[index] = value;
      setInputValues(newInputValues);

      // Move o foco para o próximo input se o valor for um dígito
      if (value && index < 5) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleChange = (e: React.ClipboardEvent<HTMLDivElement>) => {

const valor = e.clipboardData.getData('Text').split('');

    if(valor.length === 6){
      const newInputValues = [...inputValues];
      // eslint-disable-next-line no-plusplus
      for (let i=0; i<valor.length; i++)  {


        newInputValues[i] = valor[i];
        setInputValues(newInputValues);


       // console.log(valor[i]);
      }

    }else{
      enqueueSnackbar(`Erro ao colar, o codigo têm  6 caracteres`, { variant: 'error' });
    }
     // console.log(valor);

  };


  const handleInputKeyDown = (
    index: number,
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Backspace' && index > 0 && inputValues[index] === '') {
      // Move o foco para o input anterior ao pressionar "backspace"
      inputRefs.current[index - 1]?.focus();
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        disableEscapeKeyDown
        onClose={verNums}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Iconify icon='eva:email-outline' />
            <Typography variant="h4" gutterBottom>Você receberá em instantes um código no seu E-mail.</Typography>
            <Typography variant="subtitle1" gutterBottom>Insira o código recebido no seu E-mail nos campos abaixo e clique no botão &quot;validar&quot;.</Typography>
          </DialogContentText>

          <Container>
            <Grid container spacing={1}>
              {inputValues.map((value, index) => (
                <Grid item xs={2} key={index}>
                  <TextField
                    inputRef={(el) => setInputRef(el, index)}
                    value={value}
                    onPaste={e => handleChange(e)}
                    onChange={(e) => handleInputChange(index, e.target.value)}
                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                      handleInputKeyDown(index, e)
                    }
                    variant="outlined"
                    margin="dense"
                    size="small"
                    fullWidth
                  />
                </Grid>
              ))}
            </Grid>
          </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={validard} variant="contained" color="primary" autoFocus>
            Validar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
