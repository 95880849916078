import * as Yup from 'yup';
import {useEffect, useState, useCallback, useMemo} from 'react';
import {Divider, styled, Tabs,Radio ,RadioGroup ,Alert, AlertTitle, FormControlLabel ,Chip,Pagination, Typography,Card, FormLabel , Tab, IconButton,Button,Link,TextField,InputLabel, List, MenuItem,FormControl,OutlinedInput,InputAdornment, Paper, Box, Grid, Badge, Avatar, Select, Stack, Rating, Tooltip} from "@mui/material";
import { CustomAvatar } from "src/components/custom-avatar";
import Iconify from 'src/components/iconify';
import { LoadingButton } from '@mui/lab';
import {useSnackbar} from "notistack";
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider, {RHFCheckbox} from '../../../../components/hook-form';
import { IUserProfile, IUserProfilePost } from '../../../../@types/user';


import axios from "../../../../utils/axios";
import ChatNavItem from "../../chat/nav/ChatNavItem";
import { CustomFile } from '../../../../components/upload';

import {PATH_AUTH, PATH_DASHBOARD} from '../../../../routes/paths';

import {fData} from "../../../../utils/formatNumber";
import {useAuthContext} from "../../../../auth/useAuthContext";
import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs';
import ForumConversa from './FórumConversa';
import LateralForum from './LateralForum';

import Atendente from '../../Atendentes/ComponetesAtedentes/atendente';


// ----------------------------------------------------------------------

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));


  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

type props = {
    imgComunidade: any;
    tituloComunidade: any;
    qtdMembros: any;
    ultimaMensagem: any;
    id: any
    socket: any
};

export default function ComunidadeInterno({imgComunidade, tituloComunidade, qtdMembros, ultimaMensagem, id,socket }:props) {
    const { enqueueSnackbar } = useSnackbar();
  const [Atendentes, setAtendentes] = useState<any>({data:[]});

    const [value, setValue] = useState(0);
    const [posts, setposts] = useState<any>({last_page:1,data:[]});
    const [post, setpost] = useState<any>({personLikes:[]});
    const [page, setpage] = useState<any>('');
    const [ispage, seispage] = useState<any>(1);

    const handleChangepage = (event: React.ChangeEvent<unknown>, valuepage: any) => {
        setpage(valuepage);
    };

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };
    const [seachstate, setSeach] = useState('');
    const [participantedado, setparticipante] = useState({isparticipante:false});
    const [rankdados, setrank] = useState([]);
    const [personLikes, setpersonLikes] = useState([]);



    const atualizarmenssagens= useCallback(async () => {

        try {
            const response = await axios.post(`/areadoheroi/getcomunidade-menssagems?page=${page}`,{
                query: seachstate,
                id
            });

            const {menssagens,participante,rank,personLike} = response.data;
            setposts(menssagens);
            setparticipante(participante);
            setrank(rank);
            setpersonLikes(personLike);

            // if(ispage === 1){
            //     setpage(menssagens.last_page);
            // }
            //
            // if(posts.last_page === 1){
            //     seispage((prevCount: number) => prevCount + 1);
            // }


        } catch (error) {
            console.error(error);
        }
    },[page, seachstate, id]);



    useEffect(() => {

        atualizarmenssagens();


    }, [atualizarmenssagens]);





    //
    // useEffect(() => {
    //
    //     try {
    //         socket.on("comunidade", (dados:any) => {
    //             atualizarmenssagens();
    //             console.log(page);
    //         });
    //     } catch (error) {
    //         enqueueSnackbar(`${ error}`, { variant: 'error' });
    //     }
    // },[atualizarmenssagens, enqueueSnackbar, page, socket]);
    //
    



    const [vazio, setisvazio] = useState<any>(false);


    const getAtendentes = useCallback(async () => {
        try {
            const response = await axios.post(`/areadoheroi/atendentes?page=1}`, {
                valueAssunto:'',
                query:'',
                tipoprofissional:'',
                idade:'',
                genero:'',
                avaliacao:'',
                preco:'',
            });

            const {atendentes} = response.data;

            setAtendentes(atendentes);



        } catch (error) {
            console.error(error);
        }
    }, []);



    useEffect(() => {

        getAtendentes();

    }, [getAtendentes]);



    // @ts-ignore
    return (
            <>

            <Grid container spacing={2}>
                <Grid item md={8} xs={12}>
                    <Item>
                        <Card sx={{p: 2}}>
                            <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            >
                                <img src={imgComunidade} alt={tituloComunidade} style={{width: '70px', aspectRatio: '3/3', objectFit: 'cover', borderRadius: '100%'  }} />

                                <Grid
                                container
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                sx={{width: 'fit-content', ml: 2}}
                                >
                                    <Typography variant="h6" > {tituloComunidade} </Typography>
                                    <Typography variant="subtitle2" sx={{color: '#637381'}}> {qtdMembros} membros </Typography>
                                    <Typography variant="subtitle2"> Última mensagem: {ultimaMensagem}  </Typography>
                                </Grid>

                                <Box sx={{ width: '100%' }}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                        <Tab label="Fórum de Conversa" {...a11yProps(0)} />
                                        <Tab label="Profissionais Online" {...a11yProps(1)} />
                                        
                                        </Tabs>
                                    </Box>
                                    <CustomTabPanel value={value} index={0}>
                                        

                                      <ForumConversa  posts={posts} participantedado={participantedado} id={id}  />
                                        { posts.last_page > 1 ? ( <Pagination count={posts.last_page} sx={{mt:2}} page={page} onChange={handleChangepage} /> ) : ('')}



                                    </CustomTabPanel>
                                    <CustomTabPanel value={value} index={1}>

                                    {  Atendentes.data.length !== 0 ?
                                      (<>
                                          {
                                          vazio ?  (
                                              <Alert>
                                                  <AlertTitle>Não há anjos/profissionais para este assunto ou busca.</AlertTitle>
                                                  Atendentes <strong>online agora!</strong>
                                              </Alert>

                                              ):('')
                                          }{  /* eslint-disable-next-line react/destructuring-assignment */
                                      Atendentes.data.map((Atendentevar:any,index:any) => (
                                      <div className='ListadeAtendentesHeroi' key={index}>
                                          <Atendente atendente={Atendentevar}  />
                                      </div>
                                      )

                                      ) }</>):('')}

                                    </CustomTabPanel>
                                   
                                </Box>

                            </Grid> 
                        </Card>
                    </Item>
                </Grid>
                <Grid item md={4} xs={12}>
                    <Item>

                         <LateralForum post={post} participantedado={participantedado} id={id} personLikes={personLikes} atualizarmenssagens={atualizarmenssagens} rankdados={rankdados} />

                    </Item>
                </Grid>
            </Grid>

                

            </>
    );
}


