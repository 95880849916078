import * as React from 'react';
import {useEffect} from "react";
import useResponsive from 'src/hooks/useResponsive';
import {useNavigate} from "react-router-dom";

import {CardProps} from "@mui/material";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { CustomAvatar } from 'src/components/custom-avatar';

import avatarImg from '../img/avatar_2.jpg'
import './AtendimentosStepper.css';
import {PATH_DASHBOARD} from "../../../../routes/paths";





const steps = [
  {
    atendimento: 'Atendimento finalizado',
    status: 'Concluído',
    data: '22/02/2024',
    hora: '22:00',
    description: `For each ad campaign that you create, you can control how much
              you're willing to spend on clicks and conversions, which networks
              and geographical locations you want your ads to show on, and more.`,
  },
  {
    atendimento: 'Próximo atendimento',
    status: 'Seu próximo atendimento',
    data: '22/02/2024',
    hora: '22:00',
    description:
      'An ad group contains one or more ads which target a shared set of keywords.',
  },
  {
    atendimento: 'Atendimento futuro',
    status: 'Pendente',
    data: '22/02/2024',
    hora: '22:00',
    description: `Try out different ad text to see what brings in the most customers,
              and learn how to enhance your ads using features like ad extensions.
              If you run into any problems with your ads, find out how to tell if
              they're running and how to resolve approval issues.`,
  },
  {
    atendimento: 'Atendimento futuro',
    status: 'Pendente',
    data: '22/02/2024',
    hora: '22:00',
    description: `Try out different ad text to see what brings in the most customers,
              and learn how to enhance your ads using features like ad extensions.
              If you run into any problems with your ads, find out how to tell if
              they're running and how to resolve approval issues.`,
  },
];
interface props extends CardProps {
  atendente: any;
  pacotedados: any;

}
export default function AtendimentosStepper({atendente,pacotedados}:props) {
  const [activeStep, setActiveStep] = React.useState(0);
  const navigate = useNavigate();
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };




  useEffect(() => {

    setActiveStep( pacotedados.usados);

  }, [pacotedados, setActiveStep]);

  const agendar = () => {
    navigate(PATH_DASHBOARD.conversar(atendente.id));
  }
  const avaliar = () => {
    navigate(PATH_DASHBOARD.avaliacoes);
  };
  const chat = (token:any) => {
    navigate(PATH_DASHBOARD.chat.view(token));
  };

  const isDesktop = useResponsive('up', 'lg');

  return (
    <Box sx={isDesktop ? {p: 0} : {p: 2}}>
      <Stepper activeStep={activeStep} orientation="vertical" className='StepperAtendimentosPacotes'>
        {pacotedados.steps.map((step:any, index: number) => (
          <Step key={index}>
            
                <StepLabel className={index !== activeStep ? 'TopoStepperAtendimentosPacotes' : 'TopoStepperAtendimentosPacotes Ativo'}>
                    {step.atendimento}

                    <div className='DataHoraAtendimentosPacotes'>
                      {step.status === 'Concluído'? (
                          <>
                            <Typography variant='subtitle2' sx={{mr: 1}}> {step.data} </Typography>
                            <Typography variant='subtitle2'> {step.hora} </Typography>
                          </>
                      ):('')}

                        <div className='BotoesAcaoAtendimentosPacotes'>
                        {index !== activeStep ? (
                            <>
                                {index < activeStep ? (
                                    <>
                                        <Button
                                            variant="contained"
                                            onClick={()=>{ chat(step.chat)}}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            Chat
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            onClick={avaliar}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            Avaliar
                                        </Button>
                                        
                                    </>
                                ) : ('')}
                            </>
                        ) : null}
                        </div>
                    </div>

                </StepLabel>
                <StepContent className={index !== activeStep ? 'ConteudoStepAtendimentosPacotes' : 'ConteudoStepAtendimentosPacotesAtivo'}>
                
                

                <Box sx={{ mb: 2 }}>
                    
                    <Typography variant='subtitle1' sx={{fontWeight: 700}}>{atendente.nome}</Typography>
                    <Typography variant='subtitle2' sx={{fontWeight: 400}}>{step.description}</Typography>

                    <div style={{display: 'flex', flexDirection: 'row', marginTop: '15px'}}>

                    <CustomAvatar
                    src={atendente.avatar}
                    alt={atendente.nome}
                    name={atendente.nome}
                    sx={{mr: 1.5, width: '40px', height:'40px'}}
                    />

                        <Button
                            variant="contained"
                            onClick={agendar}
                        >
                            Agendar
                        </Button>

                    
                    </div>
                </Box>
                </StepContent>
            
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}