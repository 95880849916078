
 export const portuguesliguage = {
    about_call: 'About Call',
    screen: 'Screen',
    camera: 'Camera',
    leave: 'Leave',
    dismiss: 'Dismiss',
    logo: 'Logo',
    page: 'Page',
    more: 'More',
    'page.prev': 'Previous Page',
    'page.next': 'Next Page',
    layout: 'Layout',
    'layout.auto': 'Auto Layout',
    settings: 'Settings',
    file: 'File',
    image: 'Image',
    connection: 'Connection',
    leave_confirmation: 'Are you sure you want to leave the call?',
    cancel: 'Cancel',
    yes: 'Yes',
    you: 'You',
    to: 'To',
    mute: 'Mute',
    kick: 'Kick',
    pin: 'Pin',
    pinned: 'Pinned',
    unpin: 'Unpin',
    pip_on: 'Show PiP',
    pip_off: 'Hide PiP',
    viewers: 'Viewers',
    join: 'Join',
    joined: 'Joined',
    create: 'Create',
    close: 'Close',
    ask: 'Ask',
    type: 'Type',
    activate: 'Activate',
    mic_off: 'Mic Off',
    disable_mic: 'Disable Mic',
    mic_on: 'Mic On',
    enable_mic: 'Enable Mic',
    audio: 'Audio',
    test: 'Test',
    mute_all: 'Mute all',
    'mute_all.description': 'Everyone in the meeting will be muted.',
    'mute_all.header': 'Are you sure?',
    'mute_all.allow_unmute': 'Allow others to unmute',
    video_off: 'Video Off',
    disable_video: 'Disable Video',
    video_on: 'Video On',
    enable_video: 'Enable Video',
    video: 'Video',
    offline: "You're offline",
    'offline.description': 'Please ensure that you are connected to the internet.',
    disconnected: "You haven't joined the meeting.",
    'disconnected.description': 'Please join the meeting in order to see and interact with other participants.',
    participants: 'Participants',
    'participants.errors.empty_results': "Couldn't find a participant with the specified name or ID.",
    'participants.empty_list': 'It looks like nobody is here.',
    'participants.turn_off_video': 'Turn off video',
    polls: 'Polls',
    'polls.by': 'Poll by',
    'polls.question': 'Poll Question',
    'polls.question.placeholder': 'What is your poll for?',
    'polls.answers': 'Answers',
    'polls.option': 'Add an option.',
    'polls.option.placeholder': 'Enter an option',
    'polls.results.anon': 'Anonymous',
    'polls.results.hide': 'Hide results before voting',
    'polls.create': 'Create Poll',
    'polls.cancel': 'Cancel Poll Creation',
    'polls.errors.question_required': 'Question is required.',
    'polls.errors.empty_option': 'Empty options not allowed.',
    screenshare: 'Screen Share',
    'screenshare.min_preview': 'Minimize Preview',
    'screenshare.max_preview': 'Expand Preview',
    'screenshare.shared': 'Your screen is being shared.',
    'screenshare.start': 'Share Screen',
    'screenshare.stop': 'Stop Sharing',
    'screenshare.error.unknown': 'An error occured while starting screenshare.',
    'screenshare.error.max_count': 'Maximum screen share limit reached.',
    plugins: 'Plugins',
    perm_denied: 'Permission denied by browser.',
    'perm_denied.audio': 'Mic Permission denied by browser',
    'perm_denied.video': 'Camera Permission denied by browser',
    'perm_denied.screenshare': 'Screenshare Permission denied by browser',
    'perm_denied.audio.chrome.message': `Under Chrome settings, navigate to 'Privacy and Security > Site Settings > Permissions'. Select 'Microphone', provide permission and reload this application.`,
    'perm_denied.video.chrome.message': `Under Chrome settings, navigate to 'Privacy and Security > Site Settings > Permissions'. Select 'Camera', provide permission and reload this application.`,
    'perm_denied.screenshare.chrome.message': `Under Chrome settings, navigate to 'Privacy and Security > Site Settings > Permissions'. Select 'Screenshare', provide permission and reload this application.`,
    'perm_denied.audio.safari.message': `Under Safari settings, navigate to 'Websites > Microphone', provide permission and reload this application.`,
    'perm_denied.video.safari.message': `Under Safari settings, navigate to 'Websites > Camera', provide permission and reload this application.`,
    'perm_denied.screenshare.safari.message': `Under Safari settings, navigate to 'Websites > Screenshare', provide permission and reload this application.`,
    'perm_denied.audio.edge.message': `Under Edge settings, navigate to 'Site Permissions > Microphone', provide permission and reload this application.`,
    'perm_denied.video.edge.message': `Under Edge settings, navigate to 'Site Permissions > Camera', provide permission and reload this application.`,
    'perm_denied.screenshare.edge.message': `Under Edge settings, navigate to 'Site Permissions > Screenshare', provide permission and reload this application.`,
    'perm_denied.audio.firefox.message': `Under Firefox settings, navigate to 'Privacy and Security > Permissions > Microphone', provide permission and reload this application.`,
    'perm_denied.video.firefox.message': `Under Firefox settings, navigate to 'Privacy and Security > Permissions > Camera', provide permission and reload this application.`,
    'perm_denied.screenshare.firefox.message': `Under Firefox settings, navigate to 'Privacy and Security > Permissions > Screenshare', provide permission and reload this application.`,
    'perm_denied.audio.others.message': `From your browser settings, enable 'Microphone' permissions and reload this application.`,
    'perm_denied.video.others.message': `From your browser settings, enable 'Camera' permissions and reload this application.`,
    'perm_denied.screenshare.others.message': `From your browser settings, enable 'Screenshare' permissions and reload this application.`,
    perm_sys_denied: 'Permission denied by system',
    'perm_sys_denied.audio': 'Mic permission denied by system',
    'perm_sys_denied.video': 'Camera permission denied by system',
    'perm_sys_denied.screenshare': 'Screenshare permission denied by system',
    'perm_sys_denied.audio.macos.message': `Open Apple Menu, Navigate to 'System Settings > Privacy & Security > Microphone'. Allow access to your browser and reload this application.`,
    'perm_sys_denied.video.macos.message': `Open Apple Menu, Navigate to 'System Settings > Privacy & Security > Camera'. Allow access to your browser and reload this application.`,
    'perm_sys_denied.screenshare.macos.message': `Open Apple Menu, Navigate to 'System Settings > Privacy & Security > Screenshare'. Allow access to your browser and reload this application.`,
    'perm_sys_denied.audio.ios.message': `On your iPhone, navigate to 'Settings > Privacy > Microphone', allow access to your browser and reload this application.`,
    'perm_sys_denied.video.ios.message': `On your iPhone, navigate to 'Settings > Privacy > Camera', allow access to your browser and reload this application.`,
    'perm_sys_denied.screenshare.ios.message': `On your iPhone, navigate to 'Settings > Privacy > Screenshare', allow access to your browser and reload this application.`,
    'perm_sys_denied.audio.windows.message': `Go to windows settings, select 'Settings > Privacy > Microphone'. Allow permissions to your browser and reload this application.`,
    'perm_sys_denied.video.windows.message': `Go to windows settings, select 'Settings > Privacy > Camera'. Allow permissions to your browser and reload this application.`,
    'perm_sys_denied.screenshare.windows.message': `Go to windows settings, select 'Settings > Privacy > Screenshare'. Allow permissions to your browser and reload this application.`,
    'perm_sys_denied.audio.android.message': `On you device, navigate to 'Settings > Apps'. Select your browser, allow Microphone permissions and reload this application.`,
    'perm_sys_denied.video.android.message': `On you device, navigate to 'Settings > Apps'. Select your browser, allow Camera permissions and reload this application.`,
    'perm_sys_denied.screenshare.android.message': `On you device, navigate to 'Settings > Apps'. Select your browser, allow Screenshare permissions and reload this application.`,
    'perm_sys_denied.audio.others.message': `Navigate to your system settings. Allow 'Microphone' permissions for your browser and reload this application.`,
    'perm_sys_denied.video.others.message': `Navigate to your system settings. Allow 'Camera' permissions for your browser and reload this application.`,
    'perm_sys_denied.screenshare.others.message': `Navigate to your system settings. Allow 'Screenshare' permissions for your browser and reload this application.`,
    perm_could_not_start: `Could not capture device.`,
    'perm_could_not_start.audio': `Unable to start your Microphone`,
    'perm_could_not_start.video': `Unable to start your Camera`,
    'perm_could_not_start.screenshare': `Unable to start your Screenshare`,
    'perm_could_not_start.audio.message': `Looks like the system could not capture your microphone. Please restart your device or upgrade your browser to fix this.`,
    'perm_could_not_start.video.message': `Looks like the system could not capture your camera. Please restart your device or upgrade your browser to fix this.`,
    'perm_could_not_start.screenshare.message': `Looks like the system could not capture your screenshare. Please restart your device or upgrade your browser to fix this.`,
    full_screen: 'Full Screen',
    'full_screen.exit': 'Exit Full Screen',
    'waitlist.header_title': 'Waiting',
    'waitlist.body_text': 'You are in the waiting room, the host will let you in soon.',
    'waitlist.deny_request': 'Deny request',
    'waitlist.accept_request': 'Accept request',
    'waitlist.accept_all': 'Accept all',
    'stage_request.header_title': 'Join Stage Requests',
    'stage_request.deny_request': 'Deny request',
    'stage_request.accept_request': 'Accept request',
    'stage_request.accept_all': 'Accept all',
    'stage_request.deny_all': 'Deny all',
    'stage_request.approval_pending': 'Pending',
    'stage_request.denied': 'Denied',
    'stage_request.request': 'Join stage',
    'stage_request.requested': 'Requested',
    'stage_request.cancel_request': 'Cancel request',
    'stage_request.leave_stage': 'Leave stage',
    'stage_request.request_tip': 'Request to join the discussion',
    'stage_request.leave_tip': 'Leave the stage',
    'stage_request.pending_tip': 'Request pending',
    'stage_request.denied_tip': 'Rejected request',
    'stage.empty_host': 'The stage is empty',
    'stage.empty_host_summary': 'You are off stage. You can manage stage request from the participants tab.',
    'stage.empty_viewer': 'There is no one on stage',
    'stage.remove_from_stage': 'Remove from stage',
    'stage.invited_notification': 'has been invited to join stage.',
    'stage.add_to_stage': 'Invite to stage',
    'stage.join_title': 'Join Stage',
    'stage.join_summary': 'You are about to join the stage, your video & audio as shown above will be visible to all participants.',
    'stage.join_cancel': 'Cancel',
    'stage.join_confirm': 'Join',
    'setup_screen.join_in_as': 'Join in as',
    'setup_screen.joining_as': 'Joining as',
    'setup_screen.your_name': 'Your name',
    'stage.reconnecting': 'Reconnecting...',
    'recording.label': 'REC',
    'recording.indicator': 'This meeting is being recorded.',
    'recording.started': 'This meeting is being recorded.',
    'recording.stopped': 'Recording for this meeting has been stopped.',
    'recording.paused': 'Recording for this meeting has been paused.',
    'recording.error.start': 'Error while starting recording.',
    'recording.error.stop': 'Error while stopping recording',
    'recording.error.resume': 'Error while resuming recording',
    'recording.start': 'Start Recording',
    'recording.stop': 'Stop Recording',
    'recording.resume': 'Resume Recording',
    'recording.starting': 'Starting',
    'recording.stopping': 'Stopping',
    'recording.loading': 'Loading',
    'recording.idle': 'Record',
    audio_playback: 'Ativar audio',
    'audio_playback.title': 'Ativar audio',
    'audio_playback.description': 'Para reproduzir o áudio corretamente no seu dispositivo, clique no botão abaixo.',
    breakout_rooms: 'Breakout Rooms',
    'breakout_rooms.room_config_header': 'Create Breakout',
    'breakout_rooms.join_breakout_header': 'Join Breakout',
    'breakout_rooms.empty': 'Nobody here yet.',
    'breakout_rooms.delete': 'Delete Room',
    'breakout_rooms.switch': 'Switch',
    'breakout_rooms.main_room': 'Main Room',
    'breakout_rooms.shuffle_participants': 'Shuffle Participants',
    'breakout_rooms.deselect': 'Deselect',
    'breakout_rooms.selected': 'selected',
    'breakout_rooms.num_of_rooms': 'No. of Rooms',
    'breakout_rooms.approx': 'Approx.',
    'breakout_rooms.participants_per_room': 'participants/room',
    'breakout_rooms.division_text': 'when equally divided.',
    'breakout_rooms.start_breakout': 'Start Breakout',
    'breakout_rooms.close_breakout': 'Close Breakout',
    'breakout_rooms.update_breakout': 'Update Breakout',
    'breakout_rooms.discard_changes': 'Discard Changes',
    'breakout_rooms.room': 'Room',
    'breakout_rooms.rooms': 'Rooms',
    'breakout_rooms.room_name': 'Room Name',
    'breakout_rooms.edit_room_name': 'Edit Room Name',
    'breakout_rooms.save_room_name': 'Save Room Name',
    'breakout_rooms.add_room': 'Add',
    'breakout_rooms.add_room_brief': 'Add Room',
    'breakout_rooms.select_all': 'Select all',
    'breakout_rooms.unassign_all': 'Unassign all',
    'breakout_rooms.assign': 'Assign',
    'breakout_rooms.assign_participants': 'Assign Participants',
    'breakout_rooms.none_assigned': 'No participants assigned yet',
    'breakout_rooms.drag_drop_participants': 'Drag and drop participants',
    'breakout_rooms.click_drop_participants': 'Click here to assign',
    'breakout_rooms.status.assign_multiple': 'Assign multiple participants at once by clicking and selecting them',
    'breakout_rooms.status.select_room': 'Select a room to assign',
    'breakout_rooms.ephemeral_status.participants_assigned': 'Participants assigned',
    'breakout_rooms.ephemeral_status.participants_assigned_randomly': 'Participants assigned randomly',
    'breakout_rooms.ephemeral_status.changes_discarded': 'Changes discarded',
    'breakout_rooms.confirm_modal.start_breakout.header': 'Start breakout rooms?',
    'breakout_rooms.confirm_modal.start_breakout.content': 'Once started, all participants will be moved to their assigned rooms.',
    'breakout_rooms.confirm_modal.start_breakout.cancelText': 'No, go back',
    'breakout_rooms.confirm_modal.start_breakout.ctaText': 'Yes, start',
    'breakout_rooms.confirm_modal.close_breakout.header': 'Close breakout rooms?',
    'breakout_rooms.confirm_modal.close_breakout.content': 'All breakout rooms will be closed & participants will be moved back to the main room.',
    'breakout_rooms.confirm_modal.close_breakout.ctaText': 'Yes, close breakout',
    'breakout_rooms.move_reason.started_msg': 'Starting breakout rooms...',
    'breakout_rooms.move_reason.started_desc': 'You are being moved to your assigned room',
    'breakout_rooms.move_reason.closed_msg': 'Closing Breakout rooms...',
    'breakout_rooms.move_reason.closed_desc': 'You are being moved back to the main room',
    'breakout_rooms.move_reason.switch_room': 'Joining Breakout Room...',
    'breakout_rooms.move_reason.switch_main_room': 'Joining Main Room...',
    'breakout_rooms.all_assigned': 'All participants have been assigned',
    'breakout_rooms.empty_main_room': 'No more participants in the main room.',
    'breakout_rooms.leave_confirmation': 'Are you sure you want to leave the call? You are in a breakout room, you can join the main room too.',
    'breakout_rooms.leave_confirmation.main_room_btn': 'Go back to main room',
    ai: 'AI',
    'ai.meeting_ai': 'MeetingAI',
    'ai.home': 'Home',
    'ai.transcriptions': 'Transcriptions',
    'ai.personal': 'Personal',
    'ai.caption_view': 'Caption View',
    'ai.chat.tooltip': 'This conversation will just be visible to you and not to others in the call.',
    'ai.chat.summerise': 'Hey AI, summarise this call',
    'ai.chat.agenda': "Hey AI, what is today's agenda?",
    search: 'Search',
    'search.could_not_find': "Couldn't find a participant with the specified name or ID.",
    'search.empty': 'It looks like nobody is here.',
    end: 'End Meeting',
    'end.all': 'End meeting for all',
    ended: 'The meeting ended.',
    'ended.rejected': 'Your request to join the meeting was denied.',
    'ended.left': 'You left the meeting.',
    'ended.kicked': 'You were removed from the meeting.',
    'ended.disconnected': 'The call ended because the connection was lost.',
    'ended.network': 'Please check your internet connection and try again.',
    network: 'Network',
    'network.reconnecting': 'Connection lost. Trying to reconnect...',
    'network.delay': 'Taking too long to reconnect...',
    'network.delay_extended': 'Taking too long to reconnect. Please check your network connection.',
    'network.restored': 'Connection restored',
    'network.lost': 'Connection lost',
    'network.lost_extended': 'Connection lost. Please check your network connection.',
    'network.disconnected': 'Could not reconnect. Please leave the meeting and try refreshing the window.',
    'network.leaving': 'Automatically leaving the meeting in 10 seconds.',
    livestream: 'Livestream',
    'livestream.indicator': 'This meeting is being livestreamed.',
    'livestream.skip': 'Skip to Live',
    'livestream.idle': 'Waiting to go live.',
    'livestream.starting': 'Livestream is starting...',
    'livestream.stopping': 'Livestream is stopping...',
    'livestream.error.not_supported': 'Player not supported.',
    'livestream.error.not_found': 'Playback URL not found.',
    'livestream.error.unknown': 'An unknown error occured.',
    'livestream.error.sync': 'Could not sync livestream please try again later.',
    'livestream.error.start': 'Error while starting livestream.',
    'livestream.error.stop': 'Error while stopping livestream.',
    'livestream.go_live': 'Go Live',
    'livestream.end_live': 'End Live',
    'livestream.error': 'Error',
    'cta.help': 'Need help on how to do this?',
    'cta.continue': 'Continue as it is',
    'cta.reload': 'Reload',
    'cta.confirmation': 'Are you sure?',
    'remote_access.empty': 'There are no remote requests, yet.',
    'remote_access.requests': 'The following people have requested remote control to your screen share.',
    'remote_access.allow': 'Please select whom you want to give access to.',
    'remote_access.grant': 'Grant acess',
    'remote_access.indicator': 'Any plugin or screenshare you switch to will sync the change across the meeting',
    chat: 'Chat',
    'chat.new': 'New',
    'chat.new_channel': 'Create new channel',
    'chat.channel_name': 'Enter channel name',
    'chat.member_name': 'Enter member name',
    'chat.add_members': 'Add members',
    'chat.delete_msg': 'Delete',
    'chat.edit_msg': 'Edit',
    'chat.send_msg': 'Send message',
    'chat.send_attachment': 'Drop files/images to send',
    'chat.send_img': 'Send an image',
    'chat.send_file': 'Send a file',
    'chat.send_emoji': 'Send an emoji',
    'chat.update_msg': 'Update message',
    'chat.channel_members': 'Channel Members',
    'chat.img.loading': 'Loading image',
    'chat.error.img_not_found': 'Image not found',
    'chat.error.empty_results': "Couldn't find a member with the specified name.",
    'chat.img.shared_by': 'Shared by',
    'chat.reply': 'Reply',
    'chat.message_placeholder': 'Message..',
    'chat.click_to_send': 'Click to send as message',
    'chat.search_msgs': 'Search messages',
    'chat.search_conversations': 'Search conversations',
    'chat.start_conversation': 'Start a conversation..',
    'chat.empty_search': 'No messages found',
    'chat.empty_channel': 'Send a message to get started',
    'chat.cancel_upload': 'Cancel upload',
    'chat.view_chats': 'View chats',
    'chat.everyone': 'everyone',
    'chat.pinned_msgs': 'Pinned messages',
    'chat.toggle_pinned_msgs': 'Toggle pinned messages',
    'date.today': 'Today',
    'date.yesteday': 'Yesterday',
    'date.sunday': 'Sunday',
    'date.monday': 'Monday',
    'date.tuesday': 'Tuesday',
    'date.wednesday': 'Wednesday',
    'date.thursday': 'Thursday',
    'date.friday': 'Friday',
    'date.saturday': 'Saturday',
    'list.empty': 'No items found',
    'grid.listening': 'Listening',
    'transcript.off': 'Turn off Transcripts',
    'transcript.on': 'Turn on Transcripts',
    'settings.notification_sound': 'Notification sound',
    'settings.microphone_input': 'Microphone (input)',
    'settings.speaker_output': 'Speaker (output)',
    'settings.mirror_video': 'Mirror my Video',
    'settings.camera_off': 'Camera is off',
};


