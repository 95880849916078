import * as React from 'react';
import { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
// @mui
import {
  CssBaseline,
  Box,
  ToggleButton,
  useMediaQuery,
  useTheme,
  ToggleButtonGroup,
  TextareaAutosize,
  IconButton,
  Stepper,
  Step,
  LinearProgress,
  StepLabel,
  Container,
  Paper,
  Grid,
  Dialog,
  Slide,
  Typography,
  Tooltip,
  Button,
  Link,
  TextField,
} from '@mui/material';
import { CustomAvatar } from 'src/components/custom-avatar';
import { TransitionProps } from '@mui/material/transitions';
import Iconify from 'src/components/iconify/Iconify';
import { styled } from '@mui/material/styles';
import { Helmet } from 'react-helmet-async';
import { useSettingsContext } from '../../../components/settings';

import salaespera1 from './img/salaespera1.png';

import FronterLogo from './img/fronterLogo.webp';
import imageProfile from './img/imageProfile.webp';
import anjoChegou from './img/anjoChegou.webp';
import anjoNaoChegou from './img/anjoNaoChegou.webp';

import './SalaEspera.css';
import CarouselAnimation from '../Dashfirst/componentesDashboardFirst/CarouselDashboardFirst';
import CarouselAnimationSalaEsperaPsicologo from './componenteSalaEspera/carouselSalaEsperaPsicologo';

import axios from '../../../utils/axios';
import { useAuthContext } from '../../../auth/useAuthContext';
import { PATH_DASHBOARD } from '../../../routes/paths';
import CarouselAnimationSalaEspera from './componenteSalaEspera/carouselSalaEspera';
import { useSnackbar } from '../../../components/snackbar';
import { criarevento } from '../../../utils/analytics ';

// ----------------------------------------------------------------------

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const steps = [' ', ' ', ' '];

type Props = {
  socket: any;
};
export default function SalaEspera({ socket }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { user } = useAuthContext();
  const { tokenConversa = '' } = useParams();
  const [usera, setuser] = useState<any>({});
  const [conversa, setconversa] = useState<any>({});
  const [conversion, setconversion] = useState(false);

  const getusers = useCallback(async () => {
    try {
      const response = await axios.get(`/areadoheroi/atendente/${tokenConversa}`);

      const { atendente, historico } = response.data;

      setuser(atendente);
      setconversa(historico);

      if(historico.IDTipoConversa !== 1){
        setconversion(true);
      }



    } catch (error) {
      console.error(error);
    }
  }, [tokenConversa]);

  useEffect(() => {
    getusers();
  }, [getusers]);



  const blue = {
    100: '#DAECFF',
    200: '#b6daff',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    900: '#003A75',
  };

  const grey = {
    50: '#f6f8fa',
    100: '#eaeef2',
    200: '#d0d7de',
    300: '#afb8c1',
    400: '#8c959f',
    500: '#6e7781',
    600: '#57606a',
    700: '#424a53',
    800: '#32383f',
    900: '#24292f',
  };

  const StyledTextarea = styled(TextareaAutosize)(
    ({ theme }) => `
        width: 320px;
        font-family: IBM Plex Sans, sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        padding: 12px;
        border-radius: 12px 12px 0 12px;
        color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
        background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
        border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
        box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
      
        &:hover {
          border-color: ${blue[400]};
        }
      
        &:focus {
          border-color: ${blue[400]};
          box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[500] : blue[200]};
        }
      
        // firefox
        &:focus-visible {
          outline: 0;
        }
      `
  );

  const enviarnotifica = useCallback(async () => {
    socket.emit(
      'enviarnotifica',

      {
        iduser: `atendido${user?.id}`,
        tipo: 'AnjoNaoChegou',
        usera,
        tokenConversa,
      }
    );
  }, [socket, user?.id, usera, tokenConversa]);

  const enviarnotificaok = useCallback(async () => {
    socket.emit(
      'enviarnotifica',

      {
        iduser: `atendido${user?.id}`,
        tipo: 'AnjoChegou',
        usera,
        tokenConversa,
      }
    );
  }, [socket, user?.id, usera, tokenConversa]);

  const [progress, setProgress] = React.useState(0);
  const [time, setTime] = useState(300000);
  const [statussala, setstatus] = useState('Confimado');
  const [valorespera, setvalorespera] = useState(0);

  useEffect(() => {
    (async function () {
      try {
        const response = await axios.post(`/areadoheroi/salaespera/gettime/`, { tokenConversa });

        const { timeconversa, status } = response.data;

        setTime(timeconversa);
        setstatus(status);

        if (timeconversa === 0) {
          setvalorespera(0);
          if (status === 'EmAtendimento') {
            enviarnotificaok();
          } else if (status === 'Finalizado') {
            navigate(PATH_DASHBOARD.dashfirst, { replace: true });
          } else {
            enviarnotifica();
          }
        } else {
          setvalorespera(1);
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [enviarnotifica, enviarnotificaok, getusers, navigate, tokenConversa]);

  React.useEffect(() => {
    const timer = setInterval(() => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      //    setTime((time) => Math.max( time - 1000, 0));
      (async function () {
        try {
          const response = await axios.post(`/areadoheroi/salaespera/gettime/`, { tokenConversa });

          const { timeconversa, status } = response.data;

          setTime(timeconversa);
          setstatus(status);

          if (timeconversa === 0) {
            setvalorespera(0);
            if (status === 'EmAtendimento') {
              enviarnotificaok();
            } else if (status === 'Finalizado') {
              navigate(PATH_DASHBOARD.dashfirst, { replace: true });
            } else {
              enviarnotifica();
            }

            // clearInterval(timer);
          } else {
            setvalorespera(1);
          }
        } catch (error) {
          console.error(error);
        }
      })();

      setProgress((oldProgress) => {
        const diff = 20;
        return Math.min(oldProgress + diff, 100);
      });
    }, 60000);

    return () => {
      clearInterval(timer);
    };
  }, [enviarnotifica, enviarnotificaok, navigate, time, tokenConversa]);

  React.useEffect(() => {
    socket.emit('salaespera', {
      iduser: `atendente${usera?.id}`,
      tipo: 'NotificaAtendente',
      tokenConversa,
    });
    console.log('888888');
  }, [socket, tokenConversa, usera]);

  React.useEffect(() => {
    const myInterval = setInterval(() => {
      socket.emit('salaespera', {
        iduser: `atendente${usera?.id}`,
        tipo: 'NotificaAtendente',
        tokenConversa,
      });
      console.log('dsdsdsd');
    }, 10000);

    return () => clearInterval(myInterval);
  }, [socket, tokenConversa, usera]);

  const [open, setOpen] = React.useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const [questao1, setquestao1] = React.useState<any>('');

  const handleChange1 = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    setquestao1(newAlignment);
  };

  const [questao2, setquestao2] = React.useState<any>('');

  const handleChange2 = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    setquestao2(newAlignment);
  };

  const [questao3, setquestao3] = React.useState<any>('');

  const handleChange3 = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    setquestao3(newAlignment);
  };

  const [Assunto, setAssunto] = React.useState('');

  // Aguardando // Chegou
  const [Aguarde, setAguarde] = React.useState('Aguardando');

  const AnjoChegou = () => {
    setAguarde('Chegou');
  };

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());

  const isStepOptional = (step: number) => {
    return step === 1;
    console.log('pass');
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
    console.log('pass2');
  };

  const handleNext = async () => {
    if (questao1 === '') {
      enqueueSnackbar(`Você precisa responder todas as questões`, { variant: 'error' });
      return false;
    }

    if (questao2 === '') {
      enqueueSnackbar(`Você precisa responder todas as questões`, { variant: 'error' });
      return false;
    }

    if (questao3 === '') {
      enqueueSnackbar(`Você precisa responder todas as questões`, { variant: 'error' });
      return false;
    }

    if (activeStep === 1 && Assunto === '') {
      enqueueSnackbar(`Escreva sobre o assunto que deseja conversar`, { variant: 'error' });
      return false;
    }

    if (activeStep === 0 || activeStep === 1) {
      Postrespostas();
    }

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
    return true;
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleChangetext = async (event: any) => {
    try {
      setAssunto(event.target.value);
    } catch (error) {
      console.error(error);
    }
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const [Status, setStatus] = React.useState('Step0');

  const Postrespostas = useCallback(async () => {
    try {
      const response = await axios.post('/areadoheroi/salaespera/respostas/post', {
        questao1,
        questao2,
        questao3,
        Assunto,
        tokenConversa,
      });
    } catch (error) {
      console.error(error);
    }
  }, [questao1, questao2, questao3, Assunto, tokenConversa]);

  const irDash = async () => {
    navigate(PATH_DASHBOARD.dashfirst, { replace: true });
  };

  return (
    <>
      <Dialog fullScreen open={open} onClose={handleClose} className="DialogStep0SaladeEspera">
        {isDesktop ? (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ height: '100vh' }}
          >
            <Grid container direction="column" justifyContent="space-between" alignItems="center">
              <div className="CardPixDesktop">
                <Grid container justifyContent="space-between">
                  <IconButton onClick={handleClose}>
                    <Iconify icon="eva:arrow-ios-back-outline" />
                    Voltar
                  </IconButton>
                  <IconButton onClick={handleClose}>
                    <Iconify icon="eva:close-fill" />
                  </IconButton>
                </Grid>

                <Grid item container justifyContent="center">
                  <Item
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img alt="Sala de espera" width="300px" src={salaespera1} />

                    <Typography className="TextoTituloDialogNotificacaoPagamento">
                      Olá, {user?.nome}
                    </Typography>
                    <Typography className="TextoSubTituloDialogNotificacaoPagamento">
                      Esta é a sala de encontro do Eyhe. O Anjo que você escolheu já está chegando.
                      <br />
                      Vamos iniciar o acolhimento para nos conhecermos melhor?
                    </Typography>

                    <Button
                      variant="contained"
                      sx={{ py: 1.5, mt: 1.5 }}
                      onClick={handleClose}
                      fullWidth
                    >
                      Iniciar Acolhimento
                    </Button>
                  </Item>
                </Grid>
              </div>
            </Grid>
          </Grid>
        ) : (
          <div className="DialogPaddingStep0SaladeEspera">
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
              <Item>
                <IconButton edge="start" color="inherit" aria-label="close">
                  <Iconify icon="eva:chevron-left-fill" />
                  Voltar
                </IconButton>
              </Item>

              <Item>
                <IconButton edge="start" color="inherit" aria-label="close" onClick={handleClose}>
                  <Iconify icon="eva:close-fill" />
                </IconButton>
              </Item>
            </Grid>

            <Grid container direction="column" justifyContent="center" alignItems="center">
              <img src={salaespera1} className="ImagemSalaDeEspera1" alt="Aguarde" />

              <Typography className="TituloSalaDeEsperaSlide01"> Olá, {user?.nome} </Typography>
              <Typography className="SubTituloSalaDeEsperaSlide01">
                {' '}
                Esta é a sala de encontro do Eyhe. O Anjo que você escolheu já está chegando.{' '}
              </Typography>
              <Typography className="TextoApoioSalaDeEsperaSlide01">
                {' '}
                Vamos iniciar o acolhimento para nos conhecermos melhor?{' '}
              </Typography>
            </Grid>

            <div className="FooterButtonSalaDeEsperaSlider01">
              <Button className="ButtonSalaDeEsperaSlider01" onClick={handleClose}>
                Iniciar Acolhimento
              </Button>
            </div>
          </div>
        )}
      </Dialog>

      <div className="TopoSaladeEspera">
        {activeStep === 0 ? (
          <div>
            <IconButton size="medium" onClick={irDash}>
              <Iconify icon="eva:close-fill" className="IconBackTopoSaladeEspera" />
            </IconButton>
          </div>
        ) : (
          <div />
        )}

        {activeStep !== 0 ? (
          <div>
            <IconButton size="medium" onClick={handleBack}>
              <Iconify icon="eva:chevron-left-fill" className="IconBackTopoSaladeEspera" />
              Voltar
            </IconButton>
          </div>
        ) : (
          <div />
        )}
      </div>

      <div className="GlobalSaladeEsperaSteppereSteps">
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};

            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={index} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>

        <div className="StepseBotoesSaladeEspera">
          <div className="StepsSaladeEsperaGlobal">
            {activeStep === 0 ? (
              <div className="Step1SaladeEspera">
                <Typography className="TituloStep1SaladeEspera">Seu Anjo está chegando</Typography>

                <div className="Pergunta1">
                  <Typography> 1. Você ja fez terapia? </Typography>
                  <ToggleButtonGroup
                    color="primary"
                    value={questao1}
                    exclusive
                    onChange={handleChange1}
                    aria-label="Platform"
                  >
                    <ToggleButton value="Sim" className="AlternativasPerguntasStep1SaladeEspera">
                      Sim
                    </ToggleButton>
                    <ToggleButton value="Não" className="AlternativasPerguntasStep1SaladeEspera">
                      Não
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>

                <div className="Pergunta2">
                  <Typography>
                    {' '}
                    2. Você já usou outra plataforma online de suporte emocional?
                  </Typography>
                  <ToggleButtonGroup
                    color="primary"
                    value={questao2}
                    exclusive
                    onChange={handleChange2}
                    aria-label="Platform"
                  >
                    <ToggleButton value="Sim" className="AlternativasPerguntasStep1SaladeEspera">
                      Sim
                    </ToggleButton>
                    <ToggleButton value="Não" className="AlternativasPerguntasStep1SaladeEspera">
                      Não
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>

                <div className="Pergunta3">
                  <Typography> 3. Você está em crise agora? </Typography>
                  <ToggleButtonGroup
                    color="primary"
                    value={questao3}
                    exclusive
                    onChange={handleChange3}
                    aria-label="Platform"
                  >
                    <ToggleButton value="Sim" className="AlternativasPerguntasStep1SaladeEspera">
                      Sim
                    </ToggleButton>
                    <ToggleButton value="Não" className="AlternativasPerguntasStep1SaladeEspera">
                      Não
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
              </div>
            ) : (
              ''
            )}

            {activeStep === 1 ? (
              <div className="Step2SaladeEspera">
                <Typography>
                  Fale um pouco sobre o assunto que deseja conversar para que o Anjo entenda a sua
                  necessidade
                </Typography>

                <TextField
                  id="outlined-multiline-static"
                  multiline
                  rows={4}
                  className="textoarea"
                  onChange={handleChangetext}
                  placeholder="Digite aqui"
                  sx={{ mb: 2 }}
                />
              </div>
            ) : (
              ''
            )}

            {activeStep === 2 ? (
              <div className="Step3SaladeEspera">
                <Typography>
                  Este acolhimento gratuito é possível porque empresas responsáveis apoiam o
                  propósito do Eyhe
                </Typography>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                  <Grid item md={4} xs={12}>
                    <Item>
                      <a
                        href="https://www.fronter.eng.br/"
                        className="empresasParceiras"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={FronterLogo} alt="Fronter Logo" />
                      </a>
                    </Item>
                  </Grid>
                </Grid>
              </div>
            ) : (
              ''
            )}

            {activeStep === 3 ? (
              <div className="Step4SaladeEspera">
                <Typography className="TituloStep4SaladeEspera">Seu Anjo está chegando</Typography>
                <Typography className="SubTituloStep4SaladeEspera">
                  Aproveite este tempo para conhecer mais sobre o Eyhe.
                </Typography>

                <Box
                  maxWidth={isDesktop ? '1280px' : '100%'} // Define a largura máxima ou 100% dependendo do tamanho da tela
                  margin="0 auto" // Centraliza horizontalmente
                >
                  {usera?.TipoAtendente === 2 ? (
                    <CarouselAnimationSalaEsperaPsicologo />
                  ) : (
                    <CarouselAnimationSalaEspera />
                  )}
                </Box>
              </div>
            ) : (
              ''
            )}
          </div>

          {activeStep !== steps.length ? (
            <div className="FooterButtonNextFinishSaladeEspera">
              <Button onClick={handleNext} className="ButtonNextFinishSaladeEspera">
                {activeStep === steps.length - 1 ? 'Finalizar' : 'Próximo'}
              </Button>
            </div>
          ) : (
            ' '
          )}
        </div>
      </div>

      {Aguarde === 'Aguardando' ? (
        <div className="CardTimerSaladeEspera">
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="space-between"
            sx={{ p: 1.5 }}
          >
            <Item className="IconRelogioeTituloSaladeEspera">
              <Iconify icon="eva:clock-outline" sx={{ mr: 1.5 }} />
              <Typography className="TituloTimerSaladeEspera">Seu anjo está chegando</Typography>
            </Item>
          </Grid>
          <Grid container direction="row">
            <Grid item md={4} xs={6}>
              <Item>
                <Typography className="TimerSaladeEspera">
                  {' '}
                  {`Espere ${`0${Math.floor((time / 60000) % 60) + valorespera}`.slice(-2)}m`}
                </Typography>
              </Item>
            </Grid>
            <Grid item md={8} xs={6}>
              <Item className="ProgressoCard">
                <LinearProgress variant="determinate" value={progress} />
              </Item>
            </Grid>
          </Grid>
        </div>
      ) : (
        ' '
      )}

      {Aguarde === 'Chegou' ? (
        <div className="CardAtendenteChegouSaladeEspera">
          <div className="FotoeTituloAnjoChegouSaladeEspera">
            <CustomAvatar
              className="FotoPerfilAtendente"
              src={imageProfile}
              alt="Fulano"
              name="Fulano"
              sx={{ cursor: 'pointer', width: 40, height: 40 }}
            />

            <div className="TituloeTextoAtendenteChegouSaladeEspera">
              <Typography className="TituloAtendenteChegouSaladeEspera">Seu anjo Chegou</Typography>
              <Typography className="SubTituloAtendenteChegouSaladeEspera">
                Clique para encontrá-lo
              </Typography>
            </div>
          </div>

          <Button className="BotaoIrParaoChatSaladeEspera">Ir para o Chat</Button>
        </div>
      ) : (
        ' '
      )}

      {conversion ? (
        <Helmet>
          <script>{`gtag('event', 'conversion', {'send_to': 'AW-696299330/arwKCN386pIZEMLegswC','value': ${conversa.ValorComDesconto}});`}</script>
        </Helmet>
      ) : (
        ''
      )}
    </>
  );
}
