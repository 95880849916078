import { useState, useEffect, useCallback } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { useTheme, styled, Theme } from '@mui/material/styles';
import { CustomAvatar } from 'src/components/custom-avatar';
import { IconButton, Typography, Button, Grid, Rating, Chip, Box, Paper } from '@mui/material';
import { getCoresTemplate } from 'src/utils/coresTemplate';
import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs';
// hooks
import useResponsive from '../../../../hooks/useResponsive';

import BadgeStatus, { BadgeStatusValue } from '../../../../components/badge-status';

// utils
import axios from '../../../../utils/axios';
import '../Agendamento.css';
import Horarios from './horarios';
// routes
import { PATH_AUTH, PATH_DASHBOARD } from '../../../../routes/paths';
// @types
import { IChatContact, IChatConversationsState } from '../../../../@types/chat';
// components
import Iconify from '../../../../components/iconify';
import { useAuthContext } from '../../../../auth/useAuthContext';
import Scrollbar from '../../../../components/scrollbar';
import { useSnackbar } from '../../../../components/snackbar';

//

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

type Props = {
  MaisOpcoes: any;
  ConfirmarAgendamento: any;
  Voltar: any;
  FecharAgendamento: any;
  status: any;
  usera: any;
  setSelectedHorario: any;
  selectedHorario: any;
};

export default function HorariosAgendamento({
  MaisOpcoes,
  ConfirmarAgendamento,
  Voltar,
  FecharAgendamento,
  status,
  usera,
  selectedHorario,
  setSelectedHorario,
}: Props) {
  const semana = [
    'Domingo',
    'Segunda-Feira',
    'Terça-Feira',
    'Quarta-Feira',
    'Quinta-Feira',
    'Sexta-Feira',
    'Sábado',
  ];
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();

  const navigate = useNavigate();

  const isDesktop = useResponsive('up', 'md');

  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  const [quantidadeitens, setquantidadeitens] = useState<any>(1);
  const [arrayvalores, setarrayvalores] = useState<any>([]);
  const [horariosdados, sethorariosdados] = useState<any[]>([]);
  const [horariosdefinidos, sethorariosdefinido] = useState<any[]>([]);
  const [tipohorario, settipo] = useState<any>('');

  const { user } = useAuthContext();

  const [value, setValue] = useState<number>(1);

  useEffect(() => {
    setValue(usera?.estrelas);
  }, [usera]);

  const getmessage = useCallback(async () => {
    try {
      const response = await axios.post(`/areadoheroi/horarioscasoforzero`, {
        idAtendente: usera?.id,
      });

      const { horarios, horariosdefinido, tipo } = response.data;

      sethorariosdados(horarios);
      sethorariosdefinido(horariosdefinido);
      settipo(tipo);
    } catch (error) {
      console.error(error);
    }
  }, [usera]);

  useEffect(() => {
    getmessage();
  }, [getmessage]);

  const handleClickOpen = () => {
    if (selectedHorario === '') {
      enqueueSnackbar(`Por favor selecione um Horário`, { variant: 'error' });
    } else {
      localStorage.setItem('agendamentoselectHorario', JSON.stringify(selectedHorario));
      ConfirmarAgendamento();
    }
  };

  return (
    <>
      

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className="geralAgendamentoHorarios"
        sx={{ px: 0, py: 0 }}
        maxWidth="md"
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className="TopoAgendamentoProfile"
          maxWidth="lg"
        >
          <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ pt: 1.5 }}
        >
          <IconButton onClick={Voltar}>
            <Iconify icon="eva:arrow-ios-back-outline" />
            <Typography variant="subtitle2">Voltar</Typography>
          </IconButton>

          <IconButton onClick={FecharAgendamento}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Grid>

          <div>
            <div className="FotoeNomeAtendenteAgendamento">
              <CustomAvatar
                className="FotoPerfilAtendente"
                src={usera?.avatar}
                alt={usera?.nome}
                name={usera?.nome}
                sx={{
                  cursor: 'pointer',
                  width: 60,
                  height: 60,
                  borderRadius: '50%',
                  border:
                    usera?.StatusAtendimento === 'Disponivel'
                      ? '3px solid #36B37E'
                      : '3px solid #FF5630',
                }}
              />
              <div>
                <Chip
                  sx={{ ml: 2 }}
                  label={usera?.tipoatendente}
                  className={getCoresTemplate(usera?.TipoAtendente).chipListaAtendentes}
                  size="small"
                />

                <Typography sx={{ ml: 2, fontWeight: 'bold' }}>{usera?.nome}</Typography>
                <Rating sx={{ ml: 2 }} name="read-only" value={value} readOnly />
              </div>
            </div>
          </div>
        </Grid>

        <Grid container maxWidth="lg">
          <Box sx={{ p: 2.5 }} className="BoxHorariosDisponiveisAgendamento">
            <Grid item xs={12}>
              {tipohorario === 'ntem' ? (
                <>
                  <Item>
                    <Typography className="HorariosSugeridosTitulo">
                      O atendente não possui horários disponíveis
                    </Typography>
                  </Item>
                </>
              ) : (
                ''
              )}

              {tipohorario === 'temforzero' ? (
                <>
                  {horariosdados.map((horario: any, index: any) => (
                    <Horarios
                      key={index}
                      selectedHorario={selectedHorario}
                      handleHorarioSelect={setSelectedHorario}
                      diaDaSemana={semana[horario.diaSemana]}
                      dia={horario.dia}
                      horariosdados={horario.dados}
                    />
                  ))}
                </>
              ) : (
                ''
              )}

              {tipohorario === 'tem' ? (
                <>
                  <Item>
                    <Typography className="HorariosSugeridosTitulo">
                      Horários sugeridos disponíveis
                    </Typography>
                  </Item>

                  <Item className="tabsHorarios" sx={{ px: 1 }}>
                    <Horarios
                      selectedHorario={selectedHorario}
                      handleHorarioSelect={setSelectedHorario}
                      diaDaSemana={horariosdefinidos[0].diasemana}
                      dia={horariosdefinidos[0].dia}
                      horariosdados={horariosdefinidos[0].dados}
                    />
                    <Horarios
                      selectedHorario={selectedHorario}
                      handleHorarioSelect={setSelectedHorario}
                      diaDaSemana={horariosdefinidos[1].diasemana}
                      dia={horariosdefinidos[1].dia}
                      horariosdados={horariosdefinidos[1].dados}
                    />
                    <Horarios
                      selectedHorario={selectedHorario}
                      handleHorarioSelect={setSelectedHorario}
                      diaDaSemana={horariosdefinidos[2].diasemana}
                      dia={horariosdefinidos[2].dia}
                      horariosdados={horariosdefinidos[2].dados}
                    />
                    <Horarios
                      selectedHorario={selectedHorario}
                      handleHorarioSelect={setSelectedHorario}
                      diaDaSemana={horariosdefinidos[3].diasemana}
                      dia={horariosdefinidos[3].dia}
                      horariosdados={horariosdefinidos[3].dados}
                    />

                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      sx={{mt: 1.5}}
                    >
                      <Iconify icon="eva:info-outline" sx={{ mr: 1 }} /> 
                      <Typography variant='subtitle2'>  Horário de Brasília</Typography>
                    </Grid>
                   

                    <Button variant="outlined" sx={{ my: 3 }} onClick={MaisOpcoes}>
                      {' '}
                      <Iconify icon="eva:calendar-outline" sx={{ mr: 1.5 }} /> Ver todas as opções
                      de horário
                    </Button>
                  </Item>
                </>
              ) : (
                ''
              )}

              <Item>
                <Button
                  fullWidth
                  className="btnAgendamento"
                  sx={{ p: 1.5 }}
                  variant="contained"
                  onClick={handleClickOpen}
                >
                  Continuar
                </Button>
                <Button
                  fullWidth
                  className="btnAgendamento"
                  sx={{ p: 1.5 }}
                  variant="outlined"
                  onClick={Voltar}
                >
                  Voltar
                </Button>
              </Item>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
