import {useEffect, useState, useCallback} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import {useSettingsContext} from '../../../components/settings';


import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
// routes
import {PATH_DASHBOARD} from '../../../routes/paths';
import axios from "../../../utils/axios";
import {useAuthContext} from "../../../auth/useAuthContext";
import Iconify from "../../../components/iconify";
import NotificaAudio from "./ComponetesNotificacoes/NotificaAudio";
import NotificaVideo from "./ComponetesNotificacoes/NotificaVideo";
import NotificaPagamento from "./ComponetesNotificacoes/NotificaPagamento";
import NotificaAnjoChegou from "./ComponetesNotificacoes/NotificaAnjoChegou";
import NotificaAnjoNaoChegou from "./ComponetesNotificacoes/NotificaAnjoNaoChegou";
import NotificaPagamentoAgen from "./ComponetesNotificacoes/NotificaPagamentoAgen";



// ----------------------------------------------------------------------
type props = {
    estadoNotifica: any;
    fecharmodal: any;
    dados: any;
};

export default function Notificacoes({estadoNotifica,fecharmodal,dados}:props) {
    const {themeStretch} = useSettingsContext();

    const { user } = useAuthContext();
    const navigate = useNavigate();

    const {pathname} = useLocation();

    const {tokenConversa = ''} = useParams();





    return (
            <>
                

                { estadoNotifica === "Pagamento" ? (
                    <NotificaPagamento fecharmodal={fecharmodal} dados={dados} />
                ) : ''}

                { estadoNotifica === "PagamentoAgen" ? (
                    <NotificaPagamentoAgen fecharmodal={fecharmodal} dados={dados} />
                ) : ''}

                { estadoNotifica === "AnjoChegou" ? (
                    <NotificaAnjoChegou fecharmodal={fecharmodal} dados={dados} />
                ) : ''}
                { estadoNotifica === "AnjoNaoChegou" ? (
                    <NotificaAnjoNaoChegou fecharmodal={fecharmodal} dados={dados} />
                ) : ''}

            </>
    );
}
