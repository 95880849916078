import * as Yup from 'yup';
import {useEffect, useState, useCallback, useMemo} from 'react';
import {Divider, styled, Tabs,Radio ,RadioGroup ,FormControlLabel ,Chip, Typography,Card, FormLabel , Tab, IconButton,Button,Link,TextField,InputLabel, List, MenuItem,FormControl,OutlinedInput,InputAdornment, Paper, Box, Grid, Badge, Avatar, Select, Stack, Rating, Tooltip} from "@mui/material";
import { CustomAvatar } from "src/components/custom-avatar";
import Iconify from 'src/components/iconify';

import {useLocation, useNavigate, useParams} from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import {useSnackbar} from "notistack";
import { LoadingButton } from '@mui/lab';
import axios from "../../../utils/axios";
import ChatNavItem from "../chat/nav/ChatNavItem";
import ProfilePicture from "./img/pessoa_call.png";
import { CustomFile } from '../../../components/upload';

import { IUserProfile, IUserProfilePost } from '../../../@types/user';

import {PATH_AUTH, PATH_DASHBOARD} from '../../../routes/paths';

import {
  _userAbout,
  _userFeeds,
  _userFriends,
  _userGallery,
  _userFollowers,
} from '../../../_mock/arrays';

import FormProvider, {RHFCheckbox} from '../../../components/hook-form';
import {fData} from "../../../utils/formatNumber";
import {useAuthContext} from "../../../auth/useAuthContext";
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
import CardComunidade from './ComponentesComunidades/CardComunidade';

import depressao from './img/depressao.jpg'
import ansiedade from './img/ansiedade.jpg'
import luto from './img/luto.jpg'
import ComunidadeInterno from './ComponentesComunidades/ComunidadeInterno';

// ----------------------------------------------------------------------

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

export default function Comunidades({socket}:any) {
  
    const [acessoComunidade, setAcessoComunidade] = useState(false);
    const [comunidadedados, setcomunidadedados] = useState<any>({});
    const handleAcessoComunidade = (comu:any) => {
        setcomunidadedados(comu);
      setAcessoComunidade(true);
    }
    const [comunidadesdados, setcomunidades] = useState<any>([]);

    const handleCloseAcessoComunidade = () => {
      setAcessoComunidade(false);
    }

    const [seachstate, setSeach] = useState('');

    const getcomunidades= useCallback(async () => {

        try {
            const response = await axios.post(`/areadoheroi/getcomunidades`,{
                query: seachstate
            });

            const {comunidades} = response.data;
            setcomunidades(comunidades);

        } catch (error) {
            console.error(error);
        }
    },[seachstate]);

    useEffect(() => {

        getcomunidades();

    }, [getcomunidades]);




    const handleChangeSearch = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setSeach(value);

    };

    // @ts-ignore
    return (
            <>
            <Box sx={{ p: 2.5 }}>
              <div className="BreadCrumbsListagemMaterial">
                <CustomBreadcrumbs
                heading="Comunidades"
                links={[
                    {
                    name: 'Dashboard',
                    href: PATH_DASHBOARD.root,
                    },
                    {
                    name: 'Comunidades',
                    href: PATH_DASHBOARD.comunidades,
                    }
                ]}
                />
                </div>
              </Box>
            

              <Box sx={{ p: 2.5 }}>
                {!acessoComunidade ? (
                  <>
                      <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
                      <Grid item xs={12} md={12}>
                        <Item>
                          <Typography variant='h3' color='primary'>Comunidades Eyhe</Typography>
                        </Item>

                        <Item>
                          
                          <TextField
                            label="Pesquise aqui pelo assunto"
                            id="outlined-start-adornment"
                            onChange={handleChangeSearch}
                            sx={{ m: 1, width: '60ch' }}
                            InputProps={{
                              endAdornment: <Iconify icon='eva:search-outline' />,
                            }}
                          />

                        </Item>

                        <Item>
                          <Chip color='primary' sx={{p:2}} size='medium' label="Seja bem vindo(a) a comunidade Eyhe. Aqui você pode conversar com outros heróis. Clique aqui para saber mais" />
                        </Item>
                      </Grid>
                    </Grid>

                    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>

                        {comunidadesdados.map((comuni:any,index:any) => (
                      
                      <Grid item xs={12} md={4} key={index}>
                        <Item>
                          <CardComunidade imgComunidade={comuni.imagem} tituloComunidade={comuni.Nome} qtdMembros={comuni.qtdMembros} ultimaMensagem={comuni.ultimaMensagem} onClick={()=>{handleAcessoComunidade(comuni)} } />
                        </Item>
                      </Grid>

                        ))}



                    </Grid>
                  </>
                ) : (
                  <ComunidadeInterno socket={socket}  id={comunidadedados.id}  imgComunidade={comunidadedados.imagem} tituloComunidade={comunidadedados.Nome}  qtdMembros={comunidadedados.qtdMembros} ultimaMensagem={comunidadedados.ultimaMensagem} />
                )}
                

              </Box>

            </>
    );
}
