import { Dialog,DialogTitle ,Grid, DialogContent,ListItemText,Tooltip, Box, Rating, useMediaQuery ,ToggleButtonGroup ,ToggleButton, ListItem,List, Divider, AppBar , Toolbar ,IconButton  ,Slider,Slide,ButtonGroup, Button, TextField, Tabs, Tab, InputAdornment, Paper, Typography} from "@mui/material";
import { TransitionProps } from '@mui/material/transitions';
import React, {useState, useRef, useEffect, MouseEvent, KeyboardEvent} from "react";
import {styled, useTheme} from "@mui/material/styles";
import { CustomAvatar } from "src/components/custom-avatar";
import useResponsive from 'src/hooks/useResponsive';
import {useSettingsContext} from "../settings";
import {useAuthContext} from "../../auth/useAuthContext";
import Iconify from "../iconify";
import MenuPopover from "../menu-popover";
import "./FiltrarProfissional.css";
import BadgeStatus, {BadgeStatusValue} from "../badge-status";
import axios from "../../utils/axios";

import iconAtendente1 from "./img/love.svg"
import iconAtendente2 from "./img/psychology.svg"
import iconAtendente4 from "./img/terapia.png"
import iconAtendente5 from "./img/coach.png"
import slideIcon from './img/slide.gif';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

type props = {
    filtrarProficional: any;
}
export default function FiltrarProfissional({filtrarProficional}:props) {



    const [valueProfissional, setValueProfissional] = React.useState('');
    const handleProficional = (event: React.SyntheticEvent, newValue: any) => {
        setValueProfissional(newValue);
        sessionStorage.setItem('tipoprofissional',newValue);
        filtrarProficional(newValue);
    };

    
  const isDesktop = useResponsive('up', 'lg');
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
  
    const handleMouseDown = (e: MouseEvent<HTMLDivElement>) => {
      setIsDragging(true);
      setStartX(e.pageX - containerRef.current!.offsetLeft);
      setScrollLeft(containerRef.current!.scrollLeft);
    };
  
    const handleMouseMove = (e: MouseEvent<HTMLDivElement>) => {
      if (!isDragging) return;
      const x = e.pageX - containerRef.current!.offsetLeft;
      const walk = (x - startX) * 3; // Adjust the scroll sensitivity here
      containerRef.current!.scrollLeft = scrollLeft - walk;
    };

    
  
    const handleMouseUp = () => {
      setIsDragging(false);
    };
  
    const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
      // Handle keyboard events if necessary
      // e.g., you can use arrow keys to scroll
    };

    useEffect(() => {
        if(sessionStorage.getItem('tipoprofissional')){
            // @ts-ignore
            setValueProfissional(sessionStorage.getItem('tipoprofissional'));
        }

    }, []);

    return (
        <>
            <div className="CardFiltrarAtendente">
                    <div className="TipoDeProfissionalScroll">
                        <Typography className="SubTituloCardFiltroAtendente">Tipo de Profissional</Typography>

                        <div
                          ref={containerRef}
                          role="scrollbar"
                          tabIndex={0}
                          aria-label="Toggle Button Group"
                          aria-controls="toggleButtonGroup"
                          aria-valuenow={containerRef.current?.scrollLeft || 0}
                          style={{
                            display: 'flex',
                            overflowX: 'auto',
                            WebkitOverflowScrolling: 'touch',
                             scrollbarWidth: 'none',
                            scrollbarColor: '#078DEE transparent', 
                            whiteSpace: 'nowrap',
                          }}
                          onMouseDown={handleMouseDown}
                          onMouseMove={handleMouseMove}
                          onMouseUp={handleMouseUp}
                          onKeyDown={handleKeyDown}
                          className="FiltroProfissionais"
                        >

                        <ToggleButtonGroup
                        color="primary"
                        value={valueProfissional}
                        exclusive
                        onChange={handleProficional}
                        aria-label="Profisionais"

                        >
                          
                            <ToggleButton value="2"> <img src={iconAtendente2} height="30px" alt="Icone Psicólogo" /> Psicólogo</ToggleButton>
                            <ToggleButton value="1"> <img src={iconAtendente1} height="30px" alt="Icone Anjo" /> Anjo</ToggleButton>
                            <ToggleButton value="4"><img src={iconAtendente4} height="30px" alt="Icone Terapeuta" /> Terapeuta</ToggleButton>
                            <ToggleButton value="5"><img src={iconAtendente5} height="30px" alt="Icone Coach" /> Coach</ToggleButton>
                            
                        </ToggleButtonGroup>

                        <div className="SlideDivFiltroAssuntos" style={{position: 'absolute', width: '100px', height:'55px', right: '3%', background: 'linear-gradient(270deg, rgba(255,255,255,1) 25%, rgba(0,166,220,0) 100%)'}}>
                          <img src={slideIcon} width={25} height={25} alt="" />
                        </div>

                        </div>
                       
                    </div>
            </div>
        </>
    );
}
