import { Helmet } from 'react-helmet-async';
// sections
import { Pacotes } from 'src/sections/@dashboard/Pacotes';



export default function PacotesPage() {
  return (
    <>
      <Helmet>
        <title>Pacotes do Herói - Eyhe Maior Comunidade de Suporte Emocional do Brasil</title>
      </Helmet>

      <Pacotes  />
    </>
  );
}
