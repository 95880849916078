import { Dialog,DialogTitle ,Grid, DialogContent,ListItemText, Box,Tooltip,ToggleButtonGroup, ToggleButton, Rating, useMediaQuery ,ListItem,List, Divider, AppBar , Toolbar ,IconButton  ,Slider,Slide, Button, TextField, Tabs, Tab, InputAdornment, Paper, Typography} from "@mui/material";
import { TransitionProps } from '@mui/material/transitions';
import React, {useState, useRef, useEffect, MouseEvent, KeyboardEvent} from "react";
import {styled, useTheme} from "@mui/material/styles";
import { CustomAvatar } from "src/components/custom-avatar";
import { useGesture, useDrag  } from 'react-use-gesture';
import {useSettingsContext} from "../settings";
import {useAuthContext} from "../../auth/useAuthContext";
import Iconify from "../iconify";
import MenuPopover from "../menu-popover";
import "./FiltrarAssuntos.css";
import BadgeStatus, {BadgeStatusValue} from "../badge-status";
import axios from "../../utils/axios";

import slideIcon from './img/slide.gif';



const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

type props = {
    filtrarAssunto: any;
}

  export default function FiltrarAssuntos({filtrarAssunto}:props) {
    
    const [valueAssunto, setValueAssunto] = React.useState('');
    const handleAssunto = (event: React.SyntheticEvent, newValue: any) => {
        setValueAssunto(newValue);
        sessionStorage.setItem('assunto',newValue);
        filtrarAssunto(newValue);
    };

    






    const containerRef = useRef<HTMLDivElement | null>(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
  
    const handleMouseDown = (e: MouseEvent<HTMLDivElement>) => {
      setIsDragging(true);
      setStartX(e.pageX - containerRef.current!.offsetLeft);
      setScrollLeft(containerRef.current!.scrollLeft);
    };
  
    const handleMouseMove = (e: MouseEvent<HTMLDivElement>) => {
      if (!isDragging) return;
      const x = e.pageX - containerRef.current!.offsetLeft;
      const walk = (x - startX) * 3; // Adjust the scroll sensitivity here
      containerRef.current!.scrollLeft = scrollLeft - walk;
    };

    
  
    const handleMouseUp = () => {
      setIsDragging(false);
    };
  
    const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
      // Handle keyboard events if necessary
      // e.g., you can use arrow keys to scroll
    };


      useEffect(() => {
          if(sessionStorage.getItem('assunto')){
              // @ts-ignore
              setValueAssunto(sessionStorage.getItem('assunto'));
          }

      }, []);




    return (
        <>
        
        <div 
        className="CardFiltrarAssunto">
           
                        <Typography className="SubTituloCardFiltroAtendente">Busca por Assunto</Typography>
                        
                        


                        <div
                          ref={containerRef}
                          role="scrollbar"
                          tabIndex={0}
                          aria-label="Toggle Button Group"
                          aria-controls="toggleButtonGroup"
                          aria-valuenow={containerRef.current?.scrollLeft || 0}
                          style={{
                            display: 'flex',
                            overflowX: 'auto',
                            WebkitOverflowScrolling: 'touch',
                            scrollbarWidth: 'none',
                            scrollbarColor: '#078DEE transparent',
                            whiteSpace: 'nowrap',
                          }}
                          onMouseDown={handleMouseDown}
                          onMouseMove={handleMouseMove}
                          onMouseUp={handleMouseUp}
                          onKeyDown={handleKeyDown}
                          className="FiltroProfissionais"
                        >
                          <ToggleButtonGroup
                          id="toggleButtonGroup"
                          color="primary"
                          value={valueAssunto}
                          onChange={handleAssunto}
                          exclusive
                        >
                            <ToggleButton value="25"><img src="https://api.eyhe.com.br/img/filtrar-assuntos/Drugs Addict.svg" height="30px" alt="Icone Vícios" /> Vícios</ToggleButton>
                            <ToggleButton value="17"> <img src="https://api.eyhe.com.br/img/filtrar-assuntos/Crying.svg" height="30px" alt="Icone Abuso Sexual" />  Abuso Sexual</ToggleButton>
                            <ToggleButton value="2"><img src="https://api.eyhe.com.br/img/filtrar-assuntos/Intrusive thought.svg" height="30px" alt="Icone Ansiedade" /> Ansiedade</ToggleButton>
                            <ToggleButton value="1"><img src="https://api.eyhe.com.br/img/filtrar-assuntos/Depression.svg" height="30px" alt="Icone Depressão" /> Depressão</ToggleButton>
                            <ToggleButton value="3"><img src="https://api.eyhe.com.br/img/filtrar-assuntos/Alzeimer.svg" height="30px" alt="Icone Luto" /> Luto</ToggleButton>
                            <ToggleButton value="7"><img src="https://api.eyhe.com.br/img/filtrar-assuntos/Mental Health.svg" height="30px" alt="Icone Problemas de Saúde" /> Problemas de saúde</ToggleButton>
                            <ToggleButton value="4"><img src="https://api.eyhe.com.br/img/filtrar-assuntos/Support System.svg" height="30px" alt="Icone Relacionamentos" /> Relacionamento</ToggleButton>
                            <ToggleButton value="18"><img src="https://api.eyhe.com.br/img/filtrar-assuntos/Emotion.svg" height="30px" alt="Icone Amor" /> Amor</ToggleButton>
                            <ToggleButton value="24"><img src="https://api.eyhe.com.br/img/filtrar-assuntos/Stress.svg" height="30px" alt="Icone Stress" /> Stress</ToggleButton>
                            <ToggleButton value="22"><img src="https://api.eyhe.com.br/img/filtrar-assuntos/Hypersex.svg" height="30px" alt="Icone Gênero" /> Gênero</ToggleButton>
                            <ToggleButton value="12"><img src="https://api.eyhe.com.br/img/filtrar-assuntos/Insecure.svg" height="30px" alt="Icone Insegurança" /> Insegurança</ToggleButton>
                            <ToggleButton value="21"><img src="https://api.eyhe.com.br/img/filtrar-assuntos/Phobia.svg" height="30px" alt="Icone Fobias" /> Fobias</ToggleButton>
                            <ToggleButton value="23"><img src="https://api.eyhe.com.br/img/filtrar-assuntos/Sleep.svg" height="30px" alt="Icone Insônia" /> Insônia</ToggleButton>
                            <ToggleButton value="19"><img src="https://api.eyhe.com.br/img/filtrar-assuntos/Autism.svg" height="30px" alt="Icone Autismo" /> Autismo</ToggleButton>
                            <ToggleButton value="20"><img src="https://api.eyhe.com.br/img/filtrar-assuntos/Bipolar.svg" height="30px" alt="Icone Bipolaridade" /> Bipolaridade</ToggleButton>
                            <ToggleButton value="26"><img src="https://api.eyhe.com.br/img/filtrar-assuntos/Espiritualidade.svg" height="30px" alt="Icone Espiritualidade" /> Espiritualidade</ToggleButton>
                        </ToggleButtonGroup>


                        <div className="SlideDivFiltroAssuntos" style={{position: 'absolute', width: '100px', height:'55px', right: '3%', background: 'linear-gradient(270deg, rgba(255,255,255,1) 25%, rgba(0,166,220,0) 100%)'}}>
                          <img src={slideIcon} width={25} height={25} alt="" />
                        </div>
                        
                        </div>
                        
        </div>

 
        </>
    )
  }