import * as Yup from 'yup';
import {useEffect, useState, useCallback, useMemo} from 'react';
import {Divider, styled, Tabs,Radio ,RadioGroup ,FormControlLabel ,Chip, Typography,Card, FormLabel , Tab, IconButton,Button,Link,TextField,InputLabel, List, MenuItem,FormControl,OutlinedInput,InputAdornment, Paper, Box, Grid, Badge, Avatar, Select, Stack, Rating, Tooltip} from "@mui/material";
import { CustomAvatar } from "src/components/custom-avatar";
import Iconify from 'src/components/iconify';

import {useLocation, useNavigate, useParams} from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import {useSnackbar} from "notistack";
import { LoadingButton } from '@mui/lab';
import axios from "../../../../utils/axios";
import ChatNavItem from "../../chat/nav/ChatNavItem";
import { CustomFile } from '../../../../components/upload';

import {PATH_AUTH, PATH_DASHBOARD} from '../../../../routes/paths';

import FormProvider, {RHFCheckbox} from '../../../../components/hook-form';
import {fData} from "../../../../utils/formatNumber";
import {useAuthContext} from "../../../../auth/useAuthContext";
import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs';



// ----------------------------------------------------------------------

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));


type props = {
    imgComunidade: any;
    tituloComunidade: any;
    qtdMembros: any;
    ultimaMensagem: any;
    onClick: any;
};

export default function CardComunidade({imgComunidade, tituloComunidade, qtdMembros, ultimaMensagem, onClick }:props) {
    
    // @ts-ignore
    return (
            <>

                <Card onClick={onClick} sx={{cursor: 'pointer'}}>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        sx={{p:3}}
                    >
                        <img src={imgComunidade} alt={tituloComunidade} style={{width: '90px', aspectRatio: '3/3', objectFit: 'cover', borderRadius: '100%'  }} />

                        <Typography variant="h6" sx={{mt:2}}> {tituloComunidade} </Typography>

                        <Typography variant="subtitle2" sx={{color: '#637381'}}> {qtdMembros} membros </Typography>

                        <Typography variant="subtitle2"> Última mensagem: {ultimaMensagem} </Typography>
                    </Grid>
                </Card>    

            </>
    );
}


