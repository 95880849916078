import { Helmet } from 'react-helmet-async';
// sections
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Chat } from '../sections/@dashboard/chat';
import {Atendetentes} from "../sections/@dashboard/Atendentes";
import {Cadastro} from "../sections/auth/Cadastro";



export default function CadastrePage() {
  return (
    <>
      <Helmet>
        <title>Cadastre-se como Herói - Eyhe Maior Comunidade de Suporte Emocional do Brasil </title>
      </Helmet>
        <GoogleOAuthProvider clientId="485840576679-9a1324oth2rvo07a1h9e97f45pjv6cp1.apps.googleusercontent.com">
      <Cadastro  />
        </GoogleOAuthProvider>
    </>
  );
}
