import { useState, useRef } from 'react';
// @mui
import Picker from '@emoji-mart/react'
import i18n from '@emoji-mart/data/i18n/pt.json'
import data from '@emoji-mart/data'


import useResponsive from 'src/hooks/useResponsive';
import { alpha } from '@mui/material/styles';
import {
  Box,
  Link,
  Card,
  Stack,
  Paper,
  Checkbox,
  InputBase,
  Typography,
  CardHeader,
  IconButton,
  InputAdornment,
  FormControlLabel,
} from '@mui/material';

import * as React from "react";
import MenuPopover from "../../../../components/menu-popover";

import './ForumConversa.css'

// @types
import { IUserProfilePost } from '../../../../@types/user';
// auth
import { useAuthContext } from '../../../../auth/useAuthContext';
// utils
import { fDate } from '../../../../utils/formatTime';
import { fShortenNumber } from '../../../../utils/formatNumber';
// components
import Image from '../../../../components/image';
import Iconify from '../../../../components/iconify';
import { CustomAvatar, CustomAvatarGroup } from '../../../../components/custom-avatar';
import {useSnackbar} from "../../../../components/snackbar";
import axios from "../../../../utils/axios";
import uuidv4 from "../../../../utils/uuidv4";

// ----------------------------------------------------------------------

interface Props {
  posts: any;
  participantedado:any;
  id:any;

}

export default function ForumConversa({ posts,participantedado,id}: Props) {

  const InputRef = useRef<HTMLInputElement>(null);
  const [message, setMessage] = useState('');
  const handleEmojiSelect = async (emoji:any) => {
    const newText = `${message}${emoji.native}`;

    setMessage(newText);
    handleClosePopover();

    InputRef.current?.focus();
  };


  const { user } = useAuthContext();

  const commentInputRef = useRef<HTMLInputElement>(null);

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const isDesktop = useResponsive('up', 'lg');


  const [like, setLike] = useState(false);

  const clickLike = async (status: any,idmenssagem:any) => {

    try {

      const response = await axios.post(`/areadoheroi/comunidade-curtir`, {
        IDComunidade: id,
        IDMenssagem: idmenssagem,
        status,
      });

    } catch (error) {
      enqueueSnackbar(`${error}`, {variant: 'error'});
    }

  }

  const removeLike = () => {
    setLike(false)
  }

  const hasComments = posts.data.length > 0;

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };
  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const { enqueueSnackbar } = useSnackbar();
  const handleSendMessage = async (value: any) => {
    try {

      const response = await axios.post(`/areadoheroi/comunidade-send-menssagem`, {
        IDComunidade:id,
        menssagem:message,
      });

      setMessage('');
    } catch (error) {
      enqueueSnackbar(`${ error}`, { variant: 'error' });
      setMessage('');
    }
  };


  const handleChangeMessage = (value: string) => {
    setMessage(value);
  };

  const handleClickAttach = () => {
    const { current } = fileInputRef;
    if (current) {
      current.click();
    }
  };

  const handleClickComment = () => {
    const { current } = commentInputRef;
    if (current) {
      current.focus();
    }
  };

  const handleSend = (event: React.KeyboardEvent<HTMLInputElement>) => {

    if (event.key === 'Enter') {
      handleSendMessage('ddsd');
      setMessage('');
    }
    return true;
  };



  return (
    <>




      {hasComments && (
        <Stack spacing={1.5} sx={{my: 2}}>
          {posts.data.map((comment:any, index:any) => (
            <Stack key={index} direction="row" spacing={2}>
              <CustomAvatar alt={comment.Nome} src={comment.avatar} />

              <Paper
                sx={{
                  p: 1.5,
                  flexGrow: 1,
                  bgcolor: 'background.neutral',
                }}
              >
                <Stack
                  justifyContent="space-between"
                  direction={{ xs: 'column', sm: 'row' }}
                  alignItems={{ sm: 'flex-start' }}
                  sx={{ mb: 0.5 }}
                >
                  <Typography variant="subtitle2" sx={{textAlign: 'left'}}>{comment.Nome}</Typography>

                  <Typography variant="caption" sx={{ color: 'text.disabled' , textAlign: 'left'}}>
                    {comment.datamessagem}
                  </Typography>
                </Stack>

                <Stack
                  justifyContent="space-between"
                  direction={{ xs: 'row', sm: 'row' }}
                  alignItems={{ sm: 'center' }}
                  sx={{ mb: 0.5, display: 'flex', alignItems: 'center' }}
                >
                  <Typography variant="body2" sx={{ color: 'text.secondary', textAlign: 'left' }}>
                    {comment.menssagem}
                  </Typography>

                  {comment.islike ? (
                      <>
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <IconButton onClick={()=>{ clickLike('remove',comment.id)}}>
                          <Iconify color='error' icon="eva:heart-fill" />
                        </IconButton>
                        <Typography variant="subtitle2"> {comment.likes}</Typography>
                        </div>
                      </>
                  ) : (
                      <>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                        <IconButton onClick={()=>{ clickLike('like',comment.id)}}>
                          <Iconify color='error' icon="eva:heart-outline" />
                        </IconButton>
                        <Typography variant="subtitle2"> {comment.likes}</Typography>
                        </div>
                      </>
                  )}
                  

                

                </Stack>
                
                

                
                

              </Paper>
            </Stack>
          ))}
        </Stack>
      )}


      { participantedado.isparticipante ? (      <Stack
          spacing={2}
          direction="row"
          alignItems="center"

      >
        {isDesktop ? (
          <CustomAvatar src={participantedado?.avatar} alt={participantedado?.Apelido} name={participantedado?.Apelido} />
        ) : ('')}
        

        <InputBase
            fullWidth
            value={message}
            inputRef={commentInputRef}
            placeholder="Escreva aqui"
            onKeyUp={handleSend}
            onChange={(event) => handleChangeMessage(event.target.value)}
            endAdornment={
              <InputAdornment position="end" sx={{ mr: 1 }}>
                <IconButton size="small" onClick={handleOpenPopover} >
                  <Iconify icon="eva:smiling-face-fill" />
                </IconButton>
              </InputAdornment>


            }
            sx={{
              pl: 1.5,
              height: 40,
              borderRadius: 1,
              border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.32)}`,
            }}
        />

        <MenuPopover open={openPopover} onClose={handleClosePopover} arrow="bottom-left" sx={{ p: 0, ml:-4 }}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Picker i18n={i18n} locale='pt' data={data}   onEmojiSelect={handleEmojiSelect}  />
          </Stack>
        </MenuPopover>

        <IconButton className='btn-enviar' sx={{background: '#2065D1', borderRadius: '100%'}} onClick={handleSendMessage}>
          <Iconify icon='eva:diagonal-arrow-right-up-fill' sx={{color: '#fff'}} />
        </IconButton>

        <input type="file" ref={fileInputRef} style={{ display: 'none' }} />
      </Stack>) :('')}



    </>
  );
}
