import {useState, useEffect, useCallback} from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import {
    Box,
    Stack,
    Drawer,
    IconButton,
    IconButtonProps,
    Typography,
    Tooltip,
    Button,
    Divider,
    List, Pagination, FormControl, InputLabel, OutlinedInput, InputAdornment,
    MenuItem, Select, CircularProgress, Grid
} from '@mui/material';

import {
    _ecommerceNewProducts,
    _ecommerceSalesOverview,
    _ecommerceBestSalesman,
    _ecommerceLatestProducts,
  } from '../../../_mock/arrays';

import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
// hooks
import useResponsive from '../../../hooks/useResponsive';

// utils
import axios from '../../../utils/axios';
// routes
import {PATH_AUTH, PATH_DASHBOARD} from '../../../routes/paths';
// @types
import { IChatContact, IChatConversationsState } from '../../../@types/chat';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
//
import ChatNavList from '../chat/nav/ChatNavList';
import ChatNavSearch from '../chat/nav/ChatNavSearch';
import ChatNavAccount from '../chat/nav/ChatNavAccount';
import ChatNavSearchResults from '../chat/nav/ChatNavSearchResults';
import MenuPopover from "../../../components/menu-popover";
import BadgeStatus, {BadgeStatusValue} from "../../../components/badge-status";
import {useAuthContext} from "../../../auth/useAuthContext";
import {useSnackbar} from "../../../components/snackbar";
import BuscarAtendimento from '../chat/nav/BuscarAtendimento';
import ChatAtendimentoLista from '../chat/nav/ChatAtendimentoLista';

import ListagemPacotes from './componentesPacotes/ListagemPacotes';
import EcommerceSaleByGender from './componentesPacotes/EcommerceSaleByGender';
import EcommerceSalesOverview from './componentesPacotes/EcommerceSalesOverview';
import EcommerceWidgetSummary from './componentesPacotes/EcommerceWidgetSummary';

import AppWelcome from './componentesPacotes/AppWelcome';
import EcommerceNewProducts from './componentesPacotes/EcommerceNewProducts';
import  MotivationIllustration  from '../../../assets/illustrations/MotivationIllustration';

// ----------------------------------------------------------------------

const StyledToggleButton = styled((props) => (
    <IconButton disableRipple {...props} />
))<IconButtonProps>(({ theme }) => ({
    left: 0,
    zIndex: 9,
    width: 32,
    height: 32,
    position: 'absolute',
    top: theme.spacing(13),
    borderRadius: `0 12px 12px 0`,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    boxShadow: theme.customShadows.primary,
    '&:hover': {
        backgroundColor: theme.palette.primary.darker,
    },
}));

// ----------------------------------------------------------------------





export default function Pacotes() {

    const theme = useTheme();
    const navigate = useNavigate();
    const [pacotesdados, setPacotes] = useState<any>([]);
    const getpacotes= useCallback(async () => {

        try {
            const response = await axios.post(`/areadoheroi/getlistapacotes`,{

            });

            const {pacotes} = response.data;
            setPacotes(pacotes);

        } catch (error) {
            console.error(error);
        }
    },[]);



    useEffect(() => {

        getpacotes();

    }, [getpacotes]);
    return (
        <>

        <Box sx={{ p: 2.5 }}>
                    <Button variant="text" onClick={()=>{ navigate(PATH_DASHBOARD.dashfirst,{replace:true})}}> <Iconify icon="eva:arrow-ios-back-outline"/> Dashboard </Button>
                    
                    <div className="BreadCrumbsListagemMaterial">
                        <CustomBreadcrumbs
                        heading="Pacotes"
                        links={[
                            {
                            name: 'Dashboard',
                            href: PATH_DASHBOARD.root,
                            },
                            {
                            name: 'Pacotes',
                            href: PATH_DASHBOARD.pacotes,
                            }
                        ]}
                        />
                    </div>
        </Box>

        <Grid container spacing={2}>

    
            
            <Grid item xs={12} md={12} lg={12}>
                <ListagemPacotes 
                title="Listagem de Pacotes"
                tableData={pacotesdados}
                tableLabels={[
                    { id: 'Atendente', label: 'Atendente', align: 'left' },
                    { id: 'TipoPacote', label: 'Tipo de Pacote', align: 'left' },
                    { id: 'Contratadas', label: 'Contratadas', align: 'left' },
                    { id: 'Utilizadas', label: 'Utilizadas', align: 'left' },
                    { id: 'Utilizadas', label: 'Data/Hora', align: 'left' },
                    { id: 'Utilizadas', label: 'Status', align: 'left' },
                    { id: 'mais', label: 'Mais Informações', align: 'left' },
                ]}
                />
            </Grid>

        </Grid>

        

        </>
    )
}
