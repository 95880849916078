import {useEffect, useState, useCallback, useMemo, useLayoutEffect} from 'react';
// @mui
import {Divider,Tabs, Tab, IconButton,Button,Dialog,Card,Chip,TextField, List, MenuItem,FormControl,OutlinedInput,InputAdornment, Paper, Box, Grid, Badge, Avatar, Select, Stack, Rating, Tooltip, Typography} from "@mui/material";
import { CustomAvatar } from "src/components/custom-avatar";
import {useLocation, useNavigate, useParams} from 'react-router-dom';
// @types
import useResponsive from "src/hooks/useResponsive";
import Iconify from 'src/components/iconify/Iconify';
import {styled, Theme} from '@mui/material/styles';
import { getCoresTemplate } from 'src/utils/coresTemplate';
import {useSettingsContext} from '../../../components/settings';
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';

import { IUserProfile, IUserProfilePost } from '../../../@types/user';


import FotoPerfil from './background_verperfil.jpg'

import ansiedade from './img/ansiedade.jpg'
import depressao from './img/depressao.jpg'
import relacaoToxica from './img/relacao-toxica.jpg'

import {PATH_DASHBOARD} from "../../../routes/paths";
import axios from "../../../utils/axios";
import {BadgeStatusValue} from "../../../components/badge-status";
import {useAuthContext} from "../../../auth/useAuthContext";
import {criarevento} from "../../../utils/analytics ";
import CardQuiz from './componentesQuiz/CardQuiz';



// ----------------------------------------------------------------------

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function Quiz() {

  const navigate = useNavigate();

  const CARD_INFO = [
    { titulo: 'Teste de Ansiedade', data: '20/03/2024', descricao:'Descrição completa aqui do teste de ansiedade no Eyhe 5.0', linkTeste: 'https://eyhe.com.br/teste-ansiedade/' , img: ansiedade },
    { titulo: 'Teste de Depressão', data: '20/03/2024', descricao:'Descrição completa aqui do teste de depressao no Eyhe 5.0', linkTeste: 'https://eyhe.com.br/teste-depressao/' , img: depressao },
    { titulo: 'Teste de Relação Tóxica', data: '20/03/2024', descricao:'Descrição completa aqui do teste de relacionamento tóxico no Eyhe 5.0', linkTeste: 'https://eyhe.com.br/teste-relacao-toxica/' , img: relacaoToxica },
];

  // @ts-ignore
  // @ts-ignore
  return (

    <>
            <Box sx={{ p: 2.5 }}>
                      <Button variant="text" onClick={()=>{ navigate(PATH_DASHBOARD.dashfirst,{replace:true})}}> <Iconify icon="eva:arrow-ios-back-outline"/> Dashboard </Button>
                      
                      <div className="BreadCrumbsListagemMaterial">
                          <CustomBreadcrumbs
                          heading="Quiz"
                          links={[
                              {
                              name: 'Dashboard',
                              href: PATH_DASHBOARD.root,
                              },
                              {
                              name: 'Quiz',
                              href: PATH_DASHBOARD.pacotes,
                              }
                          ]}
                          />
                      </div>
            </Box>

            <Box sx={{ p: 2.5 }}>



            <Grid container spacing={2}>
            {CARD_INFO.map((info, index) => (
              <Grid item xs={12} md={4}>
                <Item> 
                  <CardQuiz
                          key={index}
                          tituloPost={info.titulo}
                          dataPost={info.data}
                          descricaoPost={info.descricao}
                          linkTeste={info.linkTeste}
                          imagePost={info.img}
                  /> 
                </Item>
              </Grid>
              ))}

            </Grid>


            
               
                
               
            </Box>

          

    </>
  );
}
