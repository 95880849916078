import {useState, useEffect, useCallback} from 'react';
// @mui
import {
    Box,
    Card,
    Table,
    Stack,
    Avatar,
    TableRow,
    TableBody,
    TableCell,
    CardProps,
    CardHeader,
    Typography,
    TableContainer,
    Chip,
    Button
  } from '@mui/material';
  // utils
  import { Link as RouterLink, useNavigate } from 'react-router-dom';
  import { fCurrency } from '../../../../utils/formatNumber';
  // components
  import Label from '../../../../components/label';
  import Image from '../../../../components/image';
  import Scrollbar from '../../../../components/scrollbar';
  import { TableHeadCustom } from '../../../../components/table';
  import {PATH_AUTH, PATH_DASHBOARD} from '../../../../routes/paths';

  // ----------------------------------------------------------------------
  
  type RowProps = {
    id: string;
    name: string;
    email: string;
    avatar: string;
    category: string;
    flag: string;
    total: number;
    rank: string;
  };
  
  interface Props extends CardProps {
    title?: string;
    subheader?: string;
    tableData: RowProps[];
    tableLabels: any;
  }
  
  export default function EcommerceBestSalesman({
    title,
    subheader,
    tableData,
    tableLabels,
    ...other
  }: Props) {

    
    return (
      <Card {...other}>
        <CardHeader title={title} subheader={subheader} sx={{ mb: 3 }} />
  
        <TableContainer sx={{ overflow: 'unset' }}>
          <Scrollbar>
            <Table sx={{ minWidth: 720 }}>
              <TableHeadCustom headLabel={tableLabels} />
  
              <TableBody>
                {tableData.map((row) => (
                  <ListagemPacotes key={row.id} row={row} />
                ))}
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>
      </Card>
    );
  }
  
  // ----------------------------------------------------------------------
  
  type ListagemPacotesProps = {
    row: any;
  };
  
  function ListagemPacotes({ row }: ListagemPacotesProps) {

    const navigate = useNavigate();

    const verDetalhes = () => {
        localStorage.setItem('idpacote',row.id)
      navigate(PATH_DASHBOARD.detalhespacotes)
    }

    const [status, setStatus] = useState('Ativo');
   
    return (
      <TableRow>
        <TableCell>
          <Stack direction="row" alignItems="center">
            <Avatar alt={row.name} src={row.avatar} />
  
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2"> {row.name} </Typography>
              
            </Box>
          </Stack>
        </TableCell>
  
        <TableCell>{row.tipopacote}</TableCell>
  
        <TableCell>{row.contratadas}</TableCell>
  
        <TableCell>{row.usados}</TableCell>
  
        <TableCell>{row.data}</TableCell>

        <TableCell>
          {row.statusok === 'Ativo' && <Chip label="Ativo" size="small" color="success" />}
          {row.statusok === 'Pendente' && <Chip label="Pendente" size="small" color="warning" />}
          {row.statusok === 'Usado' && <Chip label="Inativo" size="small" color="error" />}
        </TableCell>

        <TableCell> <Button variant='contained' color='primary' onClick={verDetalhes}> Ver Detalhes </Button> </TableCell>
      </TableRow>
    );
  }
  