import {useState, useEffect, useCallback} from 'react';
import { ApexOptions } from 'apexcharts';
// @mui
import { Grid,alpha, Paper, Box, Card, Typography, Stack, CardProps, Chip, Rating, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
// components
import { CustomAvatar } from 'src/components/custom-avatar';
import { getCoresTemplate } from "src/utils/coresTemplate";
import Iconify from '../../../../components/iconify';
import Chart, { useChart } from '../../../../components/chart';
import avatarImg from '../img/avatar_2.jpg'

import imgUtilizados from '../img/conversas-utilizadas.png'

import './ComponentePacotesCards.css'

// ----------------------------------------------------------------------


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

interface Props extends CardProps {
  title: string;
  qtdAtendimentos: any;
  tempoAtendimento: any;
    tipoconversa: any;
}

export default function CardAtendimentosUtilizados({
  title,
  qtdAtendimentos,
  tipoconversa,
  tempoAtendimento,
  sx,
  ...other
}: Props) {



  return (
    <Card sx={{ display: 'flex', alignItems: 'center', p: 3, ...sx }} className='CardAtendimentosUtilizados' {...other}>
      <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        >
        <Grid item xs={8}>
            <Item sx={{display: 'flex', background: 'transparent', color: '#fff', flexDirection: 'column', justifyContent: 'flex-start', textAlign: 'left'}}>
                <Typography variant="subtitle1">
                  {title}
                </Typography>

        
                <Typography variant="h3">
                    {qtdAtendimentos} / {tempoAtendimento} min
                </Typography>

                <Typography variant="subtitle2">
                    {tipoconversa}
                </Typography>
            </Item>
        </Grid>

        <Grid item xs={4} sx={{background: 'transparent'}}>
            <Item sx={{background: 'transparent'}}>
              <img src={imgUtilizados} alt='Informações atendentes' width={80} className='imgCardDetalhesPacotes' />
            </Item>
          </Grid>
            
        </Grid>

      </Box>
      
    </Card>
  );
}
