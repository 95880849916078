import {useState, useEffect, useCallback} from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import {
    Box,
    Stack,
    Drawer,
    IconButton,
    IconButtonProps,
    Typography,
    Tooltip,
    Divider,
    Button,
    CircularProgress,
    List,
    MenuItem, Select
} from '@mui/material';

import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
// hooks
import useResponsive from '../../../hooks/useResponsive';

// utils
import axios from '../../../utils/axios';
// routes
import {PATH_AUTH, PATH_DASHBOARD} from '../../../routes/paths';
// @types
import { IChatContact, IChatConversationsState } from '../../../@types/chat';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
//
import ChatNavList from '../chat/nav/ChatNavList';
import ChatNavSearch from '../chat/nav/ChatNavSearch';
import ChatNavAccount from '../chat/nav/ChatNavAccount';
import ChatNavSearchResults from '../chat/nav/ChatNavSearchResults';
import MenuPopover from "../../../components/menu-popover";
import BadgeStatus, {BadgeStatusValue} from "../../../components/badge-status";
import {useAuthContext} from "../../../auth/useAuthContext";
import {useSnackbar} from "../../../components/snackbar";
import './Agendamentos.css';
import LoadingScreen from "../../../components/loading-screen";
import ModalAvaliacao from "../../../components/modalAvalicao/ModalAvaliacao";
import ItemAgendamentos from './componentesAgendamentos/ItemAgendamentos';

// ----------------------------------------------------------------------

const StyledToggleButton = styled((props) => (
    <IconButton disableRipple {...props} />
))<IconButtonProps>(({ theme }) => ({
    left: 0,
    zIndex: 9,
    width: 32,
    height: 32,
    position: 'absolute',
    top: theme.spacing(13),
    borderRadius: `0 12px 12px 0`,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    boxShadow: theme.customShadows.primary,
    '&:hover': {
        backgroundColor: theme.palette.primary.darker,
    },
}));

// ----------------------------------------------------------------------

const NAV_WIDTH = 320;

const NAV_COLLAPSE_WIDTH = 96;



export default function Agendamentos() {
    const { enqueueSnackbar } = useSnackbar();

    const [conversas, setConvesas] = useState<any>({data:[]});
    const [page, setpage] = useState<any>(1);
    const [loading, setloading] = useState<any>(true);
    const [ultimaconversaagendamento, setultimaconversaagendamento] = useState<any>([]);
    const [Recorrenciaagendamento, setRecorrenciaagendamento] = useState(false);

    const getagendamentos = useCallback(async () => {

        try {
            const response = await axios.post('/areadoheroi/getagendamentos', {limit:100,status:true});

            const { conversa } = response.data;

            setultimaconversaagendamento(conversa);

            if (conversa.length > 0) {
                setRecorrenciaagendamento(true);
            }
        } catch (error) {
            console.error(error);
        }
    }, []);

    useEffect(() => {
        getagendamentos();
    }, [getagendamentos]);







    const theme = useTheme();

    const STATUS = ['online', 'invisible', 'away'] as const;

    const navigate = useNavigate();

    const isDesktop = useResponsive('up', 'md');

    const [openNav, setOpenNav] = useState(true);

    const { user, logout } = useAuthContext();
    const [searchContacts, setSearchContacts] = useState('');

    const isCollapse = isDesktop && !openNav;

    const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
    const [status, setStatus] = useState<BadgeStatusValue>('online');
    const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
        setOpenPopover(event.currentTarget);
    };

    const handleClosePopover = () => {
        setOpenPopover(null);
    };



    useEffect(() => {
        if (!isDesktop) {
            handleCloseNav();
        } else {
            handleOpenNav();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDesktop]);

    const handleToggleNav = () => {
        setOpenNav(!openNav);
    };

    const handleOpenNav = () => {
        setOpenNav(true);
    };

    const handleCloseNav = () => {
        setOpenNav(false);
    };
  




    const renderContent = (
        <>
            <Box sx={{ px: 2.5 }}>
                <Button variant="text" onClick={()=>{ navigate(PATH_DASHBOARD.dashfirst,{replace:true})}}> <Iconify icon="eva:arrow-ios-back-outline"/> Dashboard </Button>
                <div className="BreadCrumbsListagemMaterial">
                    <CustomBreadcrumbs
                    heading="Seus agendamentos"
                    links={[
                        {
                        name: 'Dashboard',
                        href: PATH_DASHBOARD.root,
                        },
                        {
                        name: 'Seus agendamentos',
                        href: PATH_DASHBOARD.atendimentos,
                        }
                    ]}
                    />
                </div>
            </Box>
            

        </>
    );

    return (
        <>
            {renderContent}


            {ultimaconversaagendamento.map((atendimentoemandamento: any, index: any) => (
            <ItemAgendamentos key={index} atendimentoemandamento={atendimentoemandamento}/>
            ))}

        </>
    )
}
