import {useState, useEffect, useCallback} from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import {
    Box,
    Stack,
    Drawer,
    IconButton,
    IconButtonProps,
    Typography,
    Tooltip,
    Button,
    Divider,
    List, Pagination, FormControl, InputLabel, OutlinedInput, InputAdornment,
    MenuItem, Select, CircularProgress, Grid
} from '@mui/material';

import {
    _ecommerceNewProducts,
    _ecommerceSalesOverview,
    _atendimentos,
    _ecommerceBestSalesman,
    _ecommerceLatestProducts,
  } from '../../../_mock/arrays';

import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
// hooks
import useResponsive from '../../../hooks/useResponsive';

// utils
import axios from '../../../utils/axios';
// routes
import {PATH_AUTH, PATH_DASHBOARD} from '../../../routes/paths';
// @types
import { IChatContact, IChatConversationsState } from '../../../@types/chat';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
//
import ChatNavList from '../chat/nav/ChatNavList';
import ChatNavSearch from '../chat/nav/ChatNavSearch';
import ChatNavAccount from '../chat/nav/ChatNavAccount';
import ChatNavSearchResults from '../chat/nav/ChatNavSearchResults';
import MenuPopover from "../../../components/menu-popover";
import BadgeStatus, {BadgeStatusValue} from "../../../components/badge-status";
import {useAuthContext} from "../../../auth/useAuthContext";
import {useSnackbar} from "../../../components/snackbar";
import BuscarAtendimento from '../chat/nav/BuscarAtendimento';
import ChatAtendimentoLista from '../chat/nav/ChatAtendimentoLista';

import ListagemPacotes from './componentesPacotes/ListagemPacotes';
import EcommerceSaleByGender from './componentesPacotes/EcommerceSaleByGender';
import EcommerceSalesOverview from './componentesPacotes/EcommerceSalesOverview';
import EcommerceWidgetSummary from './componentesPacotes/EcommerceWidgetSummary';

import AppWelcome from './componentesPacotes/AppWelcome';
import EcommerceNewProducts from './componentesPacotes/EcommerceNewProducts';
import  MotivationIllustration  from '../../../assets/illustrations/MotivationIllustration';
import CardInfoAtendente from './componentesPacotes/CardInfoAtendente';
import CardAtendimentosComprados from './componentesPacotes/CardAtendimentosComprados';
import AnalyticsCurrentVisits from './componentesPacotes/AnalyticsCurrentVisits';
import CardAtendimentosUtilizados from './componentesPacotes/CardAtendimentosUtilizados';
import AtendimentosStepper from './componentesPacotes/AtendimentosStepper';

// ----------------------------------------------------------------------

const StyledToggleButton = styled((props) => (
    <IconButton disableRipple {...props} />
))<IconButtonProps>(({ theme }) => ({
    left: 0,
    zIndex: 9,
    width: 32,
    height: 32,
    position: 'absolute',
    top: theme.spacing(13),
    borderRadius: `0 12px 12px 0`,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    boxShadow: theme.customShadows.primary,
    '&:hover': {
        backgroundColor: theme.palette.primary.darker,
    },
}));

// ----------------------------------------------------------------------





export default function DetalhesPacotes() {


    const theme = useTheme();
    const navigate = useNavigate();


    const [pacotedados, setPacote] = useState<any>({atendente:{id:'',tipoAtendente:''},steps:[]});
    const getpacotes= useCallback(async () => {

        try {
            const response = await axios.post(`/areadoheroi/getlistapacote`,{
                id:localStorage.getItem('idpacote')
            });

            const {pacote} = response.data;
            setPacote(pacote);

        } catch (error) {
            console.error(error);
        }
    },[]);



    useEffect(() => {

        getpacotes();

    }, [getpacotes]);



    const myData = [
      { label: 'Atendimento 01', amount: 5000, value: 100 },
      { label: 'Atendimento 02', amount: 3000, value: 0 }
    ];

    return (
        <>

        <Box sx={{ p: 2.5 }}>
                    <Button variant="text" onClick={()=>{ navigate(PATH_DASHBOARD.pacotes,{replace:true})}}> <Iconify icon="eva:arrow-ios-back-outline"/> Pacotes </Button>
                    
                    <div className="BreadCrumbsListagemMaterial">
                        <CustomBreadcrumbs
                        heading="Detalhes dos Pacotes"
                        links={[
                            {
                            name: 'Dashboard',
                            href: PATH_DASHBOARD.root,
                            },
                            {
                            name: 'Pacotes',
                            href: PATH_DASHBOARD.pacotes,
                            },
                            {
                              name: 'Detalhes dos Pacotes',
                              href: PATH_DASHBOARD.detalhespacotes,
                              }
                        ]}
                        />
                    </div>
        </Box>

        <Grid container spacing={2} sx={{mt: 1.5}}>

        


        <Grid item xs={12} md={4}>
            <CardInfoAtendente
              title={pacotedados.name}
              estrelas={pacotedados.estrelas}
              atendente={pacotedados.atendente}
            />
        </Grid>

        <Grid item xs={12} md={4}>
            <CardAtendimentosComprados
              title='Atendimentos Comprados'
              qtdAtendimentos={pacotedados.contratadas}
              tempoAtendimento={pacotedados.Duracao}
              tipoconversa={pacotedados.tipoconversa}
            />
        </Grid>

        <Grid item xs={12} md={4}>
            <CardAtendimentosUtilizados
              title='Atendimentos Utilizados'
              qtdAtendimentos={pacotedados.usados}
              tipoconversa={pacotedados.tipoconversa}
              tempoAtendimento={pacotedados.Duracao}
            />
        </Grid>

          

         


            <Grid item xs={12} md={6} lg={4}>
              <AnalyticsCurrentVisits
                title="Total (Ativo/Inativo)"
                chart={{
                  series: [
                    { label: 'Ativos', value: pacotedados.contratadas - pacotedados.usados},
                    { label: 'Usado', value: pacotedados.usados },
                  ],
                  colors: [
                    theme.palette.primary.main,
                    theme.palette.warning.main,
                    theme.palette.error.main,
                    theme.palette.info.main,
                  ],
                }}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={8}>
                {pacotedados.status !== 'Pendente'? ( <AtendimentosStepper atendente={pacotedados.atendente} pacotedados={pacotedados}/>): ('')}

            </Grid>
            
            { /* <Grid item xs={12} md={12} lg={12}>
                <ListagemPacotes 
                title="Best Salesman"
                tableData={_ecommerceBestSalesman}
                tableLabels={[
                    { id: 'seller', label: 'Seller' },
                    { id: 'product', label: 'Product' },
                    { id: 'country', label: 'Country', align: 'center' },
                    { id: 'total', label: 'Total' },
                    { id: 'rank', label: 'Rank', align: 'right' },
                ]}
                />
              </Grid> */ }

             { /* <Grid item xs={12} md={8}>
            <AppWelcome
              title={`Congratulations! \n User`}
              description="Best seller of the month You have done 57.6% more sales today."
              img={
                <MotivationIllustration
                  sx={{
                    p: 3,
                    width: 360,
                    margin: { xs: 'auto', md: 'inherit' },
                  }}
                />
              }
              action={<Button variant="contained">Go Now</Button>}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <EcommerceNewProducts list={_ecommerceNewProducts} />
          </Grid>
            */ }
        </Grid>

        

        </>
    )
}
