import {useState, useEffect, useCallback} from 'react';
import { ApexOptions } from 'apexcharts';
// @mui
import { Grid,alpha, Paper, Box, Card, Typography, Stack, CardProps, Chip, Rating, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
// components
import { CustomAvatar } from 'src/components/custom-avatar';
import { getCoresTemplate } from "src/utils/coresTemplate";
import {useNavigate} from "react-router-dom";
import Iconify from '../../../../components/iconify';
import Chart, { useChart } from '../../../../components/chart';
import avatarImg from '../img/avatar_2.jpg'

import infoUser from '../img/user.png';

import './ComponentePacotesCards.css'
import {PATH_DASHBOARD} from "../../../../routes/paths";

// ----------------------------------------------------------------------


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

interface Props extends CardProps {
  title: string;
    estrelas: any;
    atendente: any;

}

export default function CardInfoAtendente({
  title,
  estrelas,
  atendente,
  sx,
  ...other
}: Props) {

const [tipoAtendente, setTipoAtendente] = useState(1); // Estado inicial definido como '1' > Anjo - Podendo ser (1 > Anjo , 2 > Psicólogo, 3 > Psicanalista, 4 > Terapeuta , 5 > Coach)
const [value, setValue] = useState<number | null>(4);
    const navigate = useNavigate();
    useEffect(() => {

        setValue(estrelas);

    }, [estrelas, setValue]);
    const irperfil = () => {
        navigate(PATH_DASHBOARD.verperfil(atendente.id));
    };

  return (
    <Card sx={{ display: 'flex', alignItems: 'center', p: 3, ...sx }} className='CardInfoAtendente' {...other}>
      <Box sx={{ flexGrow: 1 }}>
        

        
        <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        >
          <Grid item xs={8}>
            <Item sx={{display: 'flex', background: 'transparent', color: '#fff'}}>
            <CustomAvatar
            src={atendente.avatar}
            name={atendente.avatar}
            alt={atendente.nome}
            sx={{mr: 1.5, width: '40px', height:'40px'}}
            />

            <Grid sx={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}}>

                <Grid sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', mb: 1}}>
                  <Typography variant="subtitle1" sx={{p: 0, m: 0}} paragraph>
                    {title}
                  </Typography>
                  <Chip className={getCoresTemplate(atendente.tipoAtendente).chipListaAtendentes} label={getCoresTemplate(atendente.tipoAtendente).nomeTipoAtendente} sx={{width: 'fit-content', px: 1, ml: 1}} variant='outlined' size='small'/>
                </Grid>

                <Grid sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                    <Rating name="read-only" value={value} readOnly size="small" />
                    <Typography variant='subtitle2' sx={{ml: 1}}>{estrelas}/5</Typography>
                </Grid>
                
                <Button onClick={irperfil} variant='text' sx={{p: 0, mt: 1, width: 'fit-content', color: '#fff', borderColor: '#fff'}}>Acessar Perfil <Iconify icon='eva:chevron-right-fill' sx={{ml: 1}} /> </Button>
            </Grid>
            </Item>
          </Grid>
          <Grid item xs={4} sx={{background: 'transparent'}}>
            <Item sx={{background: 'transparent'}}>
              <img src={infoUser} alt='Informações atendentes' width={80} className='imgCardDetalhesPacotes' />
            </Item>
          </Grid>

            


            
        </Grid>
            
        

        
     
        
      </Box>

    </Card>
  );
}
