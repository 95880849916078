import {useState, useEffect, useCallback, useMemo} from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import {
    Box,
    Stack,
    Drawer,
    IconButton, Button,
    IconButtonProps,
    Typography, Grid, Paper, FilledInput, Dialog, DialogContent,DialogTitle,DialogContentText,DialogActions,
    Tooltip,
    Divider,
    List, Pagination, FormControl, InputLabel, OutlinedInput, InputAdornment,
    MenuItem, Select, Alert
} from '@mui/material';

import  { SelectChangeEvent } from '@mui/material/Select';
// hooks
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import {useForm} from "react-hook-form";
import {LoadingButton} from "@mui/lab";
import ReactFlagsSelect from "react-flags-select";
import {Helmet} from "react-helmet-async";
import useResponsive from '../../../hooks/useResponsive';
import './Cadastro.css';

// utils
import axios from '../../../utils/axios';
// routes
import {PATH_AUTH, PATH_DASHBOARD} from '../../../routes/paths';
// @types
import { IChatContact, IChatConversationsState } from '../../../@types/chat';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import FormProvider, { RHFTextField, RHFSelect } from '../../../components/hook-form';
import {useAuthContext} from "../../../auth/useAuthContext";
import RHFnascimento from "../../../components/hook-form/RHFnascimento";
import RHFtelefone from "../../../components/hook-form/RHFtelefone";
import {RHFSelectBandeiras} from "../../../components/hook-form/RHFSelectBandeiras";
import ValidarWhatsapp from './componentesCadastro/ValidarWhatsapp';
import {criarevento} from "../../../utils/analytics ";
import Logo from "../../../components/logo";




//

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
// ----------------------------------------------------------------------

const logo = "https://www.eyhe.com.br/landing-page-new/image/logo_azul.png";


type FormValuesProps = {
    nome: any ;
    email: any ;
    NumeroWhatsApp: any;
    CodigoPais:  any;
    ondeEcontrou: any;
    DataNascimento: any;
    afterSubmit?: string;
};


type Props = {
    user: any;
};




export default function Cadastrogoogleform({user}:Props) {
    const navigate = useNavigate();
    const [cadastroEmail, setCadastroEmail] = useState(false);
    const [UrlGoogle, setUrlGoogle] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const { logout,googlefinalizar } = useAuthContext();
    const [openDialog, setOpenDialog] = useState(false);

    const [conversion, setconversion] = useState(false);
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };




    const irCadastroEmail = () => {
        setCadastroEmail(true);
    }

    const voltarCadastroEmail = () => {
        logout();
        navigate(PATH_AUTH.login,{replace:true});

    }

    const [pais, setPais] = useState('');

    const handleChangePais = (event: SelectChangeEvent) => {
        setPais(event.target.value as string);
    };

    const phoneRegExp = /^\([1-9]{2}\) (?:[2-8]|9[0-9])[0-9]{3}-[0-9]{4}$/
    const date:any = new Date();
    const LoginSchema = Yup.object().shape({
        nome: Yup.string().required('Nome é obrigatorio'),
        CodigoPais: Yup.string().required('Pais é obrigatorio'),
        DataNascimento: Yup.date().max(`${date.getFullYear() - 18}-${ date.getMonth() +1    }-${ date.getDate() }` , 'Proibido para menores de 18 anos.').required('Nascimento é obrigatorio'),
        ondeEcontrou: Yup.string().required('Onde encontrou é obrigatorio'),
        NumeroWhatsApp: Yup.string().required('WhatsApp é obrigatorio').matches(phoneRegExp, 'Por favor digite um numero de telefone válido.'),
    });




    const defaultValues = useMemo(
        () => ({
            nome: user?.nome || '' ,
            email: user?.email || '',
            NumeroWhatsApp: user?.NumeroWhatsApp || '',
            CodigoPais:  'BR',
            ondeEcontrou: user?.ondeEcontrou || '',
            DataNascimento: user?.DataNascimento || '',
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [user]
    );






    const [ondeEncontrou, setOndeEncontrou] = useState('');

    const handleChangeOndeEncontrou = (event: SelectChangeEvent) => {
        setOndeEncontrou(event.target.value as string);
    };

    const methods = useForm<FormValuesProps>({
        resolver: yupResolver(LoginSchema),
        defaultValues,
    });
    const {
        reset,
        setError,
        handleSubmit,
        watch,
        control,
        setValue,
        formState: { errors, isSubmitting, isSubmitSuccessful },
        getValues
    } = methods;


    useEffect(() => {
            reset(defaultValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ user]);




    const [open, setOpen] = useState(false);

    const onSubmit = async (data: FormValuesProps) => {


        const tipoAtendente = localStorage.getItem('tipoAtendentecadastro');


        const dadoscerto =  Object.assign(data, {tipoAtendente});



        try {
           const response = await axios.post('/areadoheroi/googlefinalizar',dadoscerto);
         //  handleOpenDialog();
            if (googlefinalizar) {
                await googlefinalizar(dadoscerto);
                criarevento('cadastro_verificado');

                setconversion(true);

                setTimeout(()=>{
                    navigate(PATH_DASHBOARD.dashfirst);
                },3000);
            }



        } catch (error) {
            console.error(error);
            setOpen(true)
            setError('afterSubmit', {
                ...error,
                message: error.message || error,
            });
        }
    };



    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };

    return (
        <>
                <div className='MainCadastroGoogle'>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>


                        <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        >
                        <DialogTitle id="alert-dialog-title">
                        <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        >
                            <Iconify className='NotificaErrorIcon' icon='eva:alert-triangle-outline' color='error' sx={{mr: 1}} />
                            <Typography color='error' variant="h6" > Erro ao criar conta </Typography>
                            
                        </Grid>
                        
                        </DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            
                            {errors.afterSubmit?.message}
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={handleClose} variant='contained' autoFocus>
                            Fechar
                        </Button>
                        </DialogActions>
                        </Dialog>


                <Grid container className='ItensMainCadastroGoogle'>
                    <Grid item xs={12}>
                        <Item className='TopoCadastre'>
                                <Button variant="text" onClick={voltarCadastroEmail} >
                                    <Logo/>
                                </Button >
                            <Typography className='TituloCadastre'>Termine o seu cadastro. Preencha os dados abaixo</Typography>
                        </Item>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Item>
                            <FormControl fullWidth sx={{ m: 1 }} variant="filled">
                                <RHFTextField name="nome" id="filled-adornment-amount"  label="Nome" />
                            </FormControl>
                        </Item>


                        <Item>
                            <FormControl fullWidth sx={{ m: 1 }} variant="filled">

                           
                            <RHFSelectBandeiras name="CodigoPais" />


                                
                            </FormControl>
                        </Item>

                        <Item>
                            <FormControl fullWidth sx={{ m: 1 }} variant="filled">
                                <RHFtelefone name="NumeroWhatsApp"  label="Whatsapp"  />
                            </FormControl>
                        </Item>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Item>
                            <FormControl fullWidth sx={{ m: 1 }} variant="filled">
                                <RHFTextField name="email" disabled id="filled-adornment-amount" label="E-mail"  />
                            </FormControl>
                        </Item>

                        <Item>
                            <FormControl fullWidth sx={{ m: 1 }} variant="filled">

                                <RHFSelect native name="ondeEcontrou" sx={{width: '100%'}} label="Onde Encontrou"  >
                                                <option value="" />
                                                <option  value='Facebook'> Facebook</option>
                                                <option  value='Instagram'> Instagram</option>
                                                <option  value='Google'> Google</option>
                                                <option  value='Publicidade'> Publicidade</option>
                                </RHFSelect>

                            </FormControl>
                        </Item>

                        <Item>
                            <FormControl fullWidth sx={{ m: 1 }} variant="filled">
                                <RHFnascimento name="DataNascimento" id="filled-adornment-amount" label="Data de Nascimento"   />
                            </FormControl>
                        </Item>

                    </Grid>

                    <Grid item xs={12} md={12}>
                        <Item className='BotaoCadastreSe'>
                            <LoadingButton
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                loading={isSubmitSuccessful || isSubmitting}
                            >
                               Terminar Cadastro
                            </LoadingButton>

                            <Button fullWidth onClick={voltarCadastroEmail} variant='outlined' sx={{my: 1.5}} size="large">
                                    Voltar
                            </Button>
                        </Item>

                        <Item>
                        {/* <Button variant="outlined" onClick={handleOpenDialog}> */}
                        {/*    Abrir Diálogo */}
                        {/* </Button> */}
                        <ValidarWhatsapp open={openDialog} dados={getValues} handleClose={handleCloseDialog} />
                        </Item>
                    </Grid>

                </Grid>

               </FormProvider>
            </div>
            {conversion ?(<Helmet>
                <script>{`gtag('event', 'conversion', {'send_to': 'AW-696299330/jJn-CNr86pIZEMLegswC'});`}</script>
            </Helmet>): ('')}

        </>
    )
}
