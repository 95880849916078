
import { useState, useRef } from 'react';
// @mui
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { alpha, useTheme } from '@mui/material/styles';
import { Box, Card, Paper,FormControl,OutlinedInput,InputAdornment,ToggleButtonGroup,ToggleButton, ButtonGroup, InputLabel,MenuItem,Select,Rating,Button, Typography,TextField, CardContent,SelectChangeEvent, Divider, IconButton } from '@mui/material';
import { CustomAvatar } from "src/components/custom-avatar";
import Iconify from 'src/components/iconify/Iconify';
import {LoadingButton} from "@mui/lab";
import ProfilePicture from '../img/profile_picture.png';
import axios from "../../../../utils/axios";
import {useSnackbar} from "../../../../components/snackbar";
import useCopyToClipboard from '../../../../hooks/useCopyToClipboard';

import './Contribuicao.css';

// utils

// components

// ----------------------------------------------------------------------



export default function Contribuicao() {

    const { enqueueSnackbar } = useSnackbar();
    const { copy,copyToClipboard } = useCopyToClipboard();
const [valor, setvalor] = useState<any>('10');
const [isSubmitSuccessful, setisSubmitSuccessful] = useState<any>(false);
const [dadoscodigo, setdadoscodigo] = useState<any>('vazio');


  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newvalor: string,
  ) => {
    setvalor(newvalor);
  };

  const pixcopiaecola = async () => {
      setisSubmitSuccessful(true);
      try {
          const response = await axios.post('/areadoheroi/pixcopiaecola',{
              Valor:valor
          });

          const {dadosresponse} = response.data;

          setdadoscodigo(dadosresponse.codigo)
          enqueueSnackbar(`Gerado com sucesso!`, {variant: 'success'});
          setisSubmitSuccessful(false);

      } catch (error) {
          setisSubmitSuccessful(false);
          enqueueSnackbar(`${error.message}`, {variant: 'error'});
      }
  }




    // This is the function we wrote earlier
  const copyTextToClipboard = async () => {
      

      // if ('clipboard' in navigator) {
      //     return navigator.clipboard.writeText(dadoscodigo);
      // }

      enqueueSnackbar(`Copiado com sucesso!`, {variant: 'success'});
          // return document.execCommand('copy', true, dadoscodigo);
      


    }







  return (
    <>
        <div className='CardContribuaDashboard'>


            <Typography className='TituloCardAzulDashInicialContribua'>Sua contribuição salva vidas</Typography>
            <Typography className='SubtituloCardAzulDashInicialContribua'>Contribua para que mais pessoas possam ser
                acolhidas gratuitamente. Quando você contribui, vidas são salvas.</Typography>

            <Typography className='SelecioneoValorContribua'>Selecione o valor:</Typography>


            <ToggleButtonGroup
                color="primary"
                value={valor}
                exclusive
                onChange={handleChange}
                aria-label="Valor"
                className='OpcoesValorContribua'
            >
                <ToggleButton className='OpcaoValorContribua' value="10">R$10</ToggleButton>
                <ToggleButton className='OpcaoValorContribua' value="20">R$20</ToggleButton>
                <ToggleButton className='OpcaoValorContribua' value="30">R$30</ToggleButton>
            </ToggleButtonGroup>


            <FormControl fullWidth sx={{m: 1}}>
                <TextField
                    className='OutroValorInputContribuaTitulo OutroValorInputContribua'
                    id="outlined-uncontrolled"
                    label="Outro valor"
                    // defaultValue={valor}
                    value={valor === null ? ('') : (valor)}
                    onChange={(event) => setvalor(event.target.value)}
                    fullWidth
                />
            </FormControl>




            <LoadingButton
                fullWidth
                variant="contained"
                loading={isSubmitSuccessful}
                onClick={pixcopiaecola}

            >
                Gerar Pix
            </LoadingButton>

            { dadoscodigo === 'vazio' ? ('') :(
                <CopyToClipboard text={dadoscodigo}
            onCopy={copyTextToClipboard}>
                    <LoadingButton
                        fullWidth
                        variant="contained"
                        color='error'
                        sx={{mt:2}}

                    >
                       Copiar
                    </LoadingButton>
        </CopyToClipboard>
            )}

        </div>

    </>
  );
}
