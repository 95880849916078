import { Helmet } from 'react-helmet-async';
// sections
import Quiz from "../sections/@dashboard/Quiz/Quiz";



export default function QuizPage() {
  return (
    <>
      <Helmet>
        <title>Quiz Herói - Eyhe Maior Comunidade de Suporte Emocional do Brasil</title>
      </Helmet>

      <Quiz  />
    </>
  );
}
