import {Divider, Tabs,Dialog , Input,InputLabel,Slide,InputAdornment,Container, Radio ,Card, DialogContent, DialogActions , RadioGroup, FormControlLabel,FormLabel, FormControl,TextField, Tab, Button, List, MenuItem, ToggleButton, ToggleButtonGroup, Paper, Box, Grid, Typography, Icon, IconButton} from "@mui/material";
import React,{useEffect,forwardRef ,ReactElement , useState, useCallback} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import { TransitionProps } from '@mui/material/transitions';
// @mui
import { styled } from '@mui/material/styles';
import Iconify from "src/components/iconify/Iconify";
import { IMaskInput } from 'react-imask';
import {LoadingButton} from "@mui/lab";
import { RHFTextField } from "src/components/hook-form";
import {useSettingsContext} from '../../../components/settings';

import "./Pagamento.css";
import axios from '../../../utils/axios';
import plan1 from './img/plan_1.webp';
import plan2 from './img/plan_2.webp';
import plan3 from './img/plan_3.webp';
import contribuirImg from './img/contribuirImg.webp';
import qrCode from './img/qrCode.webp';
import cartaoCredito from './img/cartaoCredito.webp';
import boletoBancario from './img/boletoBancario.webp';
import boletoGerado from './img/boletoGerado.webp';
import CPF from './img/cpf.png';
import DadosCartao from './img/dadosCartao.png'
import cupomImagem from './img/cupomDesconto.png'

import semPacotes from './img/pacotes-vazio.png'

import pagamentoCelular from './img/smartphone_payment.png'

import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
import TipoConversaSessaoUnica from "./PagamantoComponetes/TipoConversaSessaoUnica";
import TipoSaldo from "./PagamantoComponetes/TipoSaldo";
import {useAuthContext} from "../../../auth/useAuthContext";
import useCopyToClipboard from '../../../hooks/useCopyToClipboard';
import { useSnackbar } from '../../../components/snackbar';
import useIugu from "./iugu";
import {PATH_DASHBOARD} from "../../../routes/paths";
import useResponsive from "../../../hooks/useResponsive";

// routes

import confirmaAgendamento from './img/confirmaAgendamento.svg'
import {criarevento} from "../../../utils/analytics ";
import {fCurrency} from "../../../utils/formatNumber";
import CardsPlanosPacotes from "./PagamantoComponetes/CardsPlanosPacotes";


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  const Transition = forwardRef<unknown, TransitionProps>(
    ({ children, ...props }, ref) => (
      <Slide direction="up" ref={ref} {...props}>
        {children as ReactElement}
      </Slide>
    )
  );

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

 

// ----------------------------------------------------------------------


export default function Pagamento() {

    const [abrirModalCupom, setModalCupom] = useState(false);
    const [cupom, setCupom] = useState('');
    const [valorsemaplicar, setvalorsemaplicar] = useState('');
    const [valordescontoaplicado, setvalordescontoaplicado] = useState<any>(0.00);
    const [isSubmitSuccessful, setisSubmitSuccessful] = useState(false);

    const handleClickModalCupom = () => {
        setModalCupom(true);
      };
    const mudarcupom = (dados:any) => {
        setCupom(dados.target.value);
      };
    
      const handleCloseModalCupom = () => {
        setModalCupom(false);
      };

    const isDesktop = useResponsive('up', 'lg');
        const navigate = useNavigate();
        const {idAtendente = ''} = useParams();
        const [value, setValue] = useState(0);
        const [valorlinhadigitavel, setvalorlinhadigitavel] = useState('0fdfdfdfdf');
        const [valorqrcode, setvalorqrcode] = useState('0fdfdfdfdf');
        const [cpf, setcpf] = useState<any>(null);
        const { user,getuser } = useAuthContext();
        const [pagamentoStatus, setPagamentoStatus] = React.useState('Pagamento');
        const [tiposConversas, settiposConversas] = useState<any[]>([]);
        const [tiposConversaselect, settiposConversaselect] = useState<any>({});

    const [Agendamento, setAgendamento] = useState<any>(false);

    const { enqueueSnackbar } = useSnackbar();
    const { copy } = useCopyToClipboard();
console.log(Agendamento);

    useEffect(() => {

        getuser();

    }, [getuser]);



    const getmessage = useCallback(async () => {
        try {
            const response = await axios.post('/areadoheroi/gettconversa',{idAtendente});

            const {tiposconversa} = response.data;
          console.log(tiposconversa);

            settiposConversas(tiposconversa);

        } catch (error) {
            enqueueSnackbar(`${ error.message}`, { variant: 'error' });
        }
    },[enqueueSnackbar, idAtendente]);





    useEffect(() => {

        getmessage();

    }, [getmessage]);


    const [pacotesdados, setpacotes] = useState<any>([]);
    const [corpacotes, setcorpacotes] = useState<any>(['primary','info','success','warning','error']);

    const getpacotes = useCallback(async () => {
        try {
            const response = await axios.post('/areadoheroi/getpacotes',{idAtendente});

            const {pacotes} = response.data;


            setpacotes(pacotes);

        } catch (error) {
            enqueueSnackbar(`${ error.message}`, { variant: 'error' });
        }
    },[enqueueSnackbar, idAtendente]);





    useEffect(() => {

        getpacotes();

    }, [getpacotes]);





    useEffect(() => {
        criarevento('adicionar_carrinho');

    }, []);





    useEffect(() => {

   if(sessionStorage.getItem('dadosAgendamento')) {
       const storage = sessionStorage.getItem('dadosAgendamento');

       // @ts-ignore
       const dados = JSON.parse(storage);


       if(dados.id.toString() === idAtendente.toString()){

           setAgendamento(true);
           const novaAtualizacao = [{ tipo:'Agendamento',data:dados.selectedHorario}];
           settiposConversaselect((state: any) => ({ ...state, ...novaAtualizacao[0] }));
       }else{
           sessionStorage.removeItem('dadosAgendamento');
       }
       
   }
        return sessionStorage.removeItem('dadosAgendamento');

    }, [getmessage, idAtendente]);




    const [ispacote, setispacote] = useState<any>(false);

    const alternarStatusPagamento = async  (dados:any) => {
        criarevento('checkout_iniciado');



            const response = await axios.post('/areadoheroi/moverledcarrinho',{idAtendente});



        setispacote(false);
        setCupom('');
        if(dados.id === 1){
            const novaAtualizacao = { Tipopag: 'Gratis', idAtendente, id:dados.id};

                handleClickOpen('Gratis',novaAtualizacao);



        }else{
            setPagamentoStatus('MetodoPagamento');
            const novaAtualizacao = [dados];
            console.log(novaAtualizacao);
            settiposConversaselect((state: any) => ({ ...state, ...novaAtualizacao[0] }));

        }


    };





    const alternartipoPacotes = async  (dados:any) => {
        setispacote(true);
        setvalorsemaplicar('');
        setCupom('');

            setPagamentoStatus('MetodoPagamento');
            const novaAtualizacao = [dados];
        console.log(novaAtualizacao);
            settiposConversaselect((state: any) => ({ ...state, ...novaAtualizacao[0] }));

    };




    const contribuir = () => {
        setPagamentoStatus('MetodoPagamento');
        settiposConversaselect({Valor:alignment,tipo:'Contribuicao',Nome:'Contribuicao'});
        setvalorsemaplicar('');
    };

    const copiar = () => {
        copy(valorlinhadigitavel);
        enqueueSnackbar('Copiado!');
    };

    const voltar = () => {
        setPagamentoStatus('Pagamento');
        settiposConversaselect({});
    };

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };

    const handleButtonClick = () => {
        setValue(0); // Altera o valor para 0, que é o índice da tab "Sessão única"
    };
    
  
    const popular = (
        <div className="PlanoPopularPagamentos">
                POPULAR
            </div>
    );

    const [alignment, setAlignment] = React.useState('20');



    const handleChange_ValorContribuicao = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string,
    ) => {
        setAlignment(newAlignment);
    };



    const handleChange_ValorContribuicao_input = (newAlignment:any) => {
        setAlignment(newAlignment);
    }

    const handleChangetipopagamento = (event: React.ChangeEvent<HTMLInputElement>) => {

        const novaAtualizacao = [{ Tipopag: event.target.value, idAtendente}];
        settiposConversaselect((state: any) => ({ ...state, ...novaAtualizacao[0] }));

        

    };
    const mudarcpf = (event: any) => {

        const novaAtualizacao = [{ cpf: event}];
        settiposConversaselect((state: any) => ({ ...state, ...novaAtualizacao[0] }));
    };

    const voltarDash = () => {
        navigate(PATH_DASHBOARD.dashfirst,{replace:true});
    }

    const [openSucessoAgendamento, setOpenSucessoAgendamento] = useState(false);

    const handleClickOpenSucessoAgendamento = () => {
        setOpenSucessoAgendamento(true);
    };

    const handleCloseSucessoAgendamento = () => {
        setOpenSucessoAgendamento(false);
    };


    



    const [selectedValue, setSelectedValue] = React.useState('Saldo');

    const handleChangePagamento = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedValue(event.target.value);
    };

    const [open, setOpen] = React.useState(false);
    const [Openboleto, setOpenboleto] = React.useState(false);
    const [dadosrespostas, setdadosrespostas] = React.useState<any>();
    const [openCPF, setOpenCPF] = React.useState(false);

    const handleClickOpenCPF = () => {
        setbotaodesabilitado(false);
        if(user?.CpfOrPassaporte){
            Abrirpagamento();
        }else{
            setOpenCPF(true);
            setbotaodesabilitado(true);
        }

    }
    const [numerocartao, setnumerocartao] = React.useState<any>('');
    const [nomecartao, setnomecartao] = React.useState<any>('');
    const [validadecartao, setvalidadecartao] = React.useState<any>('');
    const [codigocartao, setcodigocartao] = React.useState<any>('');
    const [parcelas, setparcelas] = React.useState<any>('');
    const [botaodesabilitado, setbotaodesabilitado] = React.useState<any>(true);


    useEffect(() => {

        const novaAtualizacao = [{
            codigocartao,
            validadecartao,
            nomecartao,
            numerocartao
        }];
        settiposConversaselect((state: any) => ({...state, ...novaAtualizacao[0]}));

    }, [codigocartao,validadecartao,nomecartao,numerocartao]);

    const Iugu = useIugu("E56B8B904FE844578A204610978C0E6E");


        async function salvarDadosCartao(event:any) {

        event.preventDefault();
        let validado = true;
        if(!numerocartao){
            enqueueSnackbar('Por favor, escreva o número do cartão', { variant: 'error' });
            validado = false;
        }
        if(!validadecartao){
            enqueueSnackbar('Por favor, escreva o nome impresso no cartão', { variant: 'error' });
            validado = false;
        }
        if(!nomecartao){
            enqueueSnackbar('Por favor, escreva a data de validade do cartão', { variant: 'error' });
            validado = false;
        }
        if(!codigocartao){
            enqueueSnackbar('Por favor, escreva o código de segurança do cartão', { variant: 'error' });
            validado = false;
        }




        if(validado) {
            setbotaodesabilitado(false);


            const response = await Iugu.createPaymentToken(event.target);

            console.log(response);
            if(response.errors){

                    if(response.errors.expiration){
                        enqueueSnackbar('Erro na data de validade do cartão', { variant: 'error' });
                    }
                    if(response.errors.last_name){
                        enqueueSnackbar('Erro no nome do cartão', { variant: 'error' });
                    }
                    if(response.errors.number){
                        enqueueSnackbar('Erro no número do cartão', { variant: 'error' });
                    }
                    if(response.errors.verification_value){
                        enqueueSnackbar('Erro no código do cartão', { variant: 'error' });
                    }

                setbotaodesabilitado(true);

            }else{
                handleClickOpen(response.id);
            }




        }


    }

    const handleClickCloseCPF = () => {
        setOpenCPF(false);
    }

    const Abrirpagamento = () => {

        let validado = true;
        if(!tiposConversaselect.Tipopag){
            enqueueSnackbar('Por favor, selecione um tipo de pagamento.', { variant: 'error' });
            validado = false;
            setbotaodesabilitado(true);
        }

        if(validado){
            if(tiposConversaselect.Tipopag === 'CartaoCredito' && numerocartao === ''){
                setPagamentoStatus('DadosCartao');
                setbotaodesabilitado(true);
            }else{

                handleClickOpen();
            }

        }

    }
    const [enviado, setenviado] = useState<any>(false);

    const handleClickOpen = async (idcartao:any = '', dados:any = {}) => {
        setbotaodesabilitado(false);
        setenviado(true);

        if(cpf) {
            const novaAtualizacao = [{ cpf}];
            settiposConversaselect((state: any) => ({ ...state, ...novaAtualizacao[0] }));
        }


            const novaAtualizacao = [dados];
            settiposConversaselect((state: any) => ({ ...state, ...novaAtualizacao[0] }));




            try {
            const response = await axios.post('/areadoheroi/realizarpagamento',{
                tiposConversaselect: idcartao === 'Gratis'? dados : tiposConversaselect,
                idcartao,
                parcelas,
                cupom,
                valorsemaplicar
            });

        
            const {dadosresponse} = response.data;

                setdadosrespostas(dadosresponse);

                // if (tiposConversaselect?.id === 2) {
                //     criarevento('escolheu_atendimento_basico');
                // }
                // if (tiposConversaselect?.id === 3) {
                //     criarevento('escolheu_atendimento_essencial');
                // }
                // if (tiposConversaselect?.id === 4) {
                //     criarevento('escolheu_atendimento_premium');
                // }
                // if (tiposConversaselect?.id === 5) {
                //     criarevento('escolheu_atendimento_profi_video');
                // }
                // if (tiposConversaselect?.id === 6) {
                //     criarevento('escolheu_atendimento_profi_texto');
                // }

                if (dadosresponse?.Tipopag === 'pix') {
                    setOpen(true);
                    setvalorlinhadigitavel(dadosresponse?.codigo);
                    setvalorqrcode(dadosresponse?.qrcode);

                }

                if (dadosresponse?.Tipopag === 'BoletoBancario') {
                    setvalorlinhadigitavel(dadosresponse?.codigo);
                    setOpenboleto(true);

                }

                if (dadosresponse?.Tipopag === 'CartaoCredito') {
                    if(Agendamento === false){
                        navigate(PATH_DASHBOARD.salaespera(dadosresponse.idconversa),{replace:true});
                    }else{

                        setOpenSucessoAgendamento(true)

                    }
                    criarevento('pagamento_confirmado');
                }

                if (dadosresponse?.Tipopag === 'SaldoemConta') {
                    // criarevento('pagamento_confirmado');
                    if(Agendamento === false){
                        navigate(PATH_DASHBOARD.salaespera(dadosresponse.idconversa),{replace:true});
                    }else{
                        setOpenSucessoAgendamento(true)
                    }

                }

                if (dadosresponse?.Tipopag === 'Gratis') {
                    // criarevento('escolheu_atendimento_gratis');
                    navigate(PATH_DASHBOARD.salaespera(dadosresponse.idconversa),{replace:true});
                }

                setbotaodesabilitado(true);
                setenviado(false);
                setOpenCPF(false);

        } catch (error) {
            enqueueSnackbar(`${ error.message}`, { variant: 'error' });


            if(error.message === 'Erro ao gerar codigo Pix, Confira seu CPF'){
                setOpenCPF(true);
            }else{
                setOpenCPF(false);
            }


           console.error(error);
                setbotaodesabilitado(true);
                setenviado(false);
        }

        



    };

    const verificarcupom = useCallback(async () => {
        setisSubmitSuccessful(true);


        if(valorsemaplicar !== ''){
            enqueueSnackbar(`Cumpom já aplicado. Não é permitido utilizar mais de 1 cupom`, { variant: 'error' });
            setisSubmitSuccessful(false);
        }else{



        try {
            const response = await axios.post('/areadoheroi/verificarcupom',{idAtendente,cupom,tiposConversaselect});

            const {iscupom} = response.data;

            enqueueSnackbar(`Cupom aplicado com sucesso!`, { variant: 'success' });
            setisSubmitSuccessful(false);
            setvalorsemaplicar(tiposConversaselect.Valor);
            setvalordescontoaplicado(tiposConversaselect.Valor - iscupom.Valor);
                const novaAtualizacao = [{ Valor:iscupom.Valor}];
                settiposConversaselect((state: any) => ({ ...state, ...novaAtualizacao[0] }));
            setModalCupom(false);

        } catch (error) {
            enqueueSnackbar(`${ error.message}`, { variant: 'error' });
            setisSubmitSuccessful(false);
            setvalorsemaplicar('');
            setCupom('');
        }
        }
    },[cupom, enqueueSnackbar, idAtendente, tiposConversaselect, valorsemaplicar]);





    const handleClose = () => {
        setOpen(false);
        setOpenboleto(false);
    };

    const BoletoGerado = (
        <Dialog
        fullScreen
        open={Openboleto}
        onClose={handleClose}
        >
            {isDesktop ? (
                <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{height: '100vh'}}
                >
                    <Grid
                    container
                    direction="column"
                    justifyContent="space-between"
                    alignItems="center"
                    >
                        <div className="CardPixDesktop">
                            <Grid container justifyContent="space-between" className="TopoDialogNotificacaoPagamentoIcons">
                                
                                <IconButton onClick={handleClose}>
                                    <Iconify icon="eva:arrow-ios-back-outline" />
                                </IconButton>
                                <IconButton onClick={handleClose}>
                                    <Iconify icon="eva:close-fill" />
                                </IconButton>
                                
                            </Grid>

                            <Grid item container justifyContent="flex-start" alignItems="center">
                                <Item>
                                    <Typography className="TextoTituloDialogNotificacaoPagamento">Seu boleto foi gerado</Typography>
                                    <Typography className="TextoSubTituloDialogNotificacaoPagamento">
                                        Aponte o celular para o QRCode ou copie a linha digitável e faça o pagamento através do app do seu banco.
                                    </Typography>

                                    <TextField
                                        id="boletoCodigo"
                                        variant="outlined"
                                        defaultValue={valorlinhadigitavel}
                                        fullWidth
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        />
                                        
                                    
                                        <Grid container spacing={2}>
                                            <Grid item xs={8}>
                                                <Item><Button variant="contained" sx={{py: 1.5, mt: 1.5}} onClick={copiar} fullWidth>Copiar código do boleto</Button></Item>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Item><Button variant="outlined" color="warning" sx={{py: 1.5, mt: 1.5}} onClick={handleClose} fullWidth>Voltar</Button></Item>
                                            </Grid>
                                            
                                        </Grid>

                                    
                                    
                                </Item>

                                <Item>
                                    <img alt="Boleto Gerado" width="300px" src={pagamentoCelular}/>
                                </Item>
                            </Grid>


                        </div>
                        </Grid>
                </Grid>
            ) :(
                <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="center"
                className="GridPagamentoBoletoGerado"
                >
                    <div className="ConteudoPagamentoPix">
                        <Grid item  className="TopoDialogNotificacaoPagamento">
                            <Item className="TopoDialogNotificacaoPagamentoIcons">
                                    <IconButton onClick={handleClose}>
                                        <Iconify icon="eva:arrow-ios-back-outline" />
                                    </IconButton>
                                    <IconButton onClick={handleClose}>
                                        <Iconify icon="eva:close-fill" />
                                    </IconButton>
        
        
                            </Item>
                        </Grid>
                            <Grid>
                        
                            <Item>
                                <img alt="Boleto Gerado" width="240px" src={pagamentoCelular}/>
                            </Item>
                    
                            <Item>
                                <Typography className="TextoTituloDialogNotificacaoPagamento">Seu boleto foi gerado</Typography>
                                <Typography className="TextoSubTituloDialogNotificacaoPagamento">Copie a linha digitável e faça o pagamento
                                    através do app do seu banco.</Typography>
                            </Item>
                        
                            <Item>
                                <TextField
                                id="boletoCodigo"
                                variant="outlined"
                                defaultValue={valorlinhadigitavel}
                                fullWidth
                                InputProps={{
                                    readOnly: true,
                                }}
                                />
        
                                <Button variant="contained" className="CopiarCodigoDialogNotificaPagamento" onClick={copiar} fullWidth>Copiar código do boleto</Button>
                                <Button variant="outlined" color="warning" className="CopiarCodigoDialogNotificaPagamento" onClick={handleClose} fullWidth>Voltar</Button>
                            </Item>
                        </Grid>
                    </div>
                </Grid>
            )}
            
      </Dialog>
    )

    const contribua = (
        <Grid container
                                    spacing={2}
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    className="ContribuicaoTabPagamento">
                                        <Grid item xs={12} md={5}>
                                            <Item><img alt="Contribuir" src={contribuirImg}/></Item>
                                        </Grid>
                                        <Grid item xs={12} md={7} >
                                            <Item className="ConteudoContribuicaoTabPagamento">
                                                <Typography className="TituloContribuirPagamento">Sua contribuição salva vidas</Typography>
                                                <Typography className="SubTituloContribuirPagamento">Contribua para que mais pessoas possam ser acolhidas gratuitamente. Quando você contribui, vidas são salvas.</Typography>
                                                <Typography className="SelecioneValorContribuirPagamento">Selecione o valor:</Typography>

                                                <ToggleButtonGroup
                                                color="primary"
                                                value={alignment}
                                                exclusive
                                                onChange={handleChange_ValorContribuicao}
                                                aria-label="Contribuição"
                                                >
                                                    <ToggleButton value="10">R$10</ToggleButton>
                                                    <ToggleButton value="20">R$20</ToggleButton>
                                                    <ToggleButton value="30">R$30</ToggleButton>
                                                </ToggleButtonGroup>

                                                <Typography className="SelecioneValorContribuirPagamento">Ou insira o valor desejado</Typography>

                                                <TextField
                                                label="Valor"
                                                id="filled-size-small"
                                                defaultValue={alignment}
                                                value={alignment}
                                                variant="filled"
                                                onChange={(e)=>{
                                                    handleChange_ValorContribuicao_input(e.target.value);
                                                }}
                                                size="small"
                                                />

                                                <Button variant="contained" onClick={contribuir} className="BotaoMetodoPagamentoContribuir" fullWidth>Método de Pagamento</Button>

                                            </Item>
                                        </Grid>
                                </Grid>
    )

    const PixGerado = (
        <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        >
            {isDesktop ? (
                <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{height: '100vh'}}
                >
                    <Grid
                    container
                    direction="column"
                    justifyContent="space-between"
                    alignItems="center"
                    >
                        <div className="CardPixDesktop">
                            <Grid container justifyContent="space-between" >
                                <Item className="TopoDialogNotificacaoPagamentoIcons">
                                        <IconButton onClick={handleClose}>
                                            <Iconify icon="eva:arrow-ios-back-outline" />
                                        </IconButton>
                                        <IconButton onClick={handleClose}>
                                            <Iconify icon="eva:close-fill" />
                                        </IconButton>
                                </Item>
                            </Grid>

                            <Grid item container justifyContent="center">
                                <Item>
                                    <img alt="Boleto Gerado" width="200px" src={valorqrcode}/>
                                </Item>
                        
                                <Item>
                                    <Typography className="TextoTituloDialogNotificacaoPagamento">Seu pix foi gerado</Typography>
                                    <Typography className="TextoSubTituloDialogNotificacaoPagamento">
                                        Aponte o celular para o QRCode ou copie a linha digitável e faça o pagamento através do app do seu banco.
                                    </Typography>

                                    <TextField
                                    id="pixCodigo"
                                    variant="outlined"
                                    defaultValue={valorlinhadigitavel}
                                    fullWidth
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    />

                                    
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <Item><Button variant="outlined" color="warning" sx={{py: 1.5, mt: 1.5}} onClick={handleClose} fullWidth>Voltar</Button></Item>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Item><Button variant="contained" sx={{py: 1.5, mt: 1.5}} onClick={copiar} fullWidth>Copiar Pix</Button></Item>
                                            </Grid>
                                        </Grid>
                                </Item>
                            </Grid>


                        </div>
                        </Grid>



                    
                
                </Grid>
            ) : (
                <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="center"
                className="GridPagamentoBoletoGerado"
                >
                    <div className="ConteudoPagamentoPix">
                    <Grid item  className="TopoDialogNotificacaoPagamento">
                        <Item className="TopoDialogNotificacaoPagamentoIcons">
                                <IconButton onClick={handleClose}>
                                    <Iconify icon="eva:arrow-ios-back-outline" />
                                </IconButton>
                                <IconButton onClick={handleClose}>
                                    <Iconify icon="eva:close-fill" />
                                </IconButton>
    
    
                        </Item>
                    </Grid>
                    <Grid item sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        {isDesktop ? (
                                <Item>
                                    <img alt="Boleto Gerado" width="200px" src={valorqrcode}/>
                                </Item>
    
                        ) : (
                            <Item>
                                <img alt="Boleto Gerado" width="200px" src={valorqrcode}/>
                            </Item>
                            )}
                    
                        <Item>
                            <Typography className="TextoTituloDialogNotificacaoPagamento">Seu pix foi gerado</Typography>
                            <Typography className="TextoSubTituloDialogNotificacaoPagamento">
                            Aponte o celular para o QRCode ou copie a linha digitável e faça o pagamento através do app do seu banco.
                            </Typography>
                        </Item>
                   
                        <Item>
                            <TextField
                            id="pixCodigo"
                            variant="outlined"
                            defaultValue={valorlinhadigitavel}
                            fullWidth
                            InputProps={{
                                readOnly: true,
                            }}
                             />
    
                            <Button variant="contained" className="CopiarCodigoDialogNotificaPagamento" onClick={copiar} fullWidth>Copiar Pix</Button>
                            <Button variant="outlined" color="warning" className="CopiarCodigoDialogNotificaPagamento" onClick={handleClose} fullWidth>Voltar</Button>
                        </Item>
                    </Grid>
                    </div>
                </Grid>
            )}
            
      </Dialog>
    )

    const InformarCPF = (
        <Dialog
                    fullScreen
                    open={openCPF}
                    onClose={handleClickCloseCPF}
                    >
                        <Grid
                        container
                        direction="column"
                        justifyContent="space-between"
                        alignItems="center"
                        className="GridPagamentoBoletoGerado"
                        >
                            <div className="ConteudoPagamentoPix">
                            <Grid item  className="TopoDialogNotificacaoPagamento">
                                <Item className="TopoDialogNotificacaoPagamentoIcons">
                                        <IconButton onClick={handleClickCloseCPF}>
                                            <Iconify icon="eva:arrow-ios-back-outline" />
                                        </IconButton>
                                        <IconButton onClick={handleClickCloseCPF}>
                                            <Iconify icon="eva:close-fill" />
                                        </IconButton>
            
            
                                </Item>
                            </Grid>
                            <Grid item>
                                <Item>
                                    <img alt="Boleto Gerado" width="300px" src={CPF}/>
                                </Item>
                            </Grid>
                            <Grid item>
                                <Item>
                                    <Typography className="TextoTituloDialogNotificacaoPagamento">Informe seu CPF</Typography>
                                    <Typography className="TextoSubTituloDialogNotificacaoPagamento">Por favor, insira seu CPF para dar sequência.</Typography>

                                    <IMaskInput
                                        type="text"
                                        mask="000.000.000-00"
                                        placeholder='CPF'
                                        label="CPF"
                                        className="inputCPFDialogPagamento"
                                        onAccept={ (valormascara, mask) => mudarcpf(valormascara)}
                                    />
                                </Item>
                            </Grid>
                            <Grid item>
                                <Item>

                                    <Button variant="contained" className="CopiarCodigoDialogNotificaPagamento" disabled={!botaodesabilitado} onClick={()=>{handleClickOpen('')}} fullWidth> <Iconify icon='eva:checkmark-circle-fill' /> Próximo</Button>
                                </Item>
                            </Grid>
                            </div>
                        </Grid>
                  </Dialog>
    )


    const irDash = async () => {
        navigate(PATH_DASHBOARD.dashfirst, { replace: true });
    };

    

    const irAtendentes = async () => {
        navigate(PATH_DASHBOARD.atendentes, { replace: true });
 
};

    return (
            <>

                {pagamentoStatus === "Pagamento" ? (
                    <div>
                        <div className="TopoMetodosdePagamento">
                                <IconButton onClick={irAtendentes}>
                                    <Iconify icon="eva:chevron-left-fill" className="IconBackTopoPagamento"/>
                                </IconButton>
                                <Typography className="TituloTopoPagamento">Pagamento </Typography>
                        </div>
                        

                            <Box sx={{ width: '100%' }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className="OpcoesTiposdePagamentoTopo">
                                    <Tab label="Sessão única" {...a11yProps(0)} />
                                    <Tab label="Pacotes" {...a11yProps(1)} />  
                                    {Agendamento === false &&  <Tab label="Contribuir" {...a11yProps(2)} />}
                                    </Tabs>
                                    
                                </Box>
                                <TabPanel value={value} index={0}>



                                        <div>
                                            <Grid container
                                            spacing={2}
                                            justifyContent="flex-start"
                                            alignItems="center">
                                                {tiposConversas.map((tiposConversa:any,index:any) => (
                                                    tiposConversa === null ? (''):(
                                               <TipoConversaSessaoUnica key={index} tipoconversa={tiposConversa} enviado={enviado} tiposConversaselect={alternarStatusPagamento} Agendamento={Agendamento}  />
                                                    )
                                                ))}
                                            </Grid>
                                        </div>


                                </TabPanel>
                                
                                
                                
                                <TabPanel value={value} index={1}>
                                
                                 
                                


                                { pacotesdados.length === 0 ? (
                                    <Container maxWidth="md">
                                    <Card sx={{p: 3, overflow: 'visible', background: '#FFAB00'}}>
                                        <Grid container spacing={2}>
                                            <Grid item md={6} xs={12} sx={{background: 'transparent'}}>
                                                <Item sx={{background: 'transparent'}}>
                                                    <Typography sx={{textAlign: 'left', color: '#fff'}} variant="h2">Ops..</Typography>
                                                    <Typography sx={{textAlign: 'left', color: '#fff'}} variant="subtitle2">Parece que este atendente ainda não criou nenhum pacote personalizado! Você pode fazer um atendimento no modo sessão única ou escolher outro atendente!</Typography>

                                                   <Button sx={ isDesktop ? {color: '#fff', border: '1px solid #fff', mt: 3, float: 'left', mr: 1} : {mt: 1, color: '#fff', border: '1px solid #fff', float: 'left'} } color="warning" variant="outlined" onClick={handleButtonClick}>Sessão única</Button>
                                                   <Button sx={ isDesktop ? {color: '#fff', border: '1px solid #fff', mt: 3, float: 'left', mr: 1} : {mt: 1, color: '#fff', border: '1px solid #fff', float: 'left'} } color="warning" variant="outlined" onClick={irAtendentes}>Escolher outro atendente</Button>
                                                </Item>
                                            </Grid>

                                            <Grid item md={6} xs={12} sx={{background: 'transparent'}}>
                                                <Item sx={{background: 'transparent'}}>
                                                    
                                                    <img src={semPacotes} style={isDesktop ? {marginTop: '-90px', width: '300px'} : {marginTop: '0px', width: '300px'}} alt="Sem pacotes" />
                                                </Item>
                                            </Grid>
                                        </Grid>
                                        
                                    </Card>
                                </Container>
                                ) : ('')}
                                

                                { pacotesdados.length > 0 ? (
                                    <div>
                                    {user?.OrigemCampanha !== 'rs' ? (
                                        <>
                                        <Typography variant="subtitle2">Planos para a democratização da saúde física, mental e espiritual de milhares de brasileiros e brasileiras.</Typography>
                                        <Grid container
                                        spacing={2}
                                        justifyContent="flex-start"
                                        alignItems="center">
                                            
                                            {pacotesdados.map((tiposConversa:any,index:any) => (
                                            <CardsPlanosPacotes dados={tiposConversa} corValor={corpacotes[index]} alternartipoPacotes={alternartipoPacotes} corBotao={corpacotes[index]} key={index} nomePlano={tiposConversa.Nome}  valorPlano={tiposConversa.Valor} qtdConversasPlano={tiposConversa.quantidade}/>
                                            ))}
                                        </Grid>
                                        </>
                                    ) : ('')}
                                    
                                </div>
                                ) : ('') }
                                

                                </TabPanel>  

                                <TabPanel value={value} index={2}>

                                
                                        {contribua}


                                </TabPanel>
                            </Box>
                    </div>
                ) : ('') }

                { pagamentoStatus === "MetodoPagamento" ? (
                    <div>

                        <div className="TopoMetodosdePagamento"> 
                            <IconButton  size="medium" onClick={voltar}>
                                <Iconify icon="eva:chevron-left-fill" className='IconBackTopoPagamento' />
                            </IconButton>

                            <Typography className="TituloTopoPagamento">Método de Pagamento </Typography>
                        </div>


                   
                        <Box sx={{ width: '100%' }}>
                        <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="female"
                                name="radio-buttons-group"
                            >
                        <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        >

                            {user?.SaldoAtual === 0  || user?.SaldoAtual === 0.00 || user?.SaldoAtual === '0' || user?.SaldoAtual === '0,00' ? '': (
                            <Grid item xs={12} md={6}>
                                    <Item>
                                        <div className="opcoesFormadePagamento">
                                            <Grid container
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center">
                                                <Grid item xs={8}>
                                                    <Item className="TipoPagamentoNome"><FormControlLabel value="SaldoemConta" control={<Radio onChange={handleChangetipopagamento} />} label="Saldo em Conta" /></Item>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Item className="IconTextoTipoPagamento">R${user?.SaldoAtual}</Item>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Item>
                            </Grid>
                            )}
                            <Grid item xs={12} md={6}>
                                    <Item>
                                        <div className="opcoesFormadePagamento">
                                            <Grid container
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center">
                                                <Grid item xs={8}>
                                                    <Item className="TipoPagamentoNome"><FormControlLabel value="pix" control={<Radio onChange={handleChangetipopagamento}  />} label="Pix" /></Item>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Item className="IconTextoTipoPagamento"> <img alt="Pagamento via Pix" width="30" src={qrCode} /> </Item>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Item>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                    <Item>
                                        <div className="opcoesFormadePagamento">
                                            <Grid container
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center">
                                                <Grid item xs={8}>
                                                    <Item className="TipoPagamentoNome"><FormControlLabel value="CartaoCredito" control={<Radio onChange={handleChangetipopagamento} />} label="Cartão de Crédito" /></Item>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Item className="IconTextoTipoPagamento"> <img alt="Pagamento via Cartão de Crédito" height="30" src={cartaoCredito} /> </Item>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Item>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                    <Item>
                                        <div className="opcoesFormadePagamento">
                                            <Grid container
                                                direction="row"
                                                justifyContent="flex-start"
                                                alignItems="center">
                                                <Grid item xs={8}>
                                                    <Item className="TipoPagamentoNome"><FormControlLabel value="BoletoBancario" control={<Radio onChange={handleChangetipopagamento} />} label="Boleto Bancário" /></Item>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Item className="IconTextoTipoPagamento"> <img alt="Pagamento via Boleto Bancário" height="30" src={boletoBancario} /> </Item>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Item>
                            </Grid>

                            <Grid item xs={12} md={12}>
                                    <Item>
                                        <div className="opcoesFormadePagamento">
                                            <Grid container
                                                direction="row"
                                                justifyContent="flex-start"
                                                alignItems="center">


                                                <Grid item xs={7}>
                                                    <Item className="TipoPagamentoNome"> <Typography>Resumo</Typography> </Item>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Item className="IconTextoTipoPagamentoEditar" onClick={voltar}> <Iconify icon="eva:edit-fill" /> Editar </Item>
                                                </Grid>


                                                {
                                                    tiposConversaselect.Nome ? (   <Grid item xs={7}>
                                                        <Item className="DetalhesPagamentoTopico"> <Typography> {!ispacote ? (<span>Sessão Unica:</span>):(<span>Pacote:</span>)} {tiposConversaselect.Nome}</Typography> </Item>
                                                    </Grid>): ''
                                                }

                                                {
                                                    tiposConversaselect.Duracao ? (
                                                        <Grid item xs={5}>
                                                            <Item className="DetalhesPagamentoValor"> {tiposConversaselect.Duracao} minutos </Item>
                                                        </Grid>
                                                    ): ''
                                                }
                                                {ispacote ? (<>
                                                    <Grid item xs={7}>
                                                    <Item className="DetalhesPagamentoTopico"> <Typography>Convesas</Typography> </Item>
                                                </Grid>
                                                    <Grid item xs={5}>
                                                        <Item className="DetalhesPagamentoValor"> {tiposConversaselect.quantidade}  </Item>
                                                    </Grid>
                                                </>):('')}


                                                <Grid item xs={7}>
                                                    <Item className="DetalhesPagamentoTopico"> <Typography>Total</Typography> </Item>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Item className="DetalhesPagamentoValor"> R${tiposConversaselect.Valor}  </Item>
                                                </Grid>

                                                <Grid item xs={7}>
                                                            <Item className="DetalhesPagamentoTopico"> <Typography>Desconto</Typography> </Item>
                                                        </Grid>
                                                   


                                               
                                                        <Grid item xs={5}>
                                                            <Item className="DetalhesPagamentoValorDesconto"> {fCurrency(valordescontoaplicado)}  </Item>
                                                        </Grid>
                                                  

                                                <Grid item sx={{display: 'flex', justifyContent: 'flex-start'}} xs={12}>
                                                    <Item> <Button variant="outlined" onClick={handleClickModalCupom}> <Iconify icon='eva:percent-fill' sx={{mr: 1}}/>  Inserir Cupom</Button>  </Item>
                                                </Grid>
                                                
                                                      
                                                

                                                
                                                      




                                                <Divider />

                                                <Grid item xs={7}>
                                                    <Item className="TipoPagamentoNome"> <Typography>Total</Typography> </Item>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Item className="TextoTotalValorPagamento"> R${tiposConversaselect.Valor}  </Item>
                                                </Grid>


                                            </Grid>
                                        </div>
                                        <div className="ItemBotaoMetodoPagamentoContribuir">

                                            <LoadingButton
                                                fullWidth
                                                onClick={handleClickOpenCPF}
                                                variant="contained"
                                                className="BotaoMetodoPagamentoContribuir"
                                                loading={!botaodesabilitado}
                                            >
                                                Confirmar pagamento
                                            </LoadingButton>
                                        </div>
                                    </Item>
                            </Grid>


                        </Grid>

                        </RadioGroup>

                        </Box>

                        {BoletoGerado}
                        {PixGerado}
                        {InformarCPF}

                    </div>) : (' ')}

                    { pagamentoStatus === "DadosCartao" ? (
                    <div>
                        <div className="TopoPaginaPagamento">
                            <IconButton  size="medium" onClick={alternarStatusPagamento}>
                                <Iconify icon="eva:chevron-left-fill" className='IconBackTopoPagamento' />
                            </IconButton>

                            <Typography>Dados do Cartão de Crédito </Typography>
                        </div>
                    
                        <Box sx={{ width: '100%' }}>
                        <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        >
                            <div className="opcoesFormadePagamento">

                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                >
                                    <img src={DadosCartao} width="400px" alt="Dados do Cartão" />       
                            </Grid>


                                <form onSubmit={salvarDadosCartao}>

                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Item>
                                        <input
                                            type="text"
                                            placeholder='Nome Impresso no Cartão'
                                            data-iugu="full_name"
                                            onChange={(event)=>{setnumerocartao(event.target.value)}}
                                                />
                                    </Item>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Item>
                                        <IMaskInput
                                            type="text"
                                            mask="0000 0000 0000 0000"
                                            placeholder='Número do Cartão'
                                            data-iugu="number"
                                            onAccept={
                                                (valormascara, mask) => setnomecartao(valormascara)
                                            }
                                                />
                                    </Item>
                                </Grid>


                                <Grid item xs={12} md={4}>
                                    <Item>
                                        <IMaskInput
                                            type="text"
                                            placeholder='Data de Vencimento'
                                             data-iugu="expiration"
                                            mask="00/0000"
                                            onAccept={
                                                (valormascara, mask) => setvalidadecartao(valormascara)
                                            }
                                                />
                                    </Item>
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <Item>
                                        <input
                                            type="text"
                                            placeholder='Código de Segurança'
                                            data-iugu="verification_value"
                                            onChange={(event)=>{setcodigocartao(event.target.value)}}
                                            pattern="\d*"
                                            maxLength={3}
                                                />
                                    </Item>
                                </Grid>
                                
                                <Grid item xs={12} md={4}>
                                    <Item>
                                        <select name="parcelas"  onChange={(event)=>{setparcelas(event.target.value)}} >
                                            <option value="1">1 Parcela</option>
                                            <option value="2">2 Parcelas</option>
                                            <option value="3">3 Parcelas</option>
                                            <option value="4">4 Parcelas</option>
                                            <option value="5">5 Parcelas</option>
                                            <option value="6">6 Parcelas</option>
                                        </select>
                                    </Item>
                                </Grid>
                                
                            </Grid>
                                    

                                    

                                    <LoadingButton
                                        fullWidth
                                        type="submit"
                                        variant="contained"
                                        className="BotaoMetodoPagamentoContribuir"
                                        loading={!botaodesabilitado}
                                    >
                                        Confirmar pagamento
                                    </LoadingButton>

                                </form>

                            </div>
                        </Grid>


                        </Box>


                    </div>) : (' ')}


                    <Dialog
                        open={abrirModalCupom}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleCloseModalCupom}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogContent sx={{pt: 3, display: 'flex', flexDirection:'column', alignItems: 'center', justifyContent: 'center'}}>
                                <img src={cupomImagem} alt="Imagem de cupom de desconto" width={370}/>
                                <Typography variant="h4" sx={{textAlign: 'center', mb: 2}}>Insira abaixo o seu código de cupom e aproveite sua conversa com desconto!</Typography>
                                <TextField label='Código do Cupom' value={cupom} onChange={mudarcupom} fullWidth/>
                        </DialogContent>
                        <DialogActions>


                            <LoadingButton
                                sx={{px: 2.5, py: 1}} onClick={verificarcupom} variant="contained"
                                loading={isSubmitSuccessful}
                            >
                                <Iconify icon='eva:checkmark-circle-outline' sx={{mr: 1}} /> Verificar Cupom
                            </LoadingButton>

                        </DialogActions>
                    </Dialog>
                    
                                
                    <Dialog
                            open={openSucessoAgendamento}
                            onClose={handleCloseSucessoAgendamento}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            
                            <DialogContent sx={{pt: 3, display: 'flex', flexDirection:'column', alignItems: 'center', justifyContent: 'center'}}>
                                
                                <img src={confirmaAgendamento} width='300px' style={{margin: '10px 0px'}} alt="Imagem Confirmação de Reagendamento"/>

                                <Typography variant="h5" sx={{textAlign: 'center', display: 'flex', alignItems: 'center', my: 1.5 }}> <Iconify icon="eva:checkmark-circle-fill" sx={{mr: 0.5}}/> Seu agendamento foi realizado com sucesso!</Typography>
                                <Typography variant="subtitle2" sx={{textAlign: 'center'}}>Você poderá acompanhar seus agendamentos na página inicial, além disso, receberá notificações via e-mail quando estiver próximo da data e horário do seu atendimento.</Typography>
                            
                            </DialogContent>
                            
                            <DialogActions sx={{display: 'flex', justifyContent: 'center'}}>
                            
                                <Button onClick={voltarDash} color="primary" variant="contained"> <Iconify icon="eva:arrow-ios-back-outline" sx={{mr: 0.5}}/> Voltar para o início</Button>
                                
                            </DialogActions>
                            
                        </Dialog>
                                

            </>
    );
}
