import { DyteAudioVisualizer, DyteAvatar } from '@dytesdk/react-ui-kit';
import { useDyteMeeting, useDyteSelector } from '@dytesdk/react-web-core';
import clsx from 'clsx';
import {Divider,Tabs, Tab, IconButton,Button,DialogTitle,DialogContent,Drawer,LinearProgress,ListItem,ListItemText,ListItemButton,AppBar,Slide,SlideProps ,Dialog,Toolbar,Card,Chip,TextField, List, MenuItem,FormControl,OutlinedInput,InputAdornment, Paper, Box, Grid, Badge, Avatar, Select, Stack, Rating, Tooltip, Typography} from "@mui/material";
import arrows from "./img/arrows.png";

import aguardechegar from './img/aguardechegar.png'

export default function Stage() {
    const { meeting } = useDyteMeeting();
    const activeParticipants = useDyteSelector((meetinga) =>
        meetinga.participants.active.toArray()
    );

    const joinedParticipants = useDyteSelector((meetingb) =>
        meetingb.participants.joined.toArray()
    );

    const participants = [
        ...activeParticipants,
        ...joinedParticipants.filter((p) => !activeParticipants.includes(p)),
        meeting.self,
    ];

    const numberOfParticipants = participants.length;



    return (
        <>
        
        {numberOfParticipants > 1 ? (
            <main className='cardsaudio'>
                
                {participants.map((participant) => (
                        < >

                        {participant.id !== meeting.self.id ? (
                            <div
                            key={participant.id}
                            style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}
                        >
                            <DyteAvatar participant={participant} size="md" style={{marginBottom: '20px'}}/>
                            
                        </div>
                        ) : null}

                            
                            

                        </>
                    )
                )}
            </main>
        ) : (
            <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            >
                <Typography color="white" variant='h5'>Por enquanto somente você está aqui! Aguarde um pouquinho.</Typography>
                <img src={aguardechegar} width={300} alt='Somente você na chamada' />
            </Grid>
                
     
        )}

            
        </>
        
    )
        ;
}